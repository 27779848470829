// Base URL for API
export const API_BASE_URL = 'https://app.dobiqueen.my/api/v1'; // 'https://stagingapp.dobiqueen.my/api/v1';
// API Client ID and Secret Generated in Admin Panel
export const API_CLIENT_ID = '6'; // '7';
export const API_SECRET = 'UIuwdRtJoMmh3JE0nTIxXNyJoOPl2DFLoLWNZBmg'; // 'nFlxSVviMg76FMKVgIJ040zoPUr9V9AF2wCG9Dqs';

// Google Maps API Key
export const GOOGLE_MAPS_API_KEY = 'AIzaSyCd5-djB72h51OTdhaAw7o4m9UdIZEO7vk'

// TODO - Move to Admin Panel API value
export const LAUNDRY_PIECES_MIN = 5