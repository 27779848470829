const NavMenuList = (props) => (
<ul role="list" className="x-nav-menu-list w-list-unstyled">
{
  props.signedOut ? [
    <li className="x-nav-menu-item" key={4}>
    <a href="/sign-up" className="x-nav-menu-link">
    Sign Up
  </a>
</li>,
<li className="x-nav-menu-item" key={5}>
  <a href="/sign-in" className="x-nav-menu-link">
    Login
  </a>
</li>
  ] : [
    <li className="x-nav-menu-item" key={1}>
    <a href="/orders" className="x-nav-menu-link">
      Orders
    </a>
  </li>,
  // <li className="x-nav-menu-item" key={2}>
  //   <a href="/outlets" className="x-nav-menu-link">
  //     Outlets
  //   </a>
  // </li>
  // ,
  <li className="x-nav-menu-item for-mobile-nav" key={3}>
    <a href="/addresses" className="x-nav-menu-link">
      My Addresses
    </a>
  </li>
  ]
}
<li className="x-nav-menu-item for-mobile-nav" key={6}>
  <a href="https://www.dobiqueen.my/app" target="_blank" className="x-nav-menu-link">
    Download App
  </a>
</li>
<li className="x-nav-menu-item for-mobile-nav" key={7}>
  <a href="https://www.dobiqueen.my/tnc/privacy-policy" target="_blank" className="x-nav-menu-link">
    Privacy
  </a>
</li>
<li className="x-nav-menu-item for-mobile-nav"key={8}>
  <a href="https://www.dobiqueen.my/tnc" target="_blank" className="x-nav-menu-link">
    Terms
  </a>
</li>
<li className="x-nav-menu-item for-mobile-nav" key={9}>
  <a href="https://www.dobiqueen.my/contact" target="_blank" className="x-nav-menu-link">
    Get Help
  </a>
</li>
</ul>
)

export default NavMenuList