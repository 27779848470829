import { atom, useRecoilValue } from 'recoil';
import { recoilPersist } from 'recoil-persist';

import OrderBagObj from './OrderBagObj';
import OrderObj from './OrderObj';

// Loader
export default function Loader(atom: any): any {
  let loading = useRecoilValue(atom);
  return loading;
}

const { persistAtom } = recoilPersist({
  key: 'recoil-persist',
  storage: localStorage
});

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Profile
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
export const isSignedInAtom = atom({
  key: 'isSignedIn',
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export const isGuestAtom = atom({
  key: 'isGuest',
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export const profileAtom = atom({
  key: 'profile',
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export const notificationAtom = atom({
  key: 'notifications',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const unreadNotificationAtom = atom({
  key: 'unreadNotifications',
  default: 0,
  effects_UNSTABLE: [persistAtom],
});

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Tokens
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
export const accessTokenAtom = atom({
  key: 'accessToken',
  default: '',
  effects_UNSTABLE: [persistAtom]
});

export const refreshTokenAtom = atom({
  key: 'refreshToken',
  default: '',
  effects_UNSTABLE: [persistAtom]
});

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Order
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
export const currentOrderAtom = atom({
  key: 'currentOrder',
  default: new OrderObj(),
  effects_UNSTABLE: [persistAtom]
});

export const orderBagsAtom = atom({
  key: 'orderBags',
  default: [],
  effects_UNSTABLE: [persistAtom]
});

export const currentBagAtom = atom({
  key: 'orderBag',
  default: new OrderBagObj(),
  effects_UNSTABLE: [persistAtom]
});

export const showBagFormAtom = atom({
  key: 'show',
  default: true,
  effects_UNSTABLE: [persistAtom]
});

// these two are for orders just placed
export const recentOrderAtom = atom({
  key: 'recentOrder',
  default: new OrderObj(),
  effects_UNSTABLE: [persistAtom]
});

export const recentOrderBagsAtom = atom({
  key: 'recentOrderBags',
  default: [],
  effects_UNSTABLE: [persistAtom]
});

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Product
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
export const productsAtom = atom({
  key: 'products',
  default: [],
  effects_UNSTABLE: [persistAtom]
});

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Timeslots
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
export const timeslotsAtom = atom({
  key: 'timeslots',
  default: [],
  effects_UNSTABLE: [persistAtom]
});

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Shipping Method
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
export const shippingMethodsAtom = atom({
  key: 'shippingMethods',
  default: [],
  effects_UNSTABLE: [persistAtom]
});