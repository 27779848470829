import {Navigate, Outlet} from "react-router-dom"
import { useRecoilState } from 'recoil';
import { isSignedInAtom, isGuestAtom } from "../Utils/Atoms";

export default function AuthRequired() {
  const [isSignedIn, setIsSignedIn] = useRecoilState(isSignedInAtom);
  const [isGuest, setIsGuest] = useRecoilState(isGuestAtom);

  if (!isSignedIn) {
    if (!isGuest) {
      return <Navigate to="/sign-in" />
    }
  }

  return <Outlet />
}