const NewOrderStepper = (props) => {
  let step = props.step

  return (
    (
      <div className="x-stepper">
      <a href={step > 1 ? '/new-order' : null} className={`x-stepper-link ${step === 1 ? 'for-current' : ''} ${step > 1 ? 'for-done' : ''}`}>
        1
      </a>
      <a href={step > 2 ? '/schedule' : null} className={`x-stepper-link ${step === 2 ? 'for-current' : ''} ${step > 2 ? 'for-done' : ''}`}>
        2
      </a>
      <a href={step > 3 ? '/address-select' : null} className={`x-stepper-link ${step === 3 ? 'for-current' : ''} ${step > 3 ? 'for-done' : ''}`}>
        3
      </a>
      <a href={step > 4 ? '/add-ons' : null} className={`x-stepper-link ${step === 4 ? 'for-current' : ''} ${step > 4 ? 'for-done' : ''}`}>
        4
      </a>
      <a className={`x-stepper-link ${step === 5 ? 'for-current' : ''} ${step > 5 ? 'for-done' : ''}`}>
        5
      </a>
    </div>
      )
  )

}

export default NewOrderStepper