const ScheduleModals = (props:any) => {
  if (props.modalCode === 1) {
    return (
      <div className="x-form-col">
      <img
        alt=""
        src="images/calendar-event-fill.svg"
        className="x-message-thumb for-dialog"
      />
      <div className="x-dialog-title for-action">
        Schedule Pickup &amp; Return Time
      </div>
      <p>
        Select the appropriate timing that works for you. We offer
        same day Pickup &amp; Delivery (subjected to Terms).
      </p>
      </div>
    )
  }
  return null
}

export default ScheduleModals