import { useRecoilState } from "recoil";

import OrderBagObj from "../Utils/OrderBagObj";
import { getProduct, getSubproduct, getSubproducts } from "../Utils/Products";
import {
  currentBagAtom,
  currentOrderAtom,
  orderBagsAtom,
  productsAtom,
  showBagFormAtom,
} from "../Utils/Atoms";
import {
  clearCurrentOrder,
  filterAddOnOrderBags,
  filterLaundryOrderBags,
  reorderBags,
} from "../Utils/Helpers";

// type MinimizedProps = {
//   id: number,
//   bag: OrderBagObj,
// };

function NewOrderBagListItem(props) {
  const { id, bag } = props;
  const [currentOrder, setCurrentOrder] = useRecoilState(currentOrderAtom);
  const [orderBags, setOrderBags] = useRecoilState(orderBagsAtom);
  const [currentBag, setCurrentBag] = useRecoilState(currentBagAtom);
  const [showBagForm, setShowBagForm] = useRecoilState(showBagFormAtom);
  const [products, setProducts] = useRecoilState(productsAtom);

  const product = getProduct(bag.product_id, products);

  // TODO add UI prompt to prevent removal of unsaved bag
  const handleEditBag = (e: any, id: number): void => {
    e.preventDefault();
    let bagToEdit = Object.assign(new OrderBagObj(), orderBags[id - 1]);
    setCurrentBag(bagToEdit);
    setShowBagForm(true);
  };

  const handleDeleteBag = (e: any, id: number): void => {
    e.preventDefault();
    let bags = [...orderBags];
    bags.splice(id - 1, 1);
    setOrderBags(reorderBags(bags));
    const filteredBag = filterLaundryOrderBags(bags);
    if (filteredBag.length === 0) {
      setCurrentOrder(clearCurrentOrder(currentOrder));
      setOrderBags([]);
      // reset currentBag to new bag object
      setCurrentBag(new OrderBagObj());
      // show the form
      setShowBagForm(true);
    }
  };

  const handleDuplicateBag = (e: any, id: number): void => {
    e.preventDefault();
    let bagToDuplicate = Object.assign(new OrderBagObj(), orderBags[id - 1]);
    const filteredLaundry = filterLaundryOrderBags(orderBags);
    const filterAddOn = filterAddOnOrderBags(orderBags);
    filteredLaundry.push(bagToDuplicate);

    const bags = [...filteredLaundry, ...filterAddOn].map((item, index) => ({
      ...item,
      id: index + 1,
    }));
    setOrderBags(bags);
  };

  // const getBagTotal = (subproduct: any) => {
  //   let amount = "0";
  //   if (subproduct !== null) {
  //     amount = subproduct.unit_price * bag.subproduct_qty;
  //   } else if (subproduct === null) {
  //     amount = product.unit_price * bag.product_qty;
  //   }
  //   amount = Number(amount).toFixed(2);
  //   return amount;
  // };

  // reset IDs of bags after deletion to avoid out of order IDs
  // const reorderBags = (bags: Array<OrderBagObj>): Array<OrderBagObj> => {
  //   let updatedBags = JSON.parse(JSON.stringify(bags)); // deep copy

  //   updatedBags.map((item: OrderBagObj, index: number) => {
  //     item.id = index + 1;
  //     return item;
  //   });
  //   return updatedBags;
  // };

  if (product !== undefined) {
    let subproducts = null;
    let subproduct = null;
    if (product.variant_type === "variant") {
      subproducts = getSubproducts(bag.product_id, products);
      subproduct = getSubproduct(bag.subproduct_id, subproducts);
    }

    return (
      <li className="x-bag-item">
        <div className="x-bag-item-header">
          <h3>{bag.category === "laundry" && `Bag #${id}`}</h3>
          <div className="x-hr-sep" />
          <div className="x-btn-container">
            <a
              onClick={(e) => handleDuplicateBag(e, id)}
              className="x-sml-btn for-simple w-button"
            >
              <span className="x-icon for-btn-inline"></span>
              Duplicate
            </a>
            <a
              onClick={(e) => handleDeleteBag(e, id)}
              className="x-sml-btn for-simple w-button"
            >
              <span className="x-icon for-btn-inline"></span>Delete
            </a>
          </div>
        </div>
        <div>
          {`${product.name} · ${
            (subproduct !== null && subproduct.name) ||
            (subproduct == null && bag.product_qty + " pieces")
          }`}
        </div>
        <div className="x-bag-item-footer">
          <div className="x-btn-container">
            <a
              onClick={(e) => handleEditBag(e, id)}
              className="x-btn for-secondary w-button"
            >
              <span className="x-icon for-btn-inline"></span>Edit
            </a>
          </div>
          {/* <div className="x-bag-item-total">
          <div className="x-small-text">Total for this bag</div>
          <div className="x-brand-emp">{`RM${getBagTotal(subproduct)}`}</div>
        </div> */}
        </div>
      </li>
    );
  }

  return <li></li>;
}

export default NewOrderBagListItem;
