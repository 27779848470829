import moment from "moment";
import { useNavigate } from "react-router-dom";

const MyOrderRow = (props) => {
  const navigate = useNavigate();

  const handleGoToOrder = () => {
    navigate(`/order-details/${props.order.id}`, {
      state: { orderID: props.order.id },
    });
  };

  const getIcon = () => {
    // Based on service type
    const laundryList = (props.order.order_items || []).filter(
      (item) => item.product_category === "laundry"
    );
    if (laundryList.length > 1) {
      return "images/icons8-bursts-96.png";
    } else {
      if (laundryList[0]?.product_name.includes("Fold")) {
        return "images/icons8-sheets-96.png";
      } else if (laundryList[0]?.product_name.includes("Hang")) {
        return "images/icons8-cloakroom-96.png";
      } else return "images/icons8-washing-machine-96.png";
    }
  };

  const getServiceName = () => {
    const laundryList = (props.order.order_items || []).filter(
      (item) => item.product_category === "laundry"
    );
    if (laundryList.length > 1) {
      return `${laundryList[0]?.product_name} + ${laundryList.length - 1} more`;
    } else {
      return laundryList[0]?.product_name;
    }
  };

  const getStatus = () => {
    if (props.order.status.toLowerCase() === "pending") {
      return (
        <div className="x-orderbar-status">
          <div className="x-icon for-orderbar"></div>
          <div>Pending</div>
        </div>
      );
    }

    if (props.order.status.toLowerCase() === "confirmed") {
      return (
        <div className="x-orderbar-status">
          <div className="x-icon for-orderbar"></div>
          <div>Confirmed</div>
        </div>
      );
    }

    if (props.order.status.toLowerCase().indexOf("pickup") !== -1) {
      return (
        <div className="x-orderbar-status">
          <div className="x-icon for-orderbar"></div>
          <div>Pickup</div>
        </div>
      );
    }

    if (
      props.order.status.toLowerCase().indexOf("arrive") !== -1 ||
      props.order.status.toLowerCase().indexOf("washing") !== -1
    ) {
      return (
        <div className="x-orderbar-status for-blue">
          <div className="x-icon for-orderbar"></div>
          <div>Washing</div>
        </div>
      );
    }

    if (props.order.status.toLowerCase() === "drying") {
      return (
        <div className="x-orderbar-status for-cyan">
          <div className="x-icon for-orderbar"></div>
          <div>Drying</div>
        </div>
      );
    }

    if (props.order.status.toLowerCase() === "folding") {
      return (
        <div className="x-orderbar-status for-purple">
          <div className="x-icon for-orderbar"></div>
          <div>Folding</div>
        </div>
      );
    }

    if (props.order.status.toLowerCase() === "dropoff_scheduled") {
      return (
        <div className="x-orderbar-status for-green">
          <div className="x-icon for-orderbar"></div>
          <div>To Return</div>
        </div>
      );
    }

    if (props.order.status.toLowerCase() === "dropoff_in_progress") {
      return (
        <div className="x-orderbar-status for-green">
          <div className="x-icon for-orderbar"></div>
          <div>Returning</div>
        </div>
      );
    }

    if (props.order.status.toLowerCase() === "completed") {
      return (
        <div className="x-orderbar-status for-green">
          <div className="x-icon for-orderbar"></div>
          <div>Completed</div>
        </div>
      );
    }

    if (props.order.status.toLowerCase() === "no_response") {
      return (
        <div className="x-orderbar-status for-red">
          <div className="x-icon for-orderbar"></div>
          <div>Attention</div>
        </div>
      );
    }

    if (props.order.status.toLowerCase() === "cancelled") {
      return (
        <div className="x-orderbar-status for-red">
          <div className="x-icon for-orderbar"></div>
          <div>Cancelled</div>
        </div>
      );
    }

    // if (
    //   props.order.status.toLowerCase() === "attention" ||
    //   props.order.status.toLowerCase() === "error"
    // ) {
    //   return (
    //     <div className="x-orderbar-status for-red">
    //       <div className="x-icon for-orderbar"></div>
    //       <div>Attention</div>
    //     </div>
    //   );
    // }
  };

  return (
    <li className="x-orderbar-item">
      <a onClick={handleGoToOrder} className="x-orderbar-link w-inline-block">
        <img src={getIcon()} alt="" className="x-orderbar-status-thumb" />
        <div className="x-orderbar-block">
          <div className="x-small-text">{props.order.id}</div>
          <div className="x-emp-text">{getServiceName()}</div>
        </div>
        <div className="x-orderbar-block for-smobile-hide">
          <div className="x-small-text">Sent on</div>
          <div className="x-emp-text">
            {moment(props.order.created_at).format("DD MMM YYYY · HH:mm")}
          </div>
        </div>
        <div className="x-orderbar-block for-status">
          <div className="x-small-text">Status</div>
          {getStatus()}
        </div>
        <div className="x-btn-container">
          <div className="x-icon-btn for-order"></div>
        </div>
      </a>
    </li>
  );
};

export default MyOrderRow;
