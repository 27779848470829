import { useRecoilState } from "recoil";

import NewOrderSubProductItem from "./NewOrderSubProductItem";

import OrderBagObj from "../Utils/OrderBagObj";
import { currentBagAtom, orderBagsAtom } from "../Utils/Atoms";
import { getProduct, getSubproducts } from "../Utils/Products";
import { LAUNDRY_PIECES_MIN } from "../Utils/Constants";

function NewOrderBagVariant(props: any) {
  // destructure props
  const { products } = props;
  const [currentBag, setCurrentBag] = useRecoilState(currentBagAtom);

  const currentBagProduct = getProduct(currentBag.product_id, products);

  const getVariantType = () => {
    const subproducts = getSubproducts(currentBag.product_id, products);

    const handleSelectSubProduct = (e: any, subprod: any): void => {
      let updated = Object.assign(new OrderBagObj(), currentBag);
      updated.subproduct_id = e.target.value;
      updated.subproduct_qty = 1; // it's always one for laundry bags etc. which have no piece count
      updated.product_qty = 1;
      setCurrentBag(updated);
    };

    const displaySubProducts = (subproducts: any) => {
      return subproducts.map((subprod: any, index: any) => (
        <NewOrderSubProductItem
          subprod={subprod}
          currentBag={currentBag}
          handleSelectSubProduct={(e: any, subprod: any) =>
            handleSelectSubProduct(e, subprod)
          }
        />
      ));
    };

    return (
      <div data-washtype={1} className="x-bag-editor-row">
        <div className="x-bag-editor-stepper">
          <div className="x-bag-editor-stepper-label">B</div>
          <div className="x-bag-editor-stepper-icon"></div>
        </div>
        <div className="x-bag-editor-wrapper">
          <div className="x-bag-editor-header">
            <div className="x-text-flex">
              <div className="x-emp-text">Select bag size.</div>
              <a
                data-toggle="dialog-bag-size-guide"
                data-tippy-content="How this works?"
                onClick={(e) => props.handleOpenModal(e, 3)}
                className="x-helper-btn"
              >
                
              </a>
            </div>
          </div>
          <div className="x-bag-editor-content">
            <div className="x-item-choice-grid">
              {displaySubProducts(subproducts)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getQuantityType = () => {
    const product = currentBagProduct;

    const incrementProductQty = (e: any): void => {
      let updated = Object.assign(new OrderBagObj(), currentBag);
      if (updated.product_qty < LAUNDRY_PIECES_MIN) {
        updated.product_qty = 4;
      } else {
        updated.product_qty++;
      }
      setCurrentBag(updated);
    };

    const decrementProductQty = (e: any): void => {
      let updated = Object.assign(new OrderBagObj(), currentBag);
      if (updated.product_qty > LAUNDRY_PIECES_MIN) {
        updated.product_qty--;
      }
      setCurrentBag(updated);
    };

    const handleManualQuantiy = (e: string) => {
      let updated = Object.assign(new OrderBagObj(), currentBag);
      let newManualAmount = Number(e);
      if (newManualAmount < LAUNDRY_PIECES_MIN) {
        updated.product_qty = LAUNDRY_PIECES_MIN;
      } else {
        updated.product_qty = newManualAmount;
      }
      setCurrentBag(updated);
    };

    return (
      <div data-washtype={2} className="x-bag-editor-row">
        <div className="x-bag-editor-stepper">
          <div className="x-bag-editor-stepper-label">B</div>
          <div className="x-bag-editor-stepper-icon"></div>
        </div>
        <div className="x-bag-editor-wrapper">
          <div className="x-bag-editor-header">
            <div className="x-text-flex">
              <div className="x-emp-text">Specify laundry pieces.</div>
              <a
                data-toggle="dialog-wdsh-piece-guide"
                data-tippy-content="How this works?"
                onClick={(e) => props.handleOpenModal(e, 4)}
                className="x-helper-btn"
              >
                
              </a>
            </div>
          </div>
          <div className="x-bag-editor-content">
            <div className="x-special-input-group">
              <div className="x-special-input">
                <div className="x-input-number">
                  <input
                    type="number"
                    className="x-input-text w-input"
                    maxLength={3}
                    name="NumberInput"
                    data-name="Number Input"
                    value={currentBag.product_qty}
                    onChange={(e) => handleManualQuantiy(e.target.value)}
                    min={0}
                    id="NumberInput"
                  />
                  <div className="x-input-number-btn-container">
                    <a
                      onClick={decrementProductQty}
                      className="x-input-number-btn for-down"
                    >
                      
                    </a>
                    <a
                      onClick={incrementProductQty}
                      className="x-input-number-btn"
                    >
                      
                    </a>
                  </div>
                </div>
              </div>
              <div className="x-special-input-price">
                <div className="x-brand-emp">{`Total: RM${Number(
                  currentBag.product_qty * product.unit_price
                ).toFixed(2)}`}</div>
                <div className="x-small-text">
                  <span className="x-brand-emp">
                    {`RM${product.unit_price}/piece`}
                  </span>{" "}
                  {`with min of ${LAUNDRY_PIECES_MIN} pieces.`}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {currentBagProduct !== undefined &&
        currentBagProduct.variant_type === "variant" &&
        getVariantType()}
      {currentBagProduct !== undefined &&
        currentBagProduct.variant_type === "quantity" &&
        getQuantityType()}
    </>
  );
}

export default NewOrderBagVariant;
