import { useState, useEffect } from 'react';

import MainContainer from '../../Components/MainContainer';
import HomeFooter from '../../Components/HomeFooter';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import OutletRow from '../../Components/OutletRow';

import { ApiInstance, ApiRoutes } from '../../Utils/ApiInstance';

function Outlets() {  
  const [outlets, setOutlets] = useState([]);
  const [filteredOutlets, setFilteredOutlets] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [search, setSearch] = useState("");
  const [isSearching, setIsSearching] = useState(false)

  const getOutlets = async () => {
    try {
      const response = await ApiInstance.get(ApiRoutes.GET_SHOPS);
      setOutlets(response.data.data);
    } catch (error) {
      throw error;
    }
  }

  const displayOutletRows = () => {
    if (search.length !== 0 && isSearching) {
      return filteredOutlets.map((outlet, index) => <OutletRow outlet={outlet} key={index}/>)
    } else {
      return outlets.map((outlet, index) => <OutletRow outlet={outlet} key={index}/>)
    }
  }

  const typeSearch =(data: any) => {
    setIsSearching (false)
    setSearch(data)
  }

  const handleSearch = (data : any) => {
    data.preventDefault();
    setIsSearching (true)

    if (search.length === 0) {
      // Clearing array
      setIsSearching(false)
      setFilteredOutlets([])
    } else {
      var matchOutlets = outlets.filter((el : any) => el.name.indexOf(search) !== -1)
      setFilteredOutlets(matchOutlets)
    }
  }

  useEffect(() => {
    getOutlets()
  }, []);


  return (
    <MainContainer title={'Outlets'}>
<div className="x-master">
  <div className="x-master-wrapper">
    <Header />
    <header className="x-header for-subpage">
      <h1>Outlets</h1>
    </header>
    <main className="x-main for-subpage">
      <div className="x-outlet-finder">
        <form action="/search" className="x-searchbar w-form">
          <input
            type="search"
            className="x-input-text w-input"
            autoFocus
            maxLength={256}
            name="query"
            // placeholder="Search for outlet..."
            id="search"
            onChange={(e) => typeSearch(e.target.value)}
          />
          <a
            onClick={(e) => handleSearch(e)} 
            type="submit"
            className="x-searchbar-btn w-button"
          >
            
              </a>
        </form>
        <div className="x-outlet-wrapper">
          <ol role="list" className="x-outlet-list w-list-unstyled">
          {displayOutletRows()}
          </ol>
        </div>
      </div>
    </main>
    <HomeFooter />
  </div>
  <Footer />
</div>

</MainContainer>

  );
}

export default Outlets;
