import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";

import MainContainer from "../../Components/MainContainer";
import ErrorModal from "../../Components/Modals/ErrorModal";

import { ApiInstance, ApiRoutes } from "../../Utils/ApiInstance";
import {
  accessTokenAtom,
  currentBagAtom,
  currentOrderAtom,
  isGuestAtom,
  isSignedInAtom,
  orderBagsAtom,
  productsAtom,
  profileAtom,
  refreshTokenAtom,
  showBagFormAtom,
} from "../../Utils/Atoms";
import { API_CLIENT_ID, API_SECRET } from "../../Utils/Constants";
import { PhoneField } from "../../Components/Forms/PhoneField";
import { formatDateTime } from "../../Utils/Dates";
import { postDeviceUUID } from "../../Utils/Helpers";
import OrderObj from "../../Utils/OrderObj";
import OrderBagObj from "../../Utils/OrderBagObj";

function SignUp() {
  const phoneRef = React.useRef<any>(null);
  const [isGuest, setIsGuest] = useRecoilState(isGuestAtom);
  const [signedIn, setSignedIn] = useRecoilState(isSignedInAtom);
  const [profile, setProfile] = useRecoilState(profileAtom);
  const [accessToken, setAccessToken] = useRecoilState(accessTokenAtom);
  const [refreshToken, setRefreshToken] = useRecoilState(refreshTokenAtom);
  const [currentOrder, setCurrentOrder] = useRecoilState(currentOrderAtom);
  const [orderBags, setOrderBags] = useRecoilState(orderBagsAtom);
  const [currentBag, setCurrentBag] = useRecoilState(currentBagAtom);
  const [showBagForm, setShowBagForm] = useRecoilState(showBagFormAtom);
  const [products, setProducts] = useRecoilState(productsAtom);

  const [username, setUsername] = useState("");
  const [mobile, setMobile] = useState("");
  const [fullName, setFullName] = useState("");
  const [preferredName, setPreferredName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [modalCode, setModalCode] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const handleOpenModal = (
    modalNumber: number,
    errorMessage?: string | undefined
  ) => {
    setModalCode(modalNumber);
    if (modalNumber === 0) setErrorMessage(errorMessage ?? "");
    setShowModal(true);
  };

  const handleCloseModal = (e: any) => {
    e.preventDefault();
    setShowModal(false);
    setModalCode(0);
  };

  const generateModalContent = () => {
    if (modalCode === 0) return <ErrorModal description={errorMessage} />;
    else if (modalCode === 1)
      return (
        <ErrorModal
          title="Deleted Account Reactivation"
          description={`Your account ${
            profile?.customer?.email
          } was previously sent for deletion on ${formatDateTime(
            profile?.customer?.last_deleted_at
          )} and is still subjected to ${
            profile?.soft_delete_days
          } day(s) of deletion cooldown. Do you wish to recover your account? This will reset your deletion cooldown.`}
        />
      );
  };

  const generateModalButton = () => {
    if (modalCode === 0)
      return (
        <div className="x-btn-container">
          <a
            onClick={(e) => handleCloseModal(e)}
            className="x-btn for-secondary is-close-modal w-button"
          >
            Dismiss
          </a>
        </div>
      );
    else if (modalCode === 1)
      return (
        <div className="x-btn-container">
          <a
            onClick={(e) => handleRecoverAccount(e)}
            className="x-btn for-secondary is-close-modal w-button"
          >
            Yes, recover my account
          </a>
        </div>
      );
  };

  const showHidePassword = (data: any) => {
    data.preventDefault();
    showPassword ? setShowPassword(false) : setShowPassword(true);
    return null;
  };

  const handleRecoverAccount = async (e: any) => {
    e.preventDefault();

    try {
      const response = await ApiInstance.post(ApiRoutes.RESTORE_PROFILE, {
        customer_id: profile?.customer?.id,
      });
      setProfile(response.data);
      handleLogin(true);
    } catch (error: any) {
      if (error.response?.data?.message != undefined) {
        setErrorMessage(error.response.data.message);
        handleOpenModal(0, error.response.data.message);
      } else {
        setErrorMessage(error.response);
        handleOpenModal(0, error.response);
      }
    }
  };

  const authToRegisterCustomer = async () => {
    try {
      const responseToken = await ApiInstance.post(ApiRoutes.GENERATE_TOKEN, {
        client_id: API_CLIENT_ID,
        client_secret: API_SECRET,
        grant_type: "client_credentials",
        scope: "read",
      });

      // save admin tokens for customer post
      const { access_token, refresh_token } = responseToken.data;
      setAccessToken(access_token);
      setRefreshToken(refresh_token);
    } catch (error: any) {
      if (error.response?.data?.message != undefined) {
        setErrorMessage(error.response.data.message);
        handleOpenModal(0, error.response.data.message);
      } else {
        setErrorMessage(error.message);
        handleOpenModal(0, error.message);
      }
    }
  };

  // const handleGetSettings = async () => {
  //   try {
  //     const response = await ApiInstance.get(ApiRoutes.GET_SETTINGS);
  //   } catch (error: any) {
  //     if (error.response?.data?.message != undefined) {
  //       setErrorMessage(error.response.data.message);
  //       handleOpenModal(0, error.response.data.message);
  //     } else {
  //       setErrorMessage(error.message);
  //       handleOpenModal(0, error.message);
  //     }
  //   }
  // };

  const handleLogin = async (isExistingUser: boolean) => {
    try {
      const response = await ApiInstance.post(ApiRoutes.GENERATE_TOKEN, {
        client_id: API_CLIENT_ID,
        client_secret: API_SECRET,
        grant_type: "password",
        user_type: "customer",
        scope: "*",
        username,
        password,
      });

      // save real customer tokens for later
      const { access_token, refresh_token } = response.data;
      setAccessToken(access_token);
      setRefreshToken(refresh_token);
      setCurrentOrder(new OrderObj());
      setOrderBags([]);
      setShowBagForm(true);

      const res = await postDeviceUUID();
      if (res.status === 200) {
        if (isExistingUser) {
          setSignedIn(true);
          setIsGuest(false);
          setCurrentBag(new OrderBagObj());
          navigate("/");
        } else navigate("/sign-up-tac");
      } else {
        await ApiInstance.post(ApiRoutes.REVOKE_TOKEN, {});
        setSignedIn(false);
        setIsGuest(true);
        setProfile([]);
        setProducts([]);
        setAccessToken("");
        setRefreshToken("");
      }
    } catch (error: any) {
      if (error.response?.data?.message != undefined) {
        setErrorMessage(error.response.data.message);
        handleOpenModal(0, error.response.data.message);
      } else {
        setErrorMessage(error.message);
        handleOpenModal(0, error.message);
      }
    } finally {
      // after all is said and done re-enable submit
      setIsSubmitting(false);
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      handleSignUp(event);
    }
  };

  const handleSignUp = async (data: any) => {
    data.preventDefault();

    let countryCode = phoneRef.current?.state?.country?.dialCode;
    let phoneNumber = mobile.replace(`+${countryCode}`, "");

    if (!isSubmitting) {
      setIsSubmitting(true);
    } else {
      return null;
    }

    // Validation
    if (username.length === 0) {
      setIsSubmitting(false);
      return handleOpenModal(0, "Email required");
    }
    if (phoneNumber.length === 0) {
      setIsSubmitting(false);
      return handleOpenModal(0, "Mobile number required");
    }
    if (fullName.length === 0) {
      setIsSubmitting(false);
      return handleOpenModal(0, "Full name required");
    }
    if (preferredName.length === 0) {
      setIsSubmitting(false);
      return handleOpenModal(0, "Preferred name required");
    }
    if (password.length === 0) {
      setIsSubmitting(false);
      return handleOpenModal(0, "Password required");
    }

    // Send request to API
    let requestBody = {
      name: fullName,
      preferred_name: preferredName,
      email: username,
      mobile_number: phoneNumber,
      country_code: countryCode,
      password,
      password_confirmation: password,
    };

    try {
      const response = await ApiInstance.post(ApiRoutes.SIGNUP, requestBody);

      // Saving phone data for TAC verification later
      setProfile(response.data);

      if (response.data?.is_existing) {
        handleOpenModal(1);
        setShowModal(true);
        return;
      }

      // Login user for access tokens
      handleLogin(response.data?.is_existing);
    } catch (error: any) {
      if (error.response?.data?.message != undefined) {
        setErrorMessage(error.response.data.message);
        handleOpenModal(0, error.response.data.message);
      } else {
        setErrorMessage(error.message);
        handleOpenModal(0, error.message);
      }
    } finally {
      // after all is said and done re-enable submit
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    authToRegisterCustomer();
  }, []);

  return (
    <MainContainer title={"Sign Up"}>
      <div className="x-master for-access">
        <div className="x-master-wrapper">
          <div className="x-access-wrapper">
            <img
              src="/images/dq-logo-text-color-hr.svg"
              // loading="lazy"
              alt=""
              className="x-brand-logo"
            />
            <h1>Sign Up</h1>
            <div className="x-form-wrapper w-form">
              <form
                id="wf-form-Sign-Up-Form"
                name="wf-form-Sign-Up-Form"
                data-name="Sign Up Form"
                method="get"
                data-wf-page-id="64fcac2e03b3c97638fff87a"
                data-wf-element-id="dbff2635-6c78-989d-0798-b00468339f9d"
              >
                <div className="x-form-wrapper">
                  <div className="x-form-container">
                    <div className="x-form-row">
                      <div className="x-form-col">
                        <label htmlFor="Email">Email</label>
                        <input
                          type="email"
                          className="x-input-text w-input"
                          maxLength={256}
                          name="Email"
                          data-name="Email"
                          // placeholder="Email"
                          id="Email"
                          required
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="x-form-row">
                      <PhoneField
                        inputRef={phoneRef}
                        mobile={mobile}
                        setMobile={setMobile}
                      />
                    </div>
                    <div className="x-form-row">
                      <div className="x-form-col">
                        <label htmlFor="FullName">Full Name</label>
                        <input
                          type="text"
                          className="x-input-text w-input"
                          maxLength={256}
                          name="FullName"
                          data-name="FullName"
                          // placeholder="John Doe"
                          id="FullName"
                          required
                          value={fullName}
                          onChange={(e) => setFullName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="x-form-row">
                      <div className="x-form-col">
                        <label htmlFor="PreferName">Preferred Name</label>
                        <input
                          type="text"
                          className="x-input-text w-input"
                          maxLength={256}
                          name="PreferName"
                          data-name="PreferName"
                          // placeholder="John"
                          id="PreferName"
                          required
                          value={preferredName}
                          onChange={(e) => setPreferredName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="x-form-row">
                      <div className="x-form-col">
                        <label htmlFor="Password">Password</label>
                        <input
                          type={showPassword ? "text" : "password"}
                          className="x-input-text w-input"
                          maxLength={256}
                          name="Password"
                          data-name="Password"
                          // placeholder="Password"
                          id="Password"
                          required
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          onKeyDown={handleKeyDown}
                        />
                        <button
                          id="PassUnmask"
                          onClick={(e) => showHidePassword(e)}
                          className="x-input-opt-btn"
                        >
                          Check
                        </button>
                      </div>
                    </div>
                    <div className="x-form-action">
                      <div className="x-btn-container">
                        <button
                          className="x-btn w-button"
                          onClick={handleSignUp}
                        >
                          Sign Up
                        </button>
                        <a
                          href="/sign-in"
                          className="x-btn for-secondary w-button"
                        >
                          Login instead
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {/* <div className="x-or-sep">
        <div className="x-hr-sep" />
        <div>OR</div>
        <div className="x-hr-sep" />
      </div> */}
            {/* <div className="x-btn-container for-center">
        <a href="#" className="x-icon-btn for-simple is-fb w-button">
          
        </a>
        <a href="#" className="x-icon-btn for-simple is-gg w-button">
          
        </a>
        <a href="#" className="x-icon-btn for-simple is-ap w-button">
          
        </a>
      </div> */}
            <div className="x-helper-block">
              <div className="x-icon for-btn-inline is-amber"></div>
              <div>
                <div className="x-emp-text">Your Privacy Matters</div>
                <div>
                  dobiQueen takes your privacy seriously. All information
                  provided are solely for the use of dobiQueen only to provide
                  the necessary dobi services to you. Refer to our{" "}
                  <a
                    href="https://www.dobiqueen.my/tnc/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>{" "}
                  for more information.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* MODAL SECTION */}
      <div
        data-toggletarget="dialog-addons-guide"
        className={`x-dialog ${showModal ? "" : "for-hide"}`}
      >
        <div className="x-dialog-wrapper">
          <div
            className={`x-dialog-form w-form ${showModal ? "" : "for-hide"}`}
          >
            <form
              id="wf-form-Guide-Dialog-Form"
              name="wf-form-Guide-Dialog-Form"
              data-name="Guide Dialog Form"
              method="get"
              data-wf-page-id="64fc904b6e1ec367837abe0b"
              data-wf-element-id="cfb25b3e-6032-c0c4-861a-dc1b5db263d9"
            >
              <div className="x-dialog-header">
                <div className="x-dialog-title for-action-icon"></div>
                <div className="x-btn-container">
                  <a
                    onClick={(e) => handleCloseModal(e)}
                    className="x-icon-btn for-simple is-close-modal w-button"
                  >
                    
                  </a>
                </div>
              </div>
              <div className="x-dialog-body">
                <div className="x-form-wrapper">
                  <div className="x-form-row">{generateModalContent()}</div>
                  <div className="x-form-action">{generateModalButton()}</div>
                </div>
              </div>
            </form>
          </div>
          <div className="x-dialog-overlay" />
        </div>
      </div>
    </MainContainer>
  );
}

export default SignUp;
