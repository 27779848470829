import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { useNavigate } from "react-router-dom";
import { setKey, fromAddress } from "react-geocode";

import MainContainer from '../Components/MainContainer';
import HomeFooter from '../Components/HomeFooter';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import CheckCoverageModals from '../Components/Modals/CheckCoverageModals';

import { ApiInstance, ApiRoutes } from '../Utils/ApiInstance';
import { GOOGLE_MAPS_API_KEY, API_CLIENT_ID, API_SECRET } from '../Utils/Constants';
import { accessTokenAtom, refreshTokenAtom, isGuestAtom } from '../Utils/Atoms';

const containerStyle = {
  width: '100%',
  height: '100%'
};

function CheckCoverage() {
  setKey(GOOGLE_MAPS_API_KEY);

  const [accessToken, setAccessToken] = useRecoilState(accessTokenAtom);
  const [refreshToken, setRefreshToken] = useRecoilState(refreshTokenAtom);
  const [isGuest, setIsGuest] = useRecoilState(isGuestAtom);

  const [address, setAddress] = useState('')
  const [currentLocation, setCurrentLocation] = useState({ lat: 0, lng: 0 })
  const [bounds, setBounds] = useState(null)
  const [map, setMap] = useState(null);
  const [showModal, setShowModal] = useState(false)
  const [modalCode, setModalCode] = useState(0)
  const [withinCoverage, setWithinCoverage] = useState(false)
  const [noCoverage, setNoCoverage] = useState(false)
  const [canProceedGues, setCanProceedGuest] = useState(false);

  const navigate = useNavigate();

  const handleOpenModal = (e: any, modalNumber: number) => {
    e.preventDefault();
    setShowModal(true)
    setModalCode(modalNumber)
  }

  const handleCloseModal = (e: any) => {
    e.preventDefault();
    setShowModal(false)
    setModalCode(0)
  }

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  const onMapLoad = (map:any) => {
    navigator?.geolocation.getCurrentPosition(
      ({ coords: { latitude: lat, longitude: lng } }) => {
        const pos = { lat, lng };
        setCurrentLocation(pos)
      }
    );
    google.maps.event.addListener(map, "bounds_changed", () => {
      setBounds(map.getBounds())
    });
  };

  const generateModalContent = () => {
    return <CheckCoverageModals modalCode={modalCode}/>
  }

  const canProceed = () => {
    if (withinCoverage) {
      setIsGuest(true)
      navigate("/new-order");
    } else {
      setShowModal(true)
      setModalCode(4)
    }
  }

  const initTokens = async () => {
    try {
      const responseToken = await ApiInstance.post(ApiRoutes.GENERATE_TOKEN, {
        client_id: API_CLIENT_ID,
        client_secret: API_SECRET,
        grant_type: "client_credentials",
        scope: "read"
      })

      console.log('resp', responseToken)
      
      // save tokens for later
      const {access_token, refresh_token} = responseToken.data;
      setAccessToken(access_token);
      setRefreshToken(access_token);

    } catch (error: any) {
      if(error.response?.data?.message != undefined) {
        console.log(error.response.data.message);
      } else {
        console.log(error.message);
      }
    } 
  }

  const checkAddress = () => {
    if (address.length === 0) return
    console.log('address', address)

    const checkPickUp = fromAddress(address)

    Promise.all([checkPickUp, 
      // checkDelivery
    ])
      .then((resp) => {
        const pickUpLat = resp[0].results[0].geometry.location.lat
        const pickUpLng = resp[0].results[0].geometry.location.lng
        // const deliveryLat = resp[1].results[0].geometry.location.lat
        // const deliveryLng = resp[1].results[0].geometry.location.lng

        const checkPickUpCoverage = ApiInstance.get(ApiRoutes.GET_COVERAGE,{
          params: {
            lat: pickUpLat,
            lng: pickUpLng
          }
        })
        return Promise.all([checkPickUpCoverage
        ])
          .then((resp) => {
            let pickUpCoverage = resp[0].data

            if (!pickUpCoverage.is_within_coverage
               ) {
              setNoCoverage(true)
              setCanProceedGuest(false)

              // Error modal
              setShowModal(true)
              setModalCode(3)
            } else {
              console.log('ALL GOOD')
              // Showing modal can proceed
              setShowModal(true)
              setModalCode(1)
              setCanProceedGuest(true)
              setWithinCoverage(true)
            }
          }).catch((err) => {
            setShowModal(true)
            setModalCode(5)
            // log that I have an error, return the entire array;
            return console.log('Check coverage error', err);
        })
      }).catch((err) => {
        setShowModal(true)
        setModalCode(5)
        // log that I have an error, return the entire array;
        return console.log(err);
  })
  }

  useEffect(() => {
    initTokens()
  }, []);

  return (
    <MainContainer title={'Check Coverage'}>
<div className="x-master">
  <div className="x-master-wrapper">
    <nav className="x-nav">
      <div className="x-nav-wrapper">
        <div className="x-nav-brand-group">
          <a href="/" className="x-nav-brand-link w-inline-block">
            <img
              src="images/dq-logo-text-color-hr.svg"
              loading="lazy"
              alt=""
              className="x-brand-logo"
            />
          </a>
        </div>
      </div>
    </nav>
    <main className="x-main">
      <h1>How would you like to continue?</h1>
      <div className="x-map-wrapper">
        <div
          className="x-map w-widget w-widget-map"
          data-widget-style="roadmap"
          data-widget-latlng=""
          data-enable-scroll="true"
          role="region"
          title=""
          data-enable-touch="true"
          data-widget-zoom={12}
          data-widget-tooltip=""
        >
         <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={currentLocation}
                zoom={14}
                onLoad={onMapLoad}
                onUnmount={onUnmount}
              >
              </GoogleMap>
            </LoadScript>
        </div>
        <div className="x-form-wrapper for-map w-form">
          <form
            id="wf-form-Coverage-Check-Form"
            name="wf-form-Coverage-Check-Form"
            data-name="Coverage Check Form"
            method="get"
            data-wf-page-id="6530ff537404cd998b7515d1"
            data-wf-element-id="72a6c177-9395-79a1-5674-1c16976127f0"
          >
            <div className="x-form-container">
              <div className="x-form-row">
                <div className="x-form-col">
                <input
                    type="text"
                    className="x-input-text w-input"
                    maxLength={256}
                    name="FullName"
                    data-name="Full Name"
                    // placeholder="Street or Taman"
                    id="FullName"
                    required
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                  <div className="x-btn-container for-inline">
                    <a onClick={() => checkAddress()} className="x-sml-btn w-button">
                      <span className="x-icon for-btn-inline"></span>Check
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="x-actionbar">
        <div className="x-actionbar-block for-mobile-emp">
          <div className="x-actionbar-group">
            <div>
              <h3>Pick Up &amp;&nbsp;Delivery</h3>
              <div className="x-small-text">
                Get us to wash, dry and fold your laundry
              </div>
            </div>
          </div>
          <div className="x-btn-container">
            <a onClick={() => canProceed()} className="x-btn for-full w-button">
              Book as Guest<span className="x-icon for-btn-inline"></span>
            </a>
          </div>
        </div>
        <div className="x-vr-sep for-mob" />
        <div className="x-actionbar-block">
          <div className="x-actionbar-group">
            <div>
              <h3>Get Account</h3>
              <div className="x-small-text">
                Register for a dobiQueen account now
              </div>
            </div>
          </div>
          <div className="x-btn-container">
            <a href="/sign-up" className="x-btn for-full w-button">
              Sign Up<span className="x-icon for-btn-inline"></span>
            </a>
          </div>
        </div>
        <div className="x-vr-sep" />
        <div className="x-actionbar-block">
          <div className="x-actionbar-group">
            <div>
              <h3>Login</h3>
              <div className="x-small-text">Access your dobiQueen account</div>
            </div>
          </div>
          <div className="x-btn-container">
            <a href="/sign-in" className="x-btn for-full w-button">
              Login<span className="x-icon for-btn-inline"></span>
            </a>
          </div>
        </div>
      </div>
    </main>
  </div>
    <Footer/>
</div>



  {/* <div className="x-master">
    <div className="x-master-wrapper">
      <Header />
      <main className="x-main">
        <h1>Check Coverage</h1>
        <div className="x-map w-widget w-widget-map">
        <LoadScript
              googleMapsApiKey={GOOGLE_MAPS_API_KEY}
            >
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={currentLocation}
                zoom={14}
                onLoad={onMapLoad}
                onUnmount={onUnmount}
              >
              </GoogleMap>
            </LoadScript>
        </div>
        <div className="x-form-wrapper w-form">
          <form
            id="wf-form-Coverage-Check-Form"
            name="wf-form-Coverage-Check-Form"
            data-name="Coverage Check Form"
            method="get"
            data-wf-page-id="6532476d68af70aaae186779"
            data-wf-element-id="bc22c12e-3be4-30f2-097d-71aeefc75aa0"
          >
            <div className="x-form-container">
              <div className="x-helper-block">
                <div className="x-icon for-btn-inline is-amber"></div>
                <div>
                  <div className="x-emp-text">
                    Please allow location permission
                  </div>
                  <div>
                    We need your device location permission to get the most
                    accurate coverage verification.
                  </div>
                </div>
              </div>
              <div className="x-form-row">
                <div className="x-form-col">
                  <label htmlFor="FullName">Manual Address Search</label>
                  <input
                    type="text"
                    className="x-input-text w-input"
                    maxLength={256}
                    name="FullName"
                    data-name="Full Name"
                    // placeholder="Street or Taman"
                    id="FullName"
                    required
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                  <div className="x-btn-container for-inline">
                    <a href="#" className="x-sml-btn w-button">
                      <span className="x-icon for-btn-inline"></span>Check
                    </a>
                  </div>
                </div>
              </div>
              <div className="x-form-action">
                <div className="x-btn-container">
                  <a className="x-btn w-button">Start Ordering Now</a>
                </div>
              </div>
            </div>
          </form>
        </div>
      </main>
    </div>
    <HomeFooter />
  </div> */}

{/* MODAL SECTION */}
<div data-toggletarget="dialog-incover" className={`x-dialog ${showModal ? '' : 'for-hide'}`}>
    <div className="x-dialog-wrapper">
      <div className={`x-dialog-form w-form ${showModal ? '' : 'for-hide'}`}>
        <form
          id="wf-form-Guide-Dialog-Form"
          name="wf-form-Guide-Dialog-Form"
          data-name="Guide Dialog Form"
          method="get"
          data-wf-page-id="64fb5b0e4549d64251e557bb"
          data-wf-element-id="cfb25b3e-6032-c0c4-861a-dc1b5db263d9"
        >
          <div className="x-dialog-header">
            <div className="x-dialog-title for-action-icon"></div>
            <div className="x-btn-container">
              <a
                onClick={(e) => handleCloseModal(e)}
                className="x-icon-btn for-simple is-close-modal w-button"
              >
                
              </a>
            </div>
          </div>
          <div className="x-dialog-body">
            <div className="x-form-wrapper">
            <div className="x-form-row">
              {generateModalContent()}
              </div>
              <div className="x-form-action">
                <div className="x-btn-container">
                  <a
                    onClick={(e) => handleCloseModal(e)}
                    className="x-btn for-secondary is-close-modal w-button"
                  >
                    Dismiss
                  </a>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="x-dialog-overlay" />
    </div>
  </div>
</MainContainer>
  );
}
export default CheckCoverage;
