import { useRecoilState } from 'recoil';
import OrderBagObj from '../Utils/OrderBagObj';
import { currentBagAtom, orderBagsAtom } from '../Utils/Atoms';

function AddOnTile(props: any) {
  const { product } = props;

  const [orderBags, setOrderBags] = useRecoilState(orderBagsAtom);
  const currentAddon = orderBags.find((bag: OrderBagObj, index: number) => {
    if(bag.product_id == product.id) {
      return bag;
    }
  });

  let addonQty = 0;
  if(currentAddon !== undefined) {
    addonQty = currentAddon.product_qty;
  }

  const incrementAddOnQty = (e: any): void => {
    const addonBag = orderBags.find((bag: OrderBagObj, index: number) => {
      if(bag.product_id == product.id) {
        return bag;
      }
    });

    // if the bag is undefined make a new one
    let updatedAddon = Object.assign(new OrderBagObj(), addonBag);
    if(addonBag === undefined) {
      updatedAddon = new OrderBagObj();
      updatedAddon.product_id = product.id;
      updatedAddon.category = "addon";
    }

    // increment quantity either way
    updatedAddon.product_qty++;

    // assign a bag ID
    // TODO move this to a utility
    if(updatedAddon.id === 0) {
      updatedAddon.id = orderBags.length + 1;

      let updated = [...orderBags];
      updated.push(updatedAddon);
      setOrderBags(updated);
    } else { // if ID is present, find the existing bag and replace edited bag
      let updatedBags = JSON.parse(JSON.stringify(orderBags)); // deep copy

      updatedBags.find((item: OrderBagObj, index: number) => {
        if(item.id === updatedAddon.id) {
          updatedBags[index] = updatedAddon;
          return true;
        }
      });

      setOrderBags(updatedBags);
    }
  }

  const decrementAddOnQty = (e: any): void => {
    const addonBag = orderBags.find((bag: OrderBagObj, index: number) => {
      if(bag.product_id == product.id) {
        return bag;
      }
    });

    // if the bag is undefined make a new one
    let updatedAddon = Object.assign(new OrderBagObj(), addonBag);
    if(addonBag === undefined) {
      updatedAddon = new OrderBagObj();
      updatedAddon.product_id = product.id;
      updatedAddon.category = "addon";
    }

    // Make sure doesn't go below 0
    if (updatedAddon.product_qty > 0) {
      updatedAddon.product_qty--;
    }

    // assign a bag ID
    // TODO move this to a utility
    if(updatedAddon.id === 0) {
      updatedAddon.id = orderBags.length + 1;

      let updated = [...orderBags];
      updated.push(updatedAddon);
      setOrderBags(updated);
    } else { // if ID is present, find the existing bag and replace edited bag
      let updatedBags = JSON.parse(JSON.stringify(orderBags)); // deep copy

      updatedBags.find((item: OrderBagObj, index: number) => {
        if(item.id === updatedAddon.id) {
          updatedBags[index] = updatedAddon;
          return true;
        }
      });
      setOrderBags(updatedBags);
    }
  }

  return (
    <div className="x-item-choice-block">
    <div className="x-item-choice-header">
      <div id="w-node-_38e9f72b-d0cf-96d0-57c6-e0c88dc4652c-837abe0b">
        {product.name}
      </div>
      <div className="x-small-text">
        <span className="x-brand-emp">
          {`RM${product.unit_price}/each `}
        </span>
        {
          product.description
        }
      </div>
    </div>
    <div className="x-input-number for-sml">
      <input
        type="number"
        className="x-input-text w-input"
        maxLength={4}
        name="HangerAddon"
        data-name="HangerAddon"
        min={0}
        // placeholder={'0'}
        value={addonQty}
        id={product.id}
      />
      <div className="x-input-number-btn-container">
        <a onClick={decrementAddOnQty} className="x-input-number-btn">
          
        </a>
        <a
          onClick={incrementAddOnQty}
          className="x-input-number-btn for-down"
        >
          
        </a>
      </div>
    </div>
    <img
      sizes="(max-width: 479px) 128px, (max-width: 767px) 27vw, 160px"
      alt=""
      src={product.normal_image}
      // loading="lazy"
      className="x-item-choice-thumb"
    />
  </div>
  );
}

export default AddOnTile;