export const TextField = (props: any) => {
  const {
    containerClassName,
    id,
    name,
    label,
    value,
    onChange,
    type = "text",
    maxLength = 256,
    placeholder,
    required,
  } = props;

  return (
    <div className={`x-form-col ${containerClassName}`}>
      <label htmlFor={name}>
        {label}
        {required && <span className="is-required">*</span>}
      </label>
      <input
        id={id}
        name={name}
        data-name={name}
        value={value}
        onChange={onChange}
        type={type}
        className="x-input-text w-input"
        maxLength={maxLength}
        placeholder={placeholder}
        required={required}
      />
    </div>
  );
};
