import { useRecoilState } from 'recoil';

import MainContainer from '../../Components/MainContainer';
import HomeFooter from '../../Components/HomeFooter';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import NotificationRow from '../../Components/NotificationsRow';

import { ApiInstance, ApiRoutes } from '../../Utils/ApiInstance';
import { notificationAtom, unreadNotificationAtom } from '../../Utils/Atoms';

function Notifications() {
  const [notifications, setNotifications] = useRecoilState(notificationAtom);
  const [unreadNotifications, setUnreadNotifications] = useRecoilState(unreadNotificationAtom);

  const displayNotifications = () => {
    return notifications.map((notif : any, index : any) => <NotificationRow notification={notif} key={index} read={(e:any) => handleReadNotification(notif.id)}/>)
  }

  const handleReadNotification = async (notifID:string) => {
    try {
      const response = await ApiInstance.patch(ApiRoutes.GET_NOTIFICATIONS + `/${notifID}`, {
        read: true
      });
      // Get latest notifications
      getNotifications()
    } catch (error) {
      throw error;
    }
  }

  const getNotifications = async () => {
    try {
      const response = await ApiInstance.get(ApiRoutes.GET_NOTIFICATIONS);
      setNotifications(response.data.data);

      // Get number of unread notifications
      let unreadNotifs = response.data.data.filter((notif:any) => notif.read_at == null)
      setUnreadNotifications(unreadNotifs.length)
    } catch (error) {
      throw error;
    }
  }

  return (
    <MainContainer title={'Notifications'}>
<div className="x-master">
  <div className="x-master-wrapper">
    <Header />
    <header className="x-header for-subpage">
      <h1>Notifications</h1>
    </header>
    <main className="x-main for-subpage">
      <div className="x-settings-wrapper">
        <ol role="list" className="x-noti-list w-list-unstyled">
        {displayNotifications()}
        </ol>
      </div>
    </main>
    <HomeFooter />
  </div>
    <Footer />
</div>

</MainContainer>

  );
}

export default Notifications;
