const QuickLinks = (props) => (
  <div className="x-btn-container">
    {
      !props.signedIn ?
      [<a href="/sign-up" className="x-btn for-simple w-button" key={1}>
      Sign Up
    </a>,
    <a href="/sign-in" className="x-btn for-simple w-button" key={2}>
      Login
    </a>] : [
      <a href="/addresses" className="x-btn for-simple w-button" key={3}>
      <span className="x-icon for-btn-inline"></span>My Addresses
    </a>,
    <a href="/orders" className="x-btn for-simple w-button" key={4}>
      <span className="x-icon for-btn-inline"></span>My Orders
    </a>,
    <a href="https://www.dobiqueen.my/contact" target="_blank" className="x-btn for-simple w-button" key={5}>
      <span className="x-icon for-btn-inline"></span>Get Help
    </a>,
    <a href="https://www.dobiqueen.my/stores" target="_blank" className="x-btn for-simple w-button" key={6}>
      <span className="x-icon for-btn-inline"></span>Locations
    </a>
    ]
    }
  <a href="https://www.facebook.com/DobiQueen/" target="_blank" className="x-icon-btn for-simple is-fb w-button" key={7}>
    
  </a>
  <a href="https://www.instagram.com/dobiqueen" target="_blank" className="x-icon-btn for-simple is-ig w-button" key={8}>
    
  </a>
  <a href="https://www.linkedin.com/company/dobiqueenmy/" target="_blank" key={9} className="x-icon-btn for-simple is-in w-button">
    
  </a>
</div>
)

export default QuickLinks