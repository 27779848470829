const NewOrderSubProductItem = (props) => (
  <div className="x-item-choice-block">
    <div className="x-item-choice-header">
      <div id="w-node-_4a220b2c-5695-5c11-521f-4e5af5e470c9-51e557bb">
        {props.subprod.name}
      </div>
      <div className="x-small-text">
        <span className="x-brand-emp">
          {`RM${props.subprod.unit_price}/bag`}
        </span>{" "}
        {`for ${props.subprod.description}.`}
      </div>
    </div>
    <div className="x-input-toggle-block">
      <label className="x-input-toggle w-radio">
        <div className="w-form-formradioinput w-form-formradioinput--inputType-custom x-input-toggle-btn w-radio-input" />
        <input
          id={`subproduct-${props.subprod.id}`}
          type="radio"
          name="BagSize1"
          value={props.subprod.id}
          data-name="BagSize1"
          name={`bag-${props.currentBag.id}-subproduct`}
          checked={props.currentBag.subproduct_id == props.subprod.id}
          style={{
            opacity: 0,
            position: "absolute",
            zIndex: -1,
          }}
          onChange={(e) => props.handleSelectSubProduct(e, props.subprod)}
        />
        <span className="x-input-toggle-label w-form-label">Get this</span>
      </label>
    </div>
    <img
      // loading="lazy"
      src={props.subprod.normal_image}
      alt=""
      className="x-item-choice-thumb"
    />
  </div>
);

export default NewOrderSubProductItem;
