class OrderBagObj {
  id: number;
  product_id: string;
  product_qty: number;
  subproduct_id: string;
  subproduct_qty: number;
  hangers_option: string;
  hangers_qty: number;
  // size: string;
  // iron_qty: number;
  category: string;

  constructor(
    { id,
      product_id,
      subproduct_id,
      // size,
      // iron_qty,
      hangers_option,
      hangers_qty,
      product_qty,
      subproduct_qty,
      category
    }: {
      id?: number;
      product_id?: string;
      subproduct_id?: string;
      // size?: string;
      // iron_qty?: number;
      hangers_option?: string;
      hangers_qty?: number;
      product_qty?: number;
      subproduct_qty?: number;
      category?: string;
    } = {}) {
    this.id = 0;
    this.product_id = '';
    this.subproduct_id = '';
    // this.size = "";
    // this.iron_qty = 0;
    this.hangers_option = "";
    this.hangers_qty = 0;
    this.product_qty = 0;
    this.subproduct_qty = 0;
    this.category = 'laundry';
  }
}

export default OrderBagObj;