import { useRecoilState } from "recoil";
import { productsAtom, shippingMethodsAtom } from "../../Utils/Atoms";
import { getProduct } from "../../Utils/Products";

function SummaryList(props: any) {
  const [products, setProducts] = useRecoilState(productsAtom);
  const [shippingMethods, setShippingMethods] =
    useRecoilState(shippingMethodsAtom);

  const generateLaundryList = () => {
    const list = [...props.orderDetails.order_items].filter(
      (item: any) => item.product_category === "laundry"
    );

    if (list.length) {
      return list.map((item: any, i) => {
        let product = getProduct(item.product_id, products);
        let subtotal =
          product && product?.variant_type == "quantity"
            ? Number(item.product_unit_price) * item.product_qty
            : Number(item.subproduct_unit_price);

        return (
          <li key={i} className="x-pay-summary-item">
            <div className="x-pay-summary-info">
              <div>Bag #{i + 1}</div>
              <div className="x-emp-text for-med">
                {item.product_name}{" "}
                {product.variant_type == "variant" && item.subproduct_name}
              </div>
            </div>
            <div className="x-brand-emp">RM{subtotal.toFixed(2)}</div>
          </li>
        );
      });
    }
  };

  const getAddOnItemsQuantity = (list: Array<any>) => {
    let items: any = {};

    list
      .sort((a, b) => a.product_id - b.product_id)
      .map((item: any, i) => {
        items[item.product_name] = item.product_qty;
      });

    return items;
  };

  const generateAddOnList = () => {
    const list = [...props.orderDetails.order_items].filter(
      (item: any) => item.product_category === "addon"
    );

    if (!list.length) return null;

    const items = getAddOnItemsQuantity(list);
    const emptyItems = Object.values(items).every((qty) => qty === 0);

    if (emptyItems) return null;

    let subtotal = 0;
    list.map((item: any) => {
      subtotal += Number(item.product_qty) * Number(item.product_unit_price);
    });

    return (
      <li className="x-pay-summary-item">
        <div className="x-pay-summary-info">
          <div>Addons</div>
          <div className="x-emp-text for-med">
            {Object.entries(items)
              .filter(([_, quantity]: [string, any]) => quantity > 0)
              .map(([name, quantity], key) => (
                <span key={key}>
                  {`${key ? ", " : ""}${quantity}x ${name}`}
                </span>
              ))}
          </div>
        </div>
        <div className="x-brand-emp">RM{subtotal.toFixed(2)}</div>
      </li>
    );
  };

  const generateDeliveryList = () => {
    const method = shippingMethods.find(
      (item: any) => item.id === props.orderDetails.shipping_method_id
    );

    return (
      <li className="x-pay-summary-item">
        <div className="x-pay-summary-info">
          <div>Delivery</div>
          <div className="x-emp-text for-med">
            {/* Pickup &amp; Deliver at Doorstep */}
            {method?.name}
          </div>
        </div>
        <div className="x-brand-emp">RM{props.deliveryTotal.toFixed(2)}</div>
      </li>
    );
  };

  return (
    <div className="x-pay-summary-block">
      <h3>Service Details</h3>
      <ol role="list" className="x-pay-summary-list w-list-unstyled">
        {generateLaundryList()}
        {generateAddOnList()}
        {generateDeliveryList()}
      </ol>
      <div className="x-hr-sep" />
      <div className="x-emp-text for-med">
        Remarks: {`${props.orderDetails.pickup_delivery_remarks ?? "-"}`}
      </div>
      <div className="x-emp-text for-med">
        Pickup Timing:{" "}
        {`${props.orderDetails.pickup_schedule_date ?? ""} ${
          props.orderDetails.pickup_schedule_slot
        }`}
      </div>
      <div className="x-emp-text for-med">
        Return Timing:{" "}
        {`${props.orderDetails.delivery_schedule_date ?? ""} ${
          props.orderDetails.delivery_schedule_slot === "0"
            ? ""
            : props.orderDetails.delivery_schedule_slot
        }`}
      </div>
    </div>
  );
}

export default SummaryList;
