const MyOrderEmptyState = () => (
  <div className="x-empty-placholder">
  <img
    src="images/62.svg"
    // loading="lazy"
    alt=""
    className="x-empty-placeholder-img"
  />
  <div>You have no orders.</div>
</div>
  )

export default MyOrderEmptyState