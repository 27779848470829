import { useState } from 'react';

import MainContainer from '../Components/MainContainer';
import HomeFooter from '../Components/HomeFooter';
import Footer from '../Components/Footer';
import Header from '../Components/Header';
import ErrorModal from '../Components/Modals/ErrorModal';

import { ApiInstance, ApiRoutes } from '../Utils/ApiInstance';

function TopUp() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [customAmount, setCustomAmount] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false)

  const handleOpenModal = (errorMessage: string) => {
    setErrorMessage(errorMessage)
    setShowModal(true)
  }

  const handleCloseModal = (e: any) => {
    e.preventDefault();
    setShowModal(false)
  }

  const generateModalContent = () => {
    return <ErrorModal description={errorMessage}/>
  } 

  const topUpToken = async (amount: any) => {
    // disable buttons etc.
    setIsSubmitting(true);

    if (amount < 10) {
        let errorMessage = "Minimum top up is RM10"
        handleOpenModal(errorMessage)
      return 
    }

    try {
      const response = await ApiInstance.post(ApiRoutes.POST_TOPUPS, {
        "payment_method": 1,
        "amount": amount,
        "handover": true 
      })

      let handoverURL = response.data.handover.handover_url

      // redirect to payment page
      window.location.replace(handoverURL)

    } catch (error: any) {
      if(error.response?.data?.message != undefined) {
        console.log(error.response.data.message);
        setErrorMessage(error.response.data.message);
        handleOpenModal(error.response.data.message);
      } else {
        setErrorMessage(error.message);
        handleOpenModal(error.message);
      }

    } finally {
      // after all is said and done re-enable submit
      setIsSubmitting(false);
    }
  }

  return (
    <MainContainer title={'Top Up'}>
  <div className="x-master">
    <div className="x-master-wrapper">
      <Header />
      <header className="x-header for-subpage">
        <h1>Top Up</h1>
      </header>
      <main className="x-main">
        <h2>Offers</h2>
        <div>Get extra tokens when you top up above RM20.</div>
        <div className="x-cartbar">
          <ol role="list" className="x-cartbar-list w-list-unstyled">
            <li className="x-cartbar-item">
              <div className="x-cartbar-info">
                <div className="x-cartbar-info-header">
                  <div className="x-emp-text">Top Up RM20</div>
                  <div
                    data-tippy-content="Time left until order expires. Expired orders cannot be checkedout."
                    className="x-cartbar-info-exp"
                  >
                    Get 21 TOKENS
                  </div>
                </div>
                <div className="x-cartbar-info-opt">
                  <div className="x-emp-text for-med">Also get 20 Points</div>
                </div>
              </div>
              <div className="x-btn-container">
                <a onClick={()=> topUpToken(20)} className="x-btn for-secondary w-button">
                  Get This<span className="x-icon for-btn-inline"></span>
                </a>
              </div>
            </li>
            <li className="x-cartbar-item">
              <div className="x-cartbar-info">
                <div className="x-cartbar-info-header">
                  <div className="x-emp-text">Top Up RM40</div>
                  <div
                    data-tippy-content="Time left until order expires. Expired orders cannot be checkedout."
                    className="x-cartbar-info-exp"
                  >
                    Get 42 TOKENS
                  </div>
                </div>
                <div className="x-cartbar-info-opt">
                  <div className="x-emp-text for-med">Also get 40 Points</div>
                </div>
              </div>
              <div className="x-btn-container">
                <a onClick={()=> topUpToken(40)} className="x-btn w-button">
                  Best Deal<span className="x-icon for-btn-inline"></span>
                </a>
              </div>
            </li>
            {/* <li className="x-cartbar-item">
              <div className="x-cartbar-info">
                <div className="x-cartbar-info-header">
                  <div className="x-emp-text">
                    Top Up {"{"}
                    {"{"}Amount{"}"}
                    {"}"}
                  </div>
                  <div
                    data-tippy-content="Time left until order expires. Expired orders cannot be checkedout."
                    className="x-cartbar-info-exp"
                  >
                    Get {"{"}
                    {"{"}TokenVal{"}"}
                    {"}"} TOKENS
                  </div>
                </div>
                <div className="x-cartbar-info-opt">
                  <div className="x-emp-text for-med">
                    Also get {"{"}
                    {"{"}PointVal{"}"}
                    {"}"} Points
                  </div>
                </div>
              </div>
              <div className="x-btn-container">
                <a href="#" className="x-btn w-button">
                  Best Deal<span className="x-icon for-btn-inline"></span>
                </a>
              </div>
            </li> */}
          </ol>
        </div>
        <div className="x-tokenbar">
          <h2>Custom Amount</h2>
          <div>Reload any custom amount from as low as RM10.</div>
          <div className="x-form-wrapper w-form">
            <form
              id="wf-form-Custom-Top-Up-Form"
              name="wf-form-Custom-Top-Up-Form"
              data-name="Custom Top Up Form"
              method="get"
              data-wf-page-id="6504a04e7c2d9754f75c0518"
              data-wf-element-id="3e891f42-b0ab-f0a5-bc02-067e9ae38018"
            >
              <div className="x-form-container">
                <div className="x-form-row">
                  <div className="x-form-col">
                    <div className="x-input-flex">
                      <div className="x-input-prefix">RM</div>
                      <input
                        type="tel"
                        className="x-input-text for-prefix w-input"
                        maxLength={256}
                        name="CustomTopUpVal"
                        data-name="CustomTopUpVal"
                        id="CustomTopUpVal"
                        value={customAmount}
                        onChange={(e) => setCustomAmount(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="x-form-col for-fit">
                    <div className="x-btn-container">
                    <a
                      onClick={(e) => topUpToken(Number(customAmount))} 
                      type="submit"
                      className="x-btn w-button"
                    >
                      Submit
                    </a>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </main>
      <HomeFooter />
    </div>
    <Footer />
  </div>
      {/* MODAL SECTION */}
      <div data-toggletarget="dialog-addons-guide" className={`x-dialog ${showModal ? '' : 'for-hide'}`}>
    <div className="x-dialog-wrapper">
      <div className={`x-dialog-form w-form ${showModal ? '' : 'for-hide'}`}>
        <form
          id="wf-form-Guide-Dialog-Form"
          name="wf-form-Guide-Dialog-Form"
          data-name="Guide Dialog Form"
          method="get"
          data-wf-page-id="64fc904b6e1ec367837abe0b"
          data-wf-element-id="cfb25b3e-6032-c0c4-861a-dc1b5db263d9"
        >
          <div className="x-dialog-header">
            <div className="x-dialog-title for-action-icon"></div>
            <div className="x-btn-container">
              <a
                onClick={(e) => handleCloseModal(e)}
                className="x-icon-btn for-simple is-close-modal w-button"
              >
                
              </a>
            </div>
          </div>
          <div className="x-dialog-body">
            <div className="x-form-wrapper">
              <div className="x-form-row">
              {generateModalContent()}
              </div>
              <div className="x-form-action">
                <div className="x-btn-container">
                  <a
                    onClick={(e) => handleCloseModal(e)}
                    className="x-btn for-secondary is-close-modal w-button"
                  >
                    Dismiss
                  </a>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="x-dialog-overlay" />
    </div>
  </div>
</MainContainer>
  );
}

export default TopUp;
