const NewOrderModals = (props: any) => {
  if (props.modalCode === 1) {
    return (
      <div className="x-form-col">
        <img
          alt=""
          src="images/shopping-bag-line.svg"
          className="x-message-thumb for-dialog"
        />
        <div className="x-dialog-title for-action">
          Prepare Laundry in Your Own Bags
        </div>
        <p>
          Prepare your own laundry bags, and choose your laundry service and bag
          size. You can add more bags to your order.
        </p>
      </div>
    );
  }
  if (props.modalCode === 2) {
    return (
      <div className="x-form-col">
        <img
          alt=""
          src="images/t-shirt-air-fill.svg"
          className="x-message-thumb for-dialog"
        />
        <div className="x-dialog-title for-action">Choose Laundry Service</div>
        <p>Select the appropriate laundry service that best fit your needs.</p>
      </div>
    );
  }
  if (props.modalCode === 3) {
    return (
      <div className="x-form-col">
        <img
          alt=""
          src="images/shopping-bag-line.svg"
          className="x-message-thumb for-dialog"
        />
        <div className="x-dialog-title for-action">
          Choose the Correct Bag Size
        </div>
        <p>
          We offer 3 different bag sizes that caters to most customer needs.
          Choose the correct bag size to avoid potential order issues.
        </p>
      </div>
    );
  }
  if (props.modalCode === 4) {
    return (
      <div className="x-form-col">
        <img
          alt=""
          src="images/t-shirt-2-fill.svg"
          className="x-message-thumb for-dialog"
        />
        <div className="x-dialog-title for-action">By Clothing Pieces Only</div>
        <p>
          Our Wash, Dry, Steam &amp; Hang laundry service goes by individual
          clothing pieces only to ensure quality results.
        </p>
      </div>
    );
  }
  return null;
};

export default NewOrderModals;
