import React from "react";
import { PhoneInput } from "react-international-phone";

export const PhoneField = (props: any) => {
  const { inputRef, mobile, setMobile, disabled } = props;
  return (
    <div className="x-form-col">
      <label htmlFor="Mobile">Mobile</label>
      {/* <input
          className="x-input-text for-mobile w-input"
          maxLength={11}
          name="Mobile"
          data-name="Mobile"
          placeholder=""
          type="tel"
          id="Mobile"
          onChange={(e) => setMobile(e.target.value)}
        /> */}
      <div
        className={`x-input-text for-mobile w-input ${
          disabled ? "disabled" : ""
        }`}
      >
        <PhoneInput
          ref={inputRef}
          // name="Mobile"
          // data-name="Mobile"
          // id="Mobile"
          defaultCountry="my"
          value={mobile}
          onChange={(phone) => setMobile(phone)}
          forceDialCode={true}
          inputStyle={{ border: 0 }}
          countrySelectorStyleProps={{}}
          dialCodePreviewStyleProps={{}}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
