import { useState } from "react";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";

import MainContainer from "../../../Components/MainContainer";
import HomeFooter from "../../../Components/HomeFooter";
import Footer from "../../../Components/Footer";
import Header from "../../../Components/Header";
import ErrorModal from "../../../Components/Modals/ErrorModal";

import OrderObj from "../../../Utils/OrderObj";
import { ApiInstance, ApiRoutes } from "../../../Utils/ApiInstance";
import {
  profileAtom,
  isSignedInAtom,
  currentOrderAtom,
  orderBagsAtom,
  productsAtom,
  accessTokenAtom,
  refreshTokenAtom,
  showBagFormAtom,
} from "../../../Utils/Atoms";

function Security() {
  const [profile, setProfile] = useRecoilState(profileAtom);
  const [signedIn, setSignedIn] = useRecoilState(isSignedInAtom);
  const [products, setProducts] = useRecoilState(productsAtom);
  const [accessToken, setAccessToken] = useRecoilState(accessTokenAtom);
  const [refreshToken, setRefreshToken] = useRecoilState(refreshTokenAtom);
  const [currentOrder, setCurrentOrder] = useRecoilState(currentOrderAtom);
  const [orderBags, setOrderBags] = useRecoilState(orderBagsAtom);
  const [showBagForm, setShowBagForm] = useRecoilState(showBagFormAtom);

  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string | null>("");
  const [showModal, setShowModal] = useState(false);
  const [modalCode, setModalCode] = useState(0);

  // var for form
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const navigate = useNavigate();

  const handleOpenModal = (
    modalNumber: number,
    errorMessage?: string | undefined
  ) => {
    // 0: Error message
    // 1: Account removal
    setModalCode(modalNumber);
    if (modalNumber === 0) setErrorMessage(errorMessage ?? "");
    setShowModal(true);
  };

  const handleCloseModal = (e: any) => {
    e.preventDefault();
    setShowModal(false);
    setModalCode(0);
  };

  const generateModalContent = () => {
    if (modalCode === 0) return <ErrorModal description={errorMessage} />;
    else if (modalCode === 1)
      return (
        <ErrorModal
          title="Account Deletion Cooldown"
          description="You have requested to delete your dobiQueen account. After confirmation, your account will enter a 365-day cooldown period before permanent deletion. If you sign up again with the same details during this period, your account will be restored, and the cooldown will reset."
        />
      );
  };

  const generateModalButton = () => {
    if (modalCode === 0)
      return (
        <div className="x-btn-container">
          <a
            onClick={(e) => handleCloseModal(e)}
            className="x-btn for-secondary is-close-modal w-button"
          >
            Dismiss
          </a>
        </div>
      );
    else if (modalCode === 1)
      return (
        <div className="x-btn-container">
          <a
            onClick={(e) => deleteAccount(e)}
            className="x-btn for-secondary is-close-modal w-button"
          >
            I understand, proceed to delete
          </a>
        </div>
      );
  };

  const showHidePassword = (data: any) => {
    data.preventDefault();
    showPassword ? setShowPassword(false) : setShowPassword(true);
    return null;
  };

  const deleteAccount = async (e: any) => {
    e.preventDefault();
    try {
      const responseDelete = await ApiInstance.post(ApiRoutes.DELETE_PROFILE, {
        _method: "DELETE",
      });
      // console.log("Deleted account:", responseDelete.data)

      setSignedIn(false);
      setProfile([]);
      setCurrentOrder(new OrderObj());
      setOrderBags([]);
      setProducts([]);
      setAccessToken("");
      setRefreshToken("");
      setShowBagForm(true);

      navigate("/");
    } catch (error: any) {
      if (error.response?.data?.message != undefined) {
        console.log(error.response.data.message);
        setErrorMessage(error.response.data.message);
        handleOpenModal(0, error.response.data.message);
      } else {
        setErrorMessage(error.response);
        handleOpenModal(0, error.response);
      }
    }
  };

  const handleUpdate = async (data: any) => {
    data.preventDefault();

    // disable buttons etc.
    setIsSubmitting(true);

    // Validation
    if (currentPassword.length === 0) {
      handleOpenModal(0, "Current password required");
      return;
    }
    if (newPassword.length === 0) {
      handleOpenModal(0, "New password required");
      return;
    }

    const updatedProfile = {
      mobile_number: profile.phone.number,
      country_code: profile.phone.country_code,
      current_password: currentPassword,
      password: newPassword,
      password_confirmation: newPassword,
    };

    try {
      const response = await ApiInstance.put(
        ApiRoutes.UPDATE_PROFILE,
        updatedProfile
      );

      // console.log("Password updated!", response.data)
      navigate("/settings");
    } catch (error: any) {
      if (error.response?.data?.message != undefined) {
        console.log(error.response.data.message);
        setErrorMessage(error.response.data.message);
        handleOpenModal(0, error.response.data.message);
      } else {
        setErrorMessage(error.message);
        handleOpenModal(0, error.message);
      }
    } finally {
      // after all is said and done re-enable submit
      setIsSubmitting(false);
    }
  };

  return (
    <MainContainer title={"Account"}>
      <div className="x-master">
        <div className="x-master-wrapper">
          <Header />
          <header className="x-header for-subpage">
            <h1>Security</h1>
          </header>
          <main className="x-main for-subpage">
            <div className="x-breadcrumbbar">
              <a href="/" className="x-breadcrumb-link">
                Home
              </a>
              <a href="/settings" className="x-breadcrumb-link">
                Settings
              </a>
              <a href="/security" className="x-breadcrumb-link">
                Security
              </a>
            </div>
            <div className="x-settings-wrapper">
              <div className="x-form-wrapper w-form">
                <form
                  id="wf-form-Settings-Form"
                  name="wf-form-Settings-Form"
                  data-name="Settings Form"
                  method="get"
                  data-wf-page-id="65005dd39a18fc9e757ed931"
                  data-wf-element-id="eaec668c-9260-a96b-558d-3698e995ae2d"
                >
                  <div className="x-form-body">
                    <div className="x-form-container">
                      <h2>Password</h2>
                      <div className="x-form-row" key={0}>
                        <div className="x-form-col for-med">
                          <label htmlFor="CurrentPassword">
                            Current Password
                          </label>
                          <input
                            type="password"
                            className="x-input-text w-input"
                            maxLength={256}
                            name="CurrentPassword"
                            data-name="CurrentPassword"
                            // placeholder="Current Password"
                            id="CurrentPassword"
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            value={currentPassword}
                            required
                          />
                        </div>
                      </div>
                      <div className="x-form-row" key={1}>
                        <div className="x-form-col for-med">
                          <label htmlFor="Password">New Password</label>
                          <input
                            type={showPassword ? "text" : "password"}
                            className="x-input-text w-input"
                            maxLength={256}
                            name="Password"
                            data-name="Password"
                            // placeholder="New Password"
                            id="Password"
                            required
                            onChange={(e) => setNewPassword(e.target.value)}
                            value={newPassword}
                          />
                          <a
                            id="PassUnmask"
                            className="x-input-opt-btn for-active"
                            onClick={(e) => showHidePassword(e)}
                          >
                            Check
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="x-form-container">
                      {/* <h2>Login Control</h2>
                <div className="x-special-input-group">
                  <div className="x-special-input">
                    <label className="w-checkbox x-input-chkbox">
                      <div className="w-checkbox-input w-checkbox-input--inputType-custom x-input-chkbox-btn" />
                      <input
                        type="checkbox"
                        id="Check-Remember-Me"
                        name="Check-Remember-Me"
                        data-name="Check Remember Me"
                        style={{ opacity: 0, position: "absolute", zIndex: -1 }}
                      />
                      <span
                        className="x-input-chkbox-label w-form-label"
                      >
                        Remember me until I log out.
                      </span>
                    </label>
                  </div>
                </div> */}
                      <div className="x-form-action">
                        <div className="x-btn-container">
                          <a
                            onClick={(e) => handleUpdate(e)}
                            className="x-btn w-button"
                          >
                            Submit
                          </a>
                          <a
                            href="/settings"
                            className="x-btn for-simple w-button"
                          >
                            Cancel
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="x-form-container">
                      <h2>Account Removal</h2>
                      <div className="x-form-row">
                        <div className="x-form-col">
                          <div className="x-btn-container">
                            <a
                              onClick={(e) => handleOpenModal(1)}
                              className="x-sml-btn for-simple is-red w-button"
                            >
                              <span className="x-icon for-btn-inline"></span>
                              Remove my Account
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="x-helper-block">
                        <div className="x-icon for-btn-inline is-amber"></div>
                        <div>
                          <div className="x-emp-text">
                            Account Deletion Cooldown
                          </div>
                          <div>
                            A 365 days cooldown period applicable when you
                            request to delete your account.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </main>
          <HomeFooter />
        </div>
        <Footer />
      </div>
      {/* MODAL SECTION */}
      <div
        data-toggletarget="dialog-addons-guide"
        className={`x-dialog ${showModal ? "" : "for-hide"}`}
      >
        <div className="x-dialog-wrapper">
          <div
            className={`x-dialog-form w-form ${showModal ? "" : "for-hide"}`}
          >
            <form
              id="wf-form-Guide-Dialog-Form"
              name="wf-form-Guide-Dialog-Form"
              data-name="Guide Dialog Form"
              method="get"
              data-wf-page-id="64fc904b6e1ec367837abe0b"
              data-wf-element-id="cfb25b3e-6032-c0c4-861a-dc1b5db263d9"
            >
              <div className="x-dialog-header">
                <div className="x-dialog-title for-action-icon"></div>
                <div className="x-btn-container">
                  <a
                    onClick={(e) => handleCloseModal(e)}
                    className="x-icon-btn for-simple is-close-modal w-button"
                  >
                    
                  </a>
                </div>
              </div>
              <div className="x-dialog-body">
                <div className="x-form-wrapper">
                  <div className="x-form-row">{generateModalContent()}</div>
                  <div className="x-form-action">{generateModalButton()}</div>
                </div>
              </div>
            </form>
          </div>
          <div className="x-dialog-overlay" />
        </div>
      </div>
    </MainContainer>
  );
}

export default Security;
