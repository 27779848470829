const AddressSelectModals = (props: any) => {
  if (props.modalCode === 1) {
    return (
      <div className="x-form-col">
        <img
          alt=""
          src="images/pin-distance-fill.svg"
          className="x-message-thumb for-dialog"
        />
        <div className="x-dialog-title for-action">
          Start Checking your Addresses
        </div>
        <p>
          We'll need your Pickup and Delivery addresses to ensure both locations
          are covered by dobiQueen. Ensure the addresses provided are accurate
          to avoid potential order issues.
        </p>
      </div>
    );
  }
  return null;
};

export default AddressSelectModals;
