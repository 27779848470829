export const RadioButtonOption = (props: any) => {
  const { id, value, onChange, checked, name, label } = props;

  return (
    <label className="x-input-toggle w-radio">
      <div className="w-form-formradioinput w-form-formradioinput--inputType-custom x-input-toggle-btn w-radio-input" />
      <input
        type="radio"
        id={id}
        value={value}
        onChange={onChange}
        checked={checked}
        name={name}
        data-name={name}
        style={{
          opacity: 0,
          position: "absolute",
          zIndex: -1,
        }}
      />
      <span className="x-input-toggle-label w-form-label">{label}</span>
    </label>
  );
};
