import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";

import MainContainer from "../../../Components/MainContainer";
import HomeFooter from "../../../Components/HomeFooter";
import Footer from "../../../Components/Footer";
import Header from "../../../Components/Header";
import ErrorModal from "../../../Components/Modals/ErrorModal";

import { ApiInstance, ApiRoutes } from "../../../Utils/ApiInstance";
import { profileAtom } from "../../../Utils/Atoms";
import { PhoneField } from "../../../Components/Forms/PhoneField";

function Account() {
  const phoneRef = React.useRef<any>(null);

  const [profile, setProfile] = useRecoilState(profileAtom);

  const [username, setUsername] = useState(profile.email);
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [requestedTAC, setRequestedTAC] = useState(false);
  // const [phoneResponse, setPhoneResponse] = useState({ id: 0 });

  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  // var for form
  const [fullName, setFullName] = useState(profile.name);
  const [preferredName, setPreferredName] = useState(profile.preferred_name);
  const [mobile, setMobile] = useState(profile?.phone?.formatted_number);
  const [userID, setUserID] = useState(profile.id);
  // const [tac, setTAC] = useState("");

  const navigate = useNavigate();

  const handleOpenModal = (errorMessage: string) => {
    setErrorMessage(errorMessage);
    setShowModal(true);
  };

  const handleCloseModal = (e: any) => {
    e.preventDefault();
    setShowModal(false);
  };

  const generateModalContent = () => {
    return <ErrorModal description={errorMessage} />;
  };

  const getProfile = async () => {
    try {
      const response = await ApiInstance.get(ApiRoutes.GET_PROFILE);
      setProfile(response.data);
    } catch (error) {
      throw error;
    }
  };

  // const handleTAC = async () => {
  //   // disable buttons etc.
  //   setIsSubmitting(true);

  //   let phoneID = phoneResponse.id;

  //   try {
  //     const response = await ApiInstance.put(ApiRoutes.SUBMIT_OTP, {
  //       phone_id: phoneID,
  //       code: tac,
  //     });

  //     // grab persistent profile data
  //     getProfile();
  //     navigate("/settings");
  //   } catch (error: any) {
  //     if (error.response?.data?.message != undefined) {
  //       console.log(error.response.data.message);
  //       setErrorMessage(error.response.data.message);
  //       handleOpenModal(error.response.data.message);
  //     } else {
  //       setErrorMessage(error.message);
  //       handleOpenModal(error.message);
  //     }
  //   } finally {
  //     // after all is said and done re-enable submit, reset form
  //     setIsSubmitting(false);
  //     // setRequestedTAC(false);
  //     // setPhoneResponse({ id: 0 });
  //     // setTAC("");
  //   }
  // };

  const handleUpdate = async (data: any) => {
    data.preventDefault();

    let countryCode = phoneRef.current?.state?.country?.dialCode;
    let phoneNumber = mobile.replace(`+${countryCode}`, "");

    // disable buttons etc.
    setIsSubmitting(true);

    // Validation
    if (!fullName || fullName.length === 0) {
      handleOpenModal("Full name required");
      return;
    }
    if (!preferredName || preferredName.length === 0) {
      handleOpenModal("Preferred name required");
      return;
    }
    if (!username || username.length === 0) {
      handleOpenModal("Email required");
      return;
    }
    if (!phoneNumber || phoneNumber.length === 0) {
      handleOpenModal("Mobile number required");
      return;
    }

    // if (requestedTAC) {
    //   return handleTAC();
    // }

    const updatedProfile = {
      name: fullName,
      preferred_name: preferredName,
      email: username,
      mobile_number: phoneNumber,
      country_code: countryCode,
      _method: "PUT",
    };

    try {
      const response = await ApiInstance.post(
        ApiRoutes.UPDATE_PROFILE,
        updatedProfile
      );
      console.log({ response });

      // console.log("Profile updated!", response.data.customer);
      // update profile
      setProfile(response.data);
      // set phone response in case of TAC
      // setPhoneResponse(response.data.phone);

      // Determine if changing phone number to add TAC flow
      // if (phoneNumber !== profile?.phone?.number) {
      //   console.log("requested TAC");
      //   setRequestedTAC(true);
      // } else {
      navigate("/settings");
      // }
    } catch (error: any) {
      console.log({ error });
      if (error.response?.data?.message != undefined) {
        console.log(error.response.data.message);
        setErrorMessage(error.response.data.message);
        handleOpenModal(error.response.data.message);
      } else {
        setErrorMessage(error.message);
        handleOpenModal(error.message);
      }
    } finally {
      // after all is said and done re-enable submit
      setIsSubmitting(false);
    }
  };

  return (
    <MainContainer title={"Account"}>
      <div className="x-master">
        <div className="x-master-wrapper">
          <Header />
          <header className="x-header for-subpage">
            <h1>Account</h1>
          </header>
          <main className="x-main for-subpage">
            <div className="x-breadcrumbbar">
              <a href="/" className="x-breadcrumb-link">
                Home
              </a>
              <a href="/settings" className="x-breadcrumb-link">
                Settings
              </a>
              <a href="/account" className="x-breadcrumb-link">
                Account
              </a>
            </div>
            <div className="x-settings-wrapper">
              <div className="x-form-wrapper w-form">
                <form
                  id="wf-form-Settings-Form"
                  name="wf-form-Settings-Form"
                  data-name="Settings Form"
                  method="get"
                  data-wf-page-id="650058074c36ec96688f362f"
                  data-wf-element-id="eaec668c-9260-a96b-558d-3698e995ae2d"
                >
                  <div className="x-form-body">
                    <div className="x-form-container">
                      <h2>Personal Information</h2>
                      <div className="x-form-row">
                        <div className="x-form-col">
                          <label htmlFor="FullName">Full Name</label>
                          <input
                            type="text"
                            className="x-input-text w-input"
                            maxLength={256}
                            name="FullName"
                            data-name="FullName"
                            // placeholder="Johnny Doe"
                            onChange={(e) => setFullName(e.target.value)}
                            id="FullName"
                            value={fullName}
                            required
                          />
                        </div>
                      </div>
                      <div className="x-form-row">
                        <div className="x-form-col for-med">
                          <label htmlFor="PreferName">Preferred Name</label>
                          <input
                            type="text"
                            className="x-input-text w-input"
                            maxLength={256}
                            name="PreferName"
                            data-name="PreferName"
                            // placeholder="Johnny"
                            id="PreferName"
                            value={preferredName}
                            onChange={(e) => setPreferredName(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="x-form-row">
                        <div className="x-form-col">
                          <label htmlFor="Email">Email</label>
                          <input
                            type="email"
                            className="x-input-text w-input"
                            maxLength={256}
                            name="Email"
                            data-name="Email"
                            value={username}
                            // placeholder="johnny.doe@dobiqueen.my"
                            onChange={(e) => setUsername(e.target.value)}
                            id="Email"
                            required
                          />
                        </div>
                        <PhoneField
                          inputRef={phoneRef}
                          mobile={mobile}
                          setMobile={setMobile}
                          disabled={true}
                        />
                      </div>
                      <div className="x-form-row">
                        <div className="x-form-col for-med">
                          <label htmlFor="Email-2">Member ID</label>
                          <div className="x-input-flex">
                            <div className="x-input-prefix">ID</div>
                            <input
                              type="text"
                              className="x-input-text for-prefix w-input"
                              maxLength={256}
                              name="MemberID"
                              data-name="MemberID"
                              value={userID}
                              id="MemberID"
                              required
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      {/* {requestedTAC ? (
                        <div className="x-form-row">
                          <div className="x-form-col for-med">
                            <label htmlFor="TAC">TAC</label>
                            <div className="x-input-flex">
                              <div className="x-input-prefix">TAC</div>
                              <input
                                type="text"
                                className="x-input-text for-prefix w-input"
                                maxLength={8}
                                name="TAC"
                                data-name="TAC"
                                id="TAC"
                                required
                                onChange={(e) => setTAC(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      ) : null} */}
                      <div className="x-form-action">
                        <div className="x-btn-container">
                          <a
                            onClick={(e) => handleUpdate(e)}
                            className="x-btn w-button"
                          >
                            Submit
                          </a>
                          <a
                            href="/settings"
                            className="x-btn for-simple w-button"
                          >
                            Cancel
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </main>
          <HomeFooter />
        </div>
        <Footer />
      </div>
      {/* MODAL SECTION */}
      <div
        data-toggletarget="dialog-addons-guide"
        className={`x-dialog ${showModal ? "" : "for-hide"}`}
      >
        <div className="x-dialog-wrapper">
          <div
            className={`x-dialog-form w-form ${showModal ? "" : "for-hide"}`}
          >
            <form
              id="wf-form-Guide-Dialog-Form"
              name="wf-form-Guide-Dialog-Form"
              data-name="Guide Dialog Form"
              method="get"
              data-wf-page-id="64fc904b6e1ec367837abe0b"
              data-wf-element-id="cfb25b3e-6032-c0c4-861a-dc1b5db263d9"
            >
              <div className="x-dialog-header">
                <div className="x-dialog-title for-action-icon"></div>
                <div className="x-btn-container">
                  <a
                    onClick={(e) => handleCloseModal(e)}
                    className="x-icon-btn for-simple is-close-modal w-button"
                  >
                    
                  </a>
                </div>
              </div>
              <div className="x-dialog-body">
                <div className="x-form-wrapper">
                  <div className="x-form-row">{generateModalContent()}</div>
                  <div className="x-form-action">
                    <div className="x-btn-container">
                      <a
                        onClick={(e) => handleCloseModal(e)}
                        className="x-btn for-secondary is-close-modal w-button"
                      >
                        Dismiss
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="x-dialog-overlay" />
        </div>
      </div>
    </MainContainer>
  );
}

export default Account;
