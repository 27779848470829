const NavMobileMenu = (props) => (
<div className="x-nav-mobile-menu">
<div>Connect with us</div>
<div className="x-btn-container">
  <a href="https://www.facebook.com/DobiQueen/" target="_blank" className="x-icon-btn for-simple is-fb w-button">
    
  </a>
  <a href="https://www.instagram.com/dobiqueen" target="_blank" className="x-icon-btn for-simple is-ig w-button">
    
  </a>
  <a href="https://www.linkedin.com/company/dobiqueenmy/" target="_blank" className="x-icon-btn for-simple is-in w-button">
    
  </a>
</div>
</div>
)

export default NavMobileMenu