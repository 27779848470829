const ApiRoutes = {
  GENERATE_TOKEN: '/oauth/token',
  REVOKE_TOKEN: '/oauth/revoke',
  GET_PROFILE: '/customer/profile',
  RESTORE_PROFILE: '/customers/restore',
  DELETE_PROFILE: '/customer/profile',
  SIGNUP: '/customers',
  POST_DEVICE_UUID: '/customer/device-uuid',
  GENERATE_OTP: '/mobile-number',
  SUBMIT_OTP: '/mobile-number',
  UPDATE_PROFILE: '/customer/profile',
  DELETE_ACCOUNT: '/customer/profile',
  GET_ADS: '/ads',
  GET_NOTIFICATIONS: '/notifications',
  GET_SHOPS: '/shops',
  POST_TOPUPS: '/topups',
  GET_PRODUCTS: '/products',
  GET_TIMESLOTS: '/order_slots',
  GET_ORDERS: '/orders',
  POST_ORDERS: '/orders',
  UPDATE_ORDER_STATUS: '/orders/update-order-status',
  CANCEL_ORDER: '/orders/cancel-order',
  GET_COVERAGE: '/check-coverage',
  GET_SHIPPING_METHODS: '/shipping-methods',
  GET_CITY: '/cities',
  GET_SETTINGS: '/settings'
};

export default ApiRoutes;
