import moment from "moment";

const isoDate = (date: Date): string => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

const dayName = (date: Date): string => {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  return days[date.getDay()];
}

const convertToAmPm = (slot: string): string => {
  let ampm = slot;
  let hourString = '';
  let minString = '';

  if(slot.length === 3) {
    hourString = ampm.substring(0,1);
    minString = ampm.substring(1,3);
  } else {
    hourString = ampm.substring(0,2);
    minString = ampm.substring(2,4);
  }

  let hour = Number(hourString) % 12 || 12;

  ampm = hour + ":" + minString + (Number(hourString) < 12 ? "am" : "pm");

  return ampm;
}

const convertTimeToMinutes = (timeString: any) => {
  const [hourString, minuteString] = timeString.match(/\d+/g); // Extract hour and minute parts
  let hour = parseInt(hourString, 10); // Parse hour part as integer
  const minute = parseInt(minuteString || '0', 10); // Parse minute part as integer (default to 0 if absent)
  
  if (timeString.toLowerCase().includes('pm')) {
    // If time is in PM, add 12 to the hour (unless it's 12pm)
    hour = hour === 12 ? 12 : hour + 12;
  } else if (timeString.toLowerCase().includes('am') && hour === 12) {
    // If time is in AM and hour is 12, set hour to 0 (midnight)
    hour = 0;
  }
  
  return hour * 60 + minute; // Convert to minutes
};

const getStartTime = (timeRange: any) => {
  const times = timeRange.split(' - ')
  return times[0]
}

const isNextDayDelivery = (time: any) => {
  const targetTime =  moment('12:30pm', 'h:mma')
  const slotTime = moment(time, 'h:mma');
  return slotTime.isSameOrAfter(targetTime)
}

const formatDateTime = (dateString: any) => {
  return moment(dateString, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY h:mm:ssa');
}

export {isoDate, dayName, convertToAmPm, convertTimeToMinutes,getStartTime,  isNextDayDelivery, formatDateTime};