import { useRecoilState } from "recoil";

import OrderBagObj from "../Utils/OrderBagObj";
import { currentBagAtom, orderBagsAtom } from "../Utils/Atoms";
import { LAUNDRY_PIECES_MIN } from "../Utils/Constants";

function NewOrderProductItem(props: any) {
  // destructure props
  const { product } = props;
  const [currentBag, setCurrentBag] = useRecoilState(currentBagAtom);

  const onChangeProduct = (e: any): void => {
    let updated = Object.assign(new OrderBagObj(), currentBag);
    updated.product_id = e.target.value;
    if (product.variant_type === "quantity") {
      updated.product_qty = LAUNDRY_PIECES_MIN; // minimum quantity
      updated.subproduct_id = null; // invalidate
      updated.subproduct_qty = 0; // invalidate
    } else if (product.variant_type === "variant") {
      updated.product_qty = 0; // only proper quantity
      updated.subproduct_id = null; // set to null to invalidate selection
      updated.subproduct_qty = 0; // set to zero to invalidate selection
    }
    setCurrentBag(updated);
  };

  return (
    <div className="x-item-choice-block">
      <div className="x-item-choice-header">
        <div id="w-node-_127e2d2d-0f2b-d435-ddbd-5b439ba003a4-51e557bb">
          {props.product.name}
        </div>
        <div className="x-small-text">{props.product.description}</div>
      </div>
      <div className="x-input-toggle-block">
        <label className="x-input-toggle w-radio">
          <div className="w-form-formradioinput w-form-formradioinput--inputType-custom x-input-toggle-btn w-radio-input" />
          <input
            type="radio"
            name="BagType"
            id={`product-${product.id}`}
            value={product.id}
            onChange={onChangeProduct}
            checked={currentBag.product_id == product.id}
            name={`bag-${currentBag.id}-type`}
            data-name="BagType"
            style={{
              opacity: 0,
              position: "absolute",
              zIndex: -1,
            }}
          />
          <span className="x-input-toggle-label w-form-label">Get this</span>
        </label>
      </div>
      <img
        // loading="lazy"
        src={product.normal_image}
        alt=""
        className="x-item-choice-thumb"
      />
    </div>
  );
}

export default NewOrderProductItem;
