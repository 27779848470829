import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import Header from "../Components/Header";
import NewOrderStepper from "../Components/NewOrderStepper";
import HomeFooter from "../Components/HomeFooter";
import Footer from "../Components/Footer";
import NewOrderFooter from "../Components/NewOrderFooter";
import AddOnTile from "../Components/AddOnTile";
import AddOnsModals from "../Components/Modals/AddOnsModals";

import { orderBagsAtom, currentOrderAtom, productsAtom } from "../Utils/Atoms";
import OrderBagObj from "../Utils/OrderBagObj";
import OrderObj from "../Utils/OrderObj";
import { isoDate } from "../Utils/Dates";
import MainContainer from "../Components/MainContainer";

function AddOns() {
  const [orderBags, setOrderBags] = useRecoilState(orderBagsAtom);
  const [currentOrder, setCurrentOrder] = useRecoilState(currentOrderAtom);
  const [products, setProducts] = useRecoilState(productsAtom);

  const [showModal, setShowModal] = useState(false);
  const [modalCode, setModalCode] = useState(0);

  const nav = useNavigate();

  const navigateContinue = (e: any): void => {
    e.preventDefault();
    nav("/pay");
  };

  const handleOpenModal = (e: any, modalNumber: number) => {
    e.preventDefault();
    setShowModal(true);
    setModalCode(modalNumber);
  };

  const handleCloseModal = (e: any) => {
    e.preventDefault();
    setShowModal(false);
    setModalCode(0);
  };

  const generateModalContent = () => {
    return <AddOnsModals modalCode={modalCode} />;
  };

  let bagIdx = 0;

  return (
    <MainContainer title={"Addons"}>
      <div className="x-master">
        <div className="x-master-wrapper">
          <Header />
          <header className="x-header for-subpage">
            <h1>Book Laundry Service</h1>
          </header>
          <main className="x-main for-subpage">
            <div className="x-order-header">
              <div className="x-text-flex">
                <a
                  href="/address-select"
                  className="x-icon-btn for-secondary w-button"
                >
                  
                </a>
                <h2>Addons</h2>
                <a
                  data-toggle="dialog-addons-guide"
                  data-tippy-content="How this works?"
                  onClick={(e) => handleOpenModal(e, 1)}
                  className="x-helper-btn"
                >
                  
                </a>
              </div>
              <NewOrderStepper step={4} />
            </div>
            <div className="x-order-wrapper">
              <div className="x-form-wrapper w-form">
                <form
                  id="wf-form-Order-Addons-Form"
                  name="wf-form-Order-Addons-Form"
                  data-name="Order Addons Form"
                  method="get"
                  data-wf-page-id="64fc904b6e1ec367837abe0b"
                  data-wf-element-id="6ca47c44-3a43-0c2f-1bbd-1aa46a68960f"
                >
                  <div className="x-form-body">
                    <ol role="list" className="x-bag-list w-list-unstyled">
                      <li className="x-bag-item">
                        <div className="x-bag-item-header">
                          <h3>Optional Addons</h3>
                        </div>
                        <div className="x-bag-editor">
                          <div data-washtype={2} className="x-bag-editor-row">
                            <div className="x-bag-editor-wrapper">
                              <div className="x-bag-editor-content">
                                <div className="x-item-choice-grid">
                                  {products.map((product: any, index: number) =>
                                    product.category === "addon" ? (
                                      <AddOnTile
                                        key={index}
                                        product={product}
                                      />
                                    ) : null
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="x-bag-item-footer">
                      <div className="x-btn-container">
                        <a href="#" className="x-btn w-button">
                          <span className="x-icon for-btn-inline"></span>Add
                          Items to Cart
                        </a>
                        <a
                          href="#"
                          className="x-icon-btn for-secondary w-button"
                        >
                          
                        </a>
                      </div>
                      <div className="x-bag-item-total">
                        <div className="x-small-text">Total addons</div>
                        <div className="x-brand-emp">RM0.00</div>
                      </div>
                    </div> */}
                      </li>
                    </ol>
                    <NewOrderFooter
                      goToNext={(e: any) => navigateContinue(e)}
                      buttonText={"Confirm & Checkout"}
                    />
                  </div>
                </form>
              </div>
            </div>
          </main>
          <HomeFooter />
        </div>
        <Footer />
      </div>

      {/* MODAL SECTION */}
      <div
        data-toggletarget="dialog-addons-guide"
        className={`x-dialog ${showModal ? "" : "for-hide"}`}
      >
        <div className="x-dialog-wrapper">
          <div
            className={`x-dialog-form w-form ${showModal ? "" : "for-hide"}`}
          >
            <form
              id="wf-form-Guide-Dialog-Form"
              name="wf-form-Guide-Dialog-Form"
              data-name="Guide Dialog Form"
              method="get"
              data-wf-page-id="64fc904b6e1ec367837abe0b"
              data-wf-element-id="cfb25b3e-6032-c0c4-861a-dc1b5db263d9"
            >
              <div className="x-dialog-header">
                <div className="x-dialog-title for-action-icon"></div>
                <div className="x-btn-container">
                  <a
                    onClick={(e) => handleCloseModal(e)}
                    className="x-icon-btn for-simple is-close-modal w-button"
                  >
                    
                  </a>
                </div>
              </div>
              <div className="x-dialog-body">
                <div className="x-form-wrapper">
                  <div className="x-form-row">{generateModalContent()}</div>
                  <div className="x-form-action">
                    <div className="x-btn-container">
                      <a
                        onClick={(e) => handleCloseModal(e)}
                        className="x-btn for-secondary is-close-modal w-button"
                      >
                        Dismiss
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="x-dialog-overlay" />
        </div>
      </div>
    </MainContainer>
  );
}

export default AddOns;
