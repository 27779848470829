import { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";

import MainContainer from "../Components/MainContainer";
import HomeFooter from "../Components/HomeFooter";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import MyOrderEmptyState from "../Components/MyOrderEmptyState";
import MyOrderRow from "../Components/MyOrderRow";

import { ApiInstance, ApiRoutes } from "../Utils/ApiInstance";

function MyOrders() {
  const [orders, setOrders] = useState([]);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [pagination, setPagination] = useState(2);

  const navigate = useNavigate();

  const getOrders = async () => {
    try {
      const response = await ApiInstance.get(ApiRoutes.GET_ORDERS);
      setOrders(response.data.data);
      // Check to show load more button
      // if (response.data.data.length < 5) {
      //   setShowLoadMore(false)
      // }
    } catch (error) {
      throw error;
    }
  };

  const getMoreOrders = async (data: any) => {
    data.preventDefault();
    try {
      let response = await ApiInstance.get(
        `${ApiRoutes.GET_ORDERS}?page=${pagination}`
      );
      let newOrders = response.data.data;

      // No more orders
      if (newOrders.length === 0) {
        setShowLoadMore(false);
        return;
        // console.log('No more orders to load')
      } else {
        let newCombinedOrders = orders.concat(newOrders);
        setOrders(newCombinedOrders);
        setPagination(pagination + 1);
        // console.log('Updated orders and increased pagination')
      }
    } catch (error) {
      throw error;
    }
  };

  const displayOrders = () => {
    return orders.map((order: any, index: any) => (
      <MyOrderRow order={order} key={index} />
    ));
  };

  useEffect(() => {
    getOrders();
  }, []);

  return (
    <MainContainer title={"Orders"}>
      <div className="x-master">
        <div className="x-master-wrapper">
          <Header />
          <header className="x-header for-subpage">
            <h1>Orders</h1>
          </header>
          <main className="x-main">
            <h2>Recent Orders</h2>
            {orders.length === 0 ? (
              <MyOrderEmptyState />
            ) : (
              <div className="x-orderbar">
                <ol role="list" className="x-orderbar-list w-list-unstyled">
                  {displayOrders()}
                </ol>
                {showLoadMore ? (
                  <div className="x-btn-container">
                    <a
                      onClick={getMoreOrders}
                      className="x-sml-btn for-secondary w-button"
                    >
                      Load More<span className="x-icon for-btn-inline"></span>
                    </a>
                  </div>
                ) : null}
              </div>
            )}
          </main>
          <HomeFooter />
        </div>
        <Footer />
      </div>
    </MainContainer>
  );
}

export default MyOrders;
