import { useState } from "react";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";

import MainContainer from "../../Components/MainContainer";
import ErrorModal from "../../Components/Modals/ErrorModal";

import { ApiInstance, ApiRoutes } from "../../Utils/ApiInstance";
import {
  isGuestAtom,
  accessTokenAtom,
  isSignedInAtom,
  profileAtom,
  refreshTokenAtom,
} from "../../Utils/Atoms";

function SignUpTAC() {
  const [isGuest, setIsGuest] = useRecoilState(isGuestAtom);
  const [signedIn, setSignedIn] = useRecoilState(isSignedInAtom);
  const [profile, setProfile] = useRecoilState(profileAtom);
  const [accessToken, setAccessToken] = useRecoilState(accessTokenAtom);
  const [refreshToken, setRefreshToken] = useRecoilState(refreshTokenAtom);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tac, setTac] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const handleOpenModal = (errorMessage: string) => {
    setErrorMessage(errorMessage);
    setShowModal(true);
  };

  const handleCloseModal = (e: any) => {
    e.preventDefault();
    setShowModal(false);
  };

  const generateModalContent = () => {
    return <ErrorModal description={errorMessage} />;
  };

  const getProfile = async () => {
    try {
      const response = await ApiInstance.get(ApiRoutes.GET_PROFILE);
      setProfile(response.data);
    } catch (error) {
      throw error;
    }
  };

  const resendTAC = async (data: any) => {
    data.preventDefault();

    // disable buttons etc.
    setIsSubmitting(true);

    try {
      const response = await ApiInstance.post(ApiRoutes.GENERATE_OTP, {
        phone: profile.phone.number,
        country_code: profile.phone.country_code,
      });
    } catch (error: any) {
      if (error.response?.data?.message != undefined) {
        console.log(error.response.data.message);
        setErrorMessage(error.response.data.message);
        handleOpenModal(error.response.data.message);
      } else {
        setErrorMessage(error.message);
        handleOpenModal(error.message);
      }
    } finally {
      // after all is said and done re-enable submit
      setIsSubmitting(false);
    }
  };

  const handleTAC = async (data: any) => {
    data.preventDefault();

    // disable buttons etc.
    setIsSubmitting(true);

    // Validation
    if (tac.length === 0) {
      setIsSubmitting(false);
      return handleOpenModal("TAC required");
    }

    try {
      const response = await ApiInstance.put(ApiRoutes.SUBMIT_OTP, {
        phone_id: profile.phone.id,
        code: tac,
      });

      // save status
      setSignedIn(true);
      setIsGuest(false);
      // console.log(`Set signedIn: ${signedIn}`);
      // grab persistent profile data
      getProfile();
      // navigate to success page
      navigate("/sign-up-success");
    } catch (error: any) {
      if (error.response?.data?.message != undefined) {
        console.log(error.response.data.message);
        setErrorMessage(error.response.data.message);
        handleOpenModal(error.response.data.message);
      } else {
        setErrorMessage(error.message);
        handleOpenModal(error.message);
      }
    } finally {
      // after all is said and done re-enable submit
      setIsSubmitting(false);
    }
  };

  return (
    <MainContainer title={"Sign Up"}>
      <div className="x-master for-access">
        <div className="x-master-wrapper">
          <div className="x-access-wrapper">
            <img
              src="/images/dq-logo-text-color-hr.svg"
              // loading="lazy"
              alt=""
              className="x-brand-logo"
            />
            <h1>Verify Sign Up</h1>
            <div className="x-form-wrapper w-form">
              <form
                id="wf-form-TAC-Form"
                name="wf-form-TAC-Form"
                data-name="TAC Form"
                method="get"
                data-wf-page-id="64fd2bd05a16efb4be920d8e"
                data-wf-element-id="dbff2635-6c78-989d-0798-b00468339f9d"
              >
                <div className="x-form-wrapper">
                  <div className="x-form-container">
                    <div className="x-form-row">
                      <div className="x-form-col">
                        <label htmlFor="TAC">TAC&nbsp;Code</label>
                        <input
                          type="tel"
                          className="x-input-text w-input"
                          maxLength={6}
                          name="TAC"
                          data-name="TAC"
                          // placeholder={'123456'}
                          id="TAC"
                          required
                          onChange={(e) => setTac(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="x-form-action">
                      <div className="x-btn-container">
                        <a
                          data-wait="Please wait..."
                          className="x-btn w-button"
                          onClick={(e) => handleTAC(e)}
                        >
                          Confirm Code
                        </a>
                        <a
                          onClick={(e) => resendTAC(e)}
                          className="x-btn for-secondary w-button"
                        >
                          Resend
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="x-helper-block">
              <div className="x-icon for-btn-inline is-amber"></div>
              <div>
                <div className="x-emp-text">Where's my Code?</div>
                <div>
                  You'll receive the 6-digit TAC via mobile, that is being sent
                  to{" "}
                  {`${profile?.phone?.country_code ?? ""}${
                    profile?.phone?.number ?? ""
                  }`}{" "}
                  within the next few minutes.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* MODAL SECTION */}
      <div
        data-toggletarget="dialog-addons-guide"
        className={`x-dialog ${showModal ? "" : "for-hide"}`}
      >
        <div className="x-dialog-wrapper">
          <div
            className={`x-dialog-form w-form ${showModal ? "" : "for-hide"}`}
          >
            <form
              id="wf-form-Guide-Dialog-Form"
              name="wf-form-Guide-Dialog-Form"
              data-name="Guide Dialog Form"
              method="get"
              data-wf-page-id="64fc904b6e1ec367837abe0b"
              data-wf-element-id="cfb25b3e-6032-c0c4-861a-dc1b5db263d9"
            >
              <div className="x-dialog-header">
                <div className="x-dialog-title for-action-icon"></div>
                <div className="x-btn-container">
                  <a
                    onClick={(e) => handleCloseModal(e)}
                    className="x-icon-btn for-simple is-close-modal w-button"
                  >
                    
                  </a>
                </div>
              </div>
              <div className="x-dialog-body">
                <div className="x-form-wrapper">
                  <div className="x-form-row">{generateModalContent()}</div>
                  <div className="x-form-action">
                    <div className="x-btn-container">
                      <a
                        onClick={(e) => handleCloseModal(e)}
                        className="x-btn for-secondary is-close-modal w-button"
                      >
                        Dismiss
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="x-dialog-overlay" />
        </div>
      </div>
    </MainContainer>
  );
}

export default SignUpTAC;
