import { useState } from 'react';

import MainContainer from '../Components/MainContainer';
import HomeFooter from '../Components/HomeFooter';
import Footer from '../Components/Footer';
import Header from '../Components/Header';

import { ApiInstance, ApiRoutes } from '../Utils/ApiInstance';
import QRScanner from '../Utils/QRScanPlugin'

function QRScan() {
  const [errorMessage, setErrorMessage] = useState("");

  const onNewScanResult = (decodedText:any, decodedResult:any) => {
    // console.log(decodedText, decodedResult)
    // handle decoded results here
};

  return (
    <MainContainer title={'Scan'}>
  <div className="x-master">
    <div className="x-master-wrapper">
      <Header />
      <header className="x-header for-subpage">
        <h1>QR&nbsp;Scan</h1>
      </header>
      <main className="x-main">
        <div className="x-qrscanner">
        <QRScanner 
          fps={10}
          qrbox={250}
          disableFlip={false}
          qrCodeSuccessCallback={onNewScanResult}/>
          </div>
        {/* <div id="QRreader" className="x-qrscanner" /> */}
      </main>
      <HomeFooter />
    </div>
    <Footer />
  </div>
</MainContainer>

  );
}

export default QRScan;
