import {Navigate, Outlet} from "react-router-dom"
import { useRecoilState } from 'recoil';
import { isSignedInAtom, isGuestAtom } from "../Utils/Atoms";

export default function AuthRequired() {
  const [isSignedIn, setIsSignedIn] = useRecoilState(isSignedInAtom);

  if (!isSignedIn) {
    return <Navigate to="/" />
  }

  return <Outlet />
}