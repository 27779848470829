import { useRecoilState } from 'recoil';

import MainContainer from '../Components/MainContainer';
import HomeFooter from '../Components/HomeFooter';
import Footer from '../Components/Footer';
import Header from '../Components/Header';
import NotificationRow from '../Components/NotificationsRow';

import { ApiInstance, ApiRoutes } from '../Utils/ApiInstance';
import { notificationAtom, unreadNotificationAtom } from '../Utils/Atoms';

function PaymentFailed() {
  const [notifications, setNotifications] = useRecoilState(notificationAtom);
  const [unreadNotifications, setUnreadNotifications] = useRecoilState(unreadNotificationAtom);

  return (
    <MainContainer title={'Payment Failed'}>
<div className="x-master">
  <div className="x-master-wrapper">
  <Header />
    <header className="x-header for-subpage">
      <h1>Oops! Payment Failed</h1>
    </header>
    <main className="x-main for-subpage">
      <div className="x-order-header">
        <div className="x-text-flex">
          <h2>Order could not be processed.</h2>
        </div>
      </div>
      <div className="x-order-wrapper">
        <div className="x-form-wrapper w-form">
          <form
            id="wf-form-Order-Confirmation-Form"
            name="wf-form-Order-Confirmation-Form"
            data-name="Order Confirmation Form"
            method="get"
            data-wf-page-id="660d7970f02a73989190d920"
            data-wf-element-id="6ca47c44-3a43-0c2f-1bbd-1aa46a68960f"
          >
            <div className="x-order-notice">
              <img
                src="images/62.svg"
                loading="lazy"
                alt=""
                className="x-message-thumb"
              />
              <p>
                There were issues with this order,{" "}
                <span className="x-emp-text">NO charges / payments</span> were
                made. You can remake the order again. If issues persists,
                contact us{" "}
                <a href="https://www.dobiqueen.my/contact" target="_blank">
                  here
                </a>
                .
              </p>
            </div>
            <div className="x-bag-footer">
              <div className="x-btn-container">
                <a href="/" className="x-btn w-button">
                  Back to Home
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </main>
    <HomeFooter />
  </div>
  <Footer />
</div>
</MainContainer>

  );
}

export default PaymentFailed;
