import { useRecoilState } from "recoil";

import NewOrderProductItem from "./NewOrderProductItem";
import NewOrderBagVariant from "./NewOrderBagVariant";

import OrderBagObj from "../Utils/OrderBagObj";
import {
  currentBagAtom,
  orderBagsAtom,
  productsAtom,
  showBagFormAtom,
} from "../Utils/Atoms";
import { getProduct, getSubproduct } from "../Utils/Products";
import { filterAddOnOrderBags, filterLaundryOrderBags } from "../Utils/Helpers";

function NewOrderBag(props: any) {
  const [orderBags, setOrderBags] = useRecoilState(orderBagsAtom);
  const [currentBag, setCurrentBag] = useRecoilState(currentBagAtom);
  const [showBagForm, setShowBagForm] = useRecoilState(showBagFormAtom);
  const [products, setProducts] = useRecoilState(productsAtom);

  const deleteCurrentBag = (e: any) => {
    e.preventDefault();
    // reset currentBag to new bag object
    setCurrentBag(new OrderBagObj());
    setShowBagForm(false);
    props.checkNoBag();
  };

  const handleAddBag = (e: any): void => {
    e.preventDefault();
    // assign an ID if none present, and add bag to orderBags
    if (currentBag.id === 0) {
      let updatedBag = Object.assign(new OrderBagObj(), currentBag);

      const filteredLaundry = filterLaundryOrderBags(orderBags);
      const filterAddOn = filterAddOnOrderBags(orderBags);
      filteredLaundry.push(updatedBag);

      const bags = [...filteredLaundry, ...filterAddOn].map((item, index) => ({
        ...item,
        id: index + 1,
      }));
      setOrderBags(bags);
    } else {
      // if ID is present, find the existing bag and replace edited bag
      let updatedBags = JSON.parse(JSON.stringify(orderBags)); // deep copy

      updatedBags.length &&
        updatedBags.find((item: OrderBagObj, index: number) => {
          if (item.id === currentBag.id) {
            updatedBags[index] = currentBag;
            return true;
          }
        });

      setOrderBags(updatedBags);
    }

    // reset currentBag to new bag object
    setCurrentBag(new OrderBagObj());
    // hide the form
    setShowBagForm(false);
  };

  const displayLaundryProducts = () => {
    return (
      products.length &&
      [...products]
        .filter((prod) => prod.category === "laundry")
        .sort((a, b) => a.sorting - b.sorting)
        .map((prod: any, index: any) => (
          <NewOrderProductItem key={index} product={prod} />
        ))
    );
  };

  const checkBagComplete = (): boolean => {
    if (
      currentBag.product_id &&
      currentBag.product_qty &&
      currentBag.subproduct_id &&
      currentBag.subproduct_qty
    ) {
      return true;
    } else if (
      currentBag.product_id &&
      currentBag.subproduct_id === null &&
      currentBag.product_qty
    ) {
      return true;
    }
    return false;
  };

  // const getBagTotal = () => {
  //   let bagTotal = 0;
  //   let product = getProduct(currentBag.product_id, products);

  //   if(!product) return bagTotal.toFixed(2)

  //   if (product && product.variant_type && product.variant_type == "quantity") {
  //     bagTotal += Number(currentBag.product_qty) * Number(product.unit_price);
  //   } else if (product && product.variant_type && product.variant_type == "variant") {
  //     let subproduct = getSubproduct(currentBag.subproduct_id, product.subproducts);
  //     if (subproduct) {
  //       bagTotal += Number(subproduct.unit_price);
  //     }
  //   }
  //   return bagTotal.toFixed(2)
  // }

  return (
    <li className="x-bag-item">
      <div className="x-bag-item-header">
        {/* Show 'Editing' prefix for title if editing bag */}
        <h3>
          {currentBag.id !== 0
            ? `Editing Bag #${currentBag.id}`
            : `Bag #${filterLaundryOrderBags(orderBags).length + 1}`}
        </h3>
        <div className="x-hr-sep" />
        <div className="x-btn-container">
          {/* <a onClick={duplicate} className="x-sml-btn for-simple w-button">
            <span className="x-icon for-btn-inline"></span>
            Duplicate
          </a> */}
          <a
            onClick={deleteCurrentBag}
            className="x-sml-btn for-simple w-button"
          >
            <span className="x-icon for-btn-inline"></span>Delete
          </a>
        </div>
      </div>
      <div className="x-bag-editor">
        {/* SELECT LAUNDRY SERVICE */}
        <div className="x-bag-editor-row">
          <div className="x-bag-editor-stepper">
            <div className="x-bag-editor-stepper-label">A</div>
            <div className="x-bag-editor-stepper-icon"></div>
          </div>
          <div className="x-bag-editor-wrapper">
            <div className="x-bag-editor-header">
              <div className="x-text-flex">
                <div className="x-emp-text">Select laundry service.</div>
                <a
                  data-toggle="dialog-laundry-service-guide"
                  data-tippy-content="How this works?"
                  onClick={(e) => props.handleOpenModal(e, 2)}
                  className="x-helper-btn"
                >
                  
                </a>
              </div>
            </div>
            <div className="x-bag-editor-content">
              <div className="x-item-choice-grid">
                {displayLaundryProducts()}
              </div>
            </div>
          </div>
        </div>
        {/* SELECT SUB PRODUCT */}
        {currentBag.product_id !== "" && (
          <NewOrderBagVariant
            products={products}
            handleOpenModal={(e: any, index: number) =>
              props.handleOpenModal(e, index)
            }
          />
        )}
      </div>
      <div className="x-bag-item-footer">
        <div className="x-btn-container">
          <button
            onClick={handleAddBag}
            disabled={!checkBagComplete()}
            className="x-btn w-button"
          >
            <span className="x-icon for-btn-inline"></span>Add this Bag
          </button>
        </div>
        {/* <div className="x-bag-item-total">
          <div className="x-small-text">Total for this bag</div>
          <div className="x-brand-emp">{`RM${getBagTotal()}`}</div>
        </div> */}
      </div>
    </li>
  );
}

export default NewOrderBag;
