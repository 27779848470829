import { useRecoilState } from "recoil";

import MainContainer from "../Components/MainContainer";
import HomeFooter from "../Components/HomeFooter";
import Footer from "../Components/Footer";
import Header from "../Components/Header";

import {
  orderBagsAtom,
  currentOrderAtom,
  currentBagAtom,
  showBagFormAtom,
} from "../Utils/Atoms";
import OrderObj from "../Utils/OrderObj";
import OrderBagObj from "../Utils/OrderBagObj";

function Cart() {
  const [currentOrder, setCurrentOrder] = useRecoilState(currentOrderAtom);
  const [orderBags, setOrderBags] = useRecoilState(orderBagsAtom);
  const [currentBag, setCurrentBag] = useRecoilState(currentBagAtom);
  const [showBagForm, setShowBagForm] = useRecoilState(showBagFormAtom);

  const clearCart = () => {
    setCurrentOrder(new OrderObj());
    setOrderBags([]);
    setCurrentBag(new OrderBagObj());
    setShowBagForm(true);
  };

  const getProductChip = (bag: any) => {
    let productType = "laundry";
    let productAbrv = "";
    let sideTile = "";

    // WD
    if (bag.product_id == 1) {
      productAbrv = "WD";
      // WDF
    } else if (bag.product_id == 2) {
      productAbrv = "WDF";
      // WDSH
    } else if (bag.product_id == 5) {
      productAbrv = "WDSH";
      // everything else Ao
    } else {
      productType = "ao";
      productAbrv = "Ao";
      sideTile = bag.product_qty;
    }

    // S
    if (bag.subproduct_id == 1) {
      sideTile = "S";
      // M
    } else if (bag.subproduct_id == 2) {
      sideTile = "M";
      // L
    } else if (bag.subproduct_id == 3) {
      sideTile = "L";
    }

    return (
      <div
        className={`x-cartbar-info-opt-chip ${
          productType === "ao" ? "for-addons" : ""
        }`}
      >
        <div>{productAbrv}</div>
        {sideTile.length !== 0 ? (
          <div className="x-cartbar-info-opt-size">{sideTile}</div>
        ) : null}
      </div>
    );
  };

  const showPendingOrderBags = () => {
    return (
      <li className="x-cartbar-item">
        <div className="x-cartbar-info">
          <div className="x-cartbar-info-header">
            <div className="x-emp-text">{`${orderBags.length} Bag${
              orderBags.length > 1 ? "s" : ""
            } Order`}</div>
            {/* <div
            data-tippy-content="Time left until order expires. Expired orders cannot be checkedout."
            className="x-cartbar-info-exp"
          >
            EXP IN 2HRS
          </div> */}
          </div>
          <div className="x-cartbar-info-opt">
            {orderBags.map((bag: any) => getProductChip(bag))}
          </div>
        </div>
        <div className="x-btn-container">
          <a href="/new-order" className="x-btn w-button">
            Checkout<span className="x-icon for-btn-inline"></span>
          </a>
          <a
            data-tippy-content="Delete this Order"
            onClick={clearCart}
            className="x-icon-btn for-simple w-button"
          >
            
          </a>
        </div>
      </li>
    );
  };

  // Empty cart
  if (orderBags.length === 0) {
    return (
      <MainContainer title={"Cart"}>
        <div className="x-master">
          <div className="x-master-wrapper">
            <Header />
            <header className="x-header for-subpage">
              <h1>Your Cart</h1>
            </header>
            <main className="x-main">
              <h2>Pending Checkout</h2>
              <div className="x-legendbar">
                <div className="x-legendbar-group">
                  <div className="x-cartbar-info-opt-chip">
                    <div>WD</div>
                  </div>
                  <div>Wash &amp;&nbsp;Dry</div>
                </div>
                <div className="x-legendbar-group">
                  <div className="x-cartbar-info-opt-chip">
                    <div>WDF</div>
                  </div>
                  <div>Wash,&nbsp;Dry &amp;&nbsp;Fold</div>
                </div>
                <div className="x-legendbar-group">
                  <div className="x-cartbar-info-opt-chip">
                    <div>WDSH</div>
                  </div>
                  <div>Wash, Dry, Steam &amp;&nbsp;Hang</div>
                </div>
                <div className="x-legendbar-group">
                  <div className="x-cartbar-info-opt-chip for-addons">
                    <div>Ao</div>
                  </div>
                  <div>Add-ons</div>
                </div>
              </div>
              <div className="x-empty-placholder">
                <img
                  src="images/62.svg"
                  // loading="lazy"
                  alt=""
                  className="x-empty-placeholder-img"
                />
                <div>No Orders in your cart yet.</div>
              </div>
            </main>
            <HomeFooter />
          </div>
          <Footer />
        </div>
      </MainContainer>
    );
  }

  return (
    <MainContainer title={"Cart"}>
      <div className="x-master">
        <div className="x-master-wrapper">
          <Header />
          <header className="x-header for-subpage">
            <h1>Your Cart</h1>
          </header>
          <main className="x-main">
            <h2>Pending Checkout</h2>
            <div className="x-legendbar">
              <div className="x-legendbar-group">
                <div className="x-cartbar-info-opt-chip">
                  <div>WD</div>
                </div>
                <div>Wash &amp;&nbsp;Dry</div>
              </div>
              <div className="x-legendbar-group">
                <div className="x-cartbar-info-opt-chip">
                  <div>WDF</div>
                </div>
                <div>Wash,&nbsp;Dry &amp;&nbsp;Fold</div>
              </div>
              <div className="x-legendbar-group">
                <div className="x-cartbar-info-opt-chip">
                  <div>WDSH</div>
                </div>
                <div>Wash, Dry, Steam &amp;&nbsp;Hang</div>
              </div>
              <div className="x-legendbar-group">
                <div className="x-cartbar-info-opt-chip for-addons">
                  <div>Ao</div>
                </div>
                <div>Add-ons</div>
              </div>
            </div>
            <div className="x-cartbar">
              <ol role="list" className="x-cartbar-list w-list-unstyled">
                {showPendingOrderBags()}
              </ol>
            </div>
          </main>
          <HomeFooter />
        </div>
        <Footer />
      </div>
    </MainContainer>
  );
}

export default Cart;
