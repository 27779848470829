const AddOnModals = (props:any) => {
  if (props.modalCode === 1) {
    return (
      <div className="x-form-col">
      <img
        alt=""
        src="images/heart-add-line.svg"
        className="x-message-thumb for-dialog"
      />
      <div className="x-dialog-title for-action">
        Optional Addons
      </div>
      <p>
        Get optional addons for your laundry service. Our hangers
        and bags are high-quality stuff!
      </p>
      </div>
    )
  }
  return null
}

export default AddOnModals