const Footer = (props) => (
  <div className="x-payment-opt">
    <div>Payment secured &amp; powered by</div>
    <img
      src="images/rm-color-logo.svg"
      loading="lazy"
      alt=""
      className="x-payment-rm"
    />
    <div className="x-vr-sep for-flex" />
    <div>Pay with</div>
    <img
      src="images/visa.jpeg"
      loading="lazy"
      alt=""
      className="x-payment-type-logo"
    />
    <img
      src="images/master.jpeg"
      loading="lazy"
      alt=""
      className="x-payment-type-logo"
    />
    <img
      src="images/fpx.jpeg"
      loading="lazy"
      alt=""
      className="x-payment-type-logo"
    />
    <div>and more...</div>
  </div>
)

export default Footer