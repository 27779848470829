import { useState } from 'react'
import { useRecoilState } from "recoil";

import NavMobileMenu from './NavMobileMenu'
import NavMenuList from './NavMenuList'
import DQLogo from './DQLogo'

import { profileAtom, unreadNotificationAtom } from "../Utils/Atoms";

function Header(props:any) {
  const [profile, setProfile] = useRecoilState(profileAtom);
  const [unreadNotifications, setUnreadNotifications] = useRecoilState(unreadNotificationAtom);
  const [mobileMenuHide, setMobileMenuHide] = useState(true)

  return (
    <nav className="x-nav">
      <div className="x-nav-wrapper">
        <div className="x-nav-brand-group">
          <DQLogo />
        </div>
        <div className="x-nav-group">
        <div className="x-nav-menu-wrapper">
          <div className={`x-nav-menu ${mobileMenuHide ? 'for-hide' : ''}`}>
            <div className="x-nav-mobile-brand">
              <img
                src="/images/dq-logo-text-color-hr.svg"
                // loading="lazy"
                alt=""
                className="x-brand-logo"
              />
            </div>
            <a onClick={() => setMobileMenuHide(true)} className="x-nav-menu-close-btn">
              
            </a>
            <div className="x-nav-menu-title">Menu</div>
            <NavMenuList signedOut={props.signedOut} />
            <div className="x-hr-sep for-mobile-menu" />
            <NavMobileMenu />
          </div>
          <a
            data-toggle="MobileMenu"
            onClick={() => setMobileMenuHide(false)}
            className="x-nav-icon-link for-mobile-menu w-inline-block"
          >
            <div className="x-icon"></div>
          </a>
        </div>
        {
          props.signedOut ? null
          :         <a
          data-tippy-content="Your notifications"
          href="/notifications"
          className="x-nav-icon-link for-active w-inline-block"
        >
          <div className="x-icon"></div>
          {
            unreadNotifications && unreadNotifications !== 0 ?
            <div className="x-nav-icon-badge">{unreadNotifications}</div>
            : null
          }
        </a>
        }
        <a
          data-tippy-content="Your cart"
          href="/cart"
          className="x-nav-icon-link w-inline-block"
        >
          <div className="x-icon"></div>
        </a>
        {
          props.signedOut ? null
          :         <a href="/settings" className="x-nav-acc-link w-inline-block">
          <img
            src={profile.avatar || "/images/avatar.svg"}
            loading="eager"
            alt=""
            className="x-avatar-img"
          />
          <div className="x-nav-acc-name">{profile.preferred_name || profile.name}</div>
        </a>
        }
      </div>
    </div>
  </nav>
  )
}

export default Header