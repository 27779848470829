import { useState } from "react";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";

import MainContainer from "../../Components/MainContainer";
import ErrorModal from "../../Components/Modals/ErrorModal";

import {
  accessTokenAtom,
  currentBagAtom,
  currentOrderAtom,
  isGuestAtom,
  isSignedInAtom,
  orderBagsAtom,
  productsAtom,
  profileAtom,
  refreshTokenAtom,
  showBagFormAtom,
} from "../../Utils/Atoms";
import OrderObj from "../../Utils/OrderObj";
import OrderBagObj from "../../Utils/OrderBagObj";

import { ApiInstance, ApiRoutes } from "../../Utils/ApiInstance";
import { API_CLIENT_ID, API_SECRET } from "../../Utils/Constants";
import { postDeviceUUID } from "../../Utils/Helpers";

function SignIn() {
  const [signedIn, setSignedIn] = useRecoilState(isSignedInAtom);
  const [isGuest, setIsGuest] = useRecoilState(isGuestAtom);
  const [refreshToken, setRefreshToken] = useRecoilState(refreshTokenAtom);
  const [profile, setProfile] = useRecoilState(profileAtom);
  const [accessToken, setAccessToken] = useRecoilState(accessTokenAtom);
  const [currentOrder, setCurrentOrder] = useRecoilState(currentOrderAtom);
  const [orderBags, setOrderBags] = useRecoilState(orderBagsAtom);
  const [currentBag, setCurrentBag] = useRecoilState(currentBagAtom);
  const [showBagForm, setShowBagForm] = useRecoilState(showBagFormAtom);
  const [products, setProducts] = useRecoilState(productsAtom);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = (errorMessage: string) => {
    setErrorMessage(errorMessage);
    setShowModal(true);
  };

  const handleCloseModal = (e: any) => {
    e.preventDefault();
    setShowModal(false);
  };

  const generateModalContent = () => {
    return <ErrorModal description={errorMessage} />;
  };

  const navigate = useNavigate();

  const showHidePassword = (data: any) => {
    data.preventDefault();
    showPassword ? setShowPassword(false) : setShowPassword(true);
    return null;
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      handleLogin(event);
    }
  };

  const handleLogin = async (data: any) => {
    data.preventDefault();

    // disable buttons etc.
    setIsSubmitting(true);
    if (isSubmitting) return;

    // Validation
    if (username.length === 0) {
      setIsSubmitting(false);
      return handleOpenModal("Username required");
    }
    if (password.length === 0) {
      setIsSubmitting(false);
      return handleOpenModal("Password required");
    }

    try {
      const response = await ApiInstance.post(ApiRoutes.GENERATE_TOKEN, {
        client_id: API_CLIENT_ID,
        client_secret: API_SECRET,
        grant_type: "password",
        user_type: "customer",
        scope: "*",
        username: username,
        password: password,
      });

      // save tokens for later
      const { access_token, refresh_token } = response.data;
      setAccessToken(access_token);
      setRefreshToken(refresh_token);
      setCurrentOrder(new OrderObj());
      setOrderBags([]);
      setShowBagForm(true);

      const res = await postDeviceUUID();
      if (res.status === 200) {
        setSignedIn(true);
        setIsGuest(false);
        getProfile();
        setCurrentBag(new OrderBagObj());
        navigate("/");
      } else {
        await ApiInstance.post(ApiRoutes.REVOKE_TOKEN, {});
        setSignedIn(false);
        setIsGuest(true);
        setProfile([]);
        setProducts([]);
        setAccessToken("");
        setRefreshToken("");
      }
    } catch (error: any) {
      if (error.response?.data?.message != undefined) {
        setErrorMessage(error.response.data.message);
        handleOpenModal(error.response.data.message);
      } else {
        setErrorMessage(error.message);
        handleOpenModal(error.response);
      }
    } finally {
      // after all is said and done re-enable submit
      setIsSubmitting(false);
    }
  };

  const getProfile = async () => {
    try {
      const response = await ApiInstance.get(ApiRoutes.GET_PROFILE);
      setProfile(response.data);
    } catch (error) {
      throw error;
    }
  };

  return (
    <MainContainer title={"Sign In"}>
      <div className="x-master for-access">
        <div className="x-master-wrapper">
          <div className="x-access-wrapper">
            <img
              src="/images/dq-logo-text-color-hr.svg"
              // loading="lazy"
              alt=""
              className="x-brand-logo"
            />
            <h1>Login</h1>
            <div className="x-form-wrapper w-form">
              <form
                id="wf-form-Login-Form"
                name="wf-form-Login-Form"
                data-wf-page-id="64fc9ce7523038971c35bacf"
                data-wf-element-id="dbff2635-6c78-989d-0798-b00468339f9d"
              >
                <div className="x-form-wrapper">
                  <div className="x-form-container">
                    <div className="x-form-row">
                      <div className="x-form-col">
                        <label htmlFor="Email">Email</label>
                        <input
                          type="email"
                          className="x-input-text w-input"
                          maxLength={256}
                          name="Email"
                          data-name="Email"
                          // placeholder="Email"
                          id="Email"
                          onChange={(e) => setUsername(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="x-form-row">
                      <div className="x-form-col">
                        <label htmlFor="Password">Password</label>
                        <input
                          type={showPassword ? "text" : "password"}
                          className="x-input-text w-input"
                          maxLength={256}
                          name="Password"
                          data-name="Password"
                          // placeholder="Password"
                          id="Password"
                          onChange={(e) => setPassword(e.target.value)}
                          onKeyDown={handleKeyDown}
                        />
                        <a
                          id="PassUnmask"
                          onClick={(e) => showHidePassword(e)}
                          className="x-input-opt-btn"
                        >
                          Check
                        </a>
                      </div>
                    </div>
                    <div className="x-form-row">
                      <div className="x-form-opt">
                        {/* <label className="w-checkbox x-input-chkbox">
                    <div className="w-checkbox-input w-checkbox-input--inputType-custom x-input-chkbox-btn" />
                    <input
                      type="checkbox"
                      id="Remember"
                      name="Remember"
                      data-name="Remember"
                      style={{ opacity: 0, position: "absolute", zIndex: -1 }}
                    />
                    <span className="x-input-chkbox-label w-form-label">
                      Remember me
                    </span>
                  </label> */}
                        <a
                          href="https://app.dobiqueen.my/forgot-password"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Forgot password
                        </a>
                      </div>
                    </div>
                    <div className="x-form-action">
                      <div className="x-btn-container">
                        <a
                          onClick={(e) => handleLogin(e)}
                          className="x-btn w-button"
                        >
                          Login
                        </a>
                        <a
                          href="/sign-up"
                          className="x-btn for-secondary w-button"
                        >
                          Get an account
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {/* <div className="x-or-sep">
        <div className="x-hr-sep" />
        <div>OR</div>
        <div className="x-hr-sep" />
      </div>
      <div className="x-btn-container for-center">
        <a href="#" className="x-icon-btn for-simple is-fb w-button">
          
        </a>
        <a href="#" className="x-icon-btn for-simple is-gg w-button">
          
        </a>
        <a href="#" className="x-icon-btn for-simple is-ap w-button">
          
        </a>
      </div> */}
          </div>
        </div>
      </div>
      {/* MODAL SECTION */}
      <div
        data-toggletarget="dialog-addons-guide"
        className={`x-dialog ${showModal ? "" : "for-hide"}`}
      >
        <div className="x-dialog-wrapper">
          <div
            className={`x-dialog-form w-form ${showModal ? "" : "for-hide"}`}
          >
            <form
              id="wf-form-Guide-Dialog-Form"
              name="wf-form-Guide-Dialog-Form"
              data-name="Guide Dialog Form"
              method="get"
              data-wf-page-id="64fc904b6e1ec367837abe0b"
              data-wf-element-id="cfb25b3e-6032-c0c4-861a-dc1b5db263d9"
            >
              <div className="x-dialog-header">
                <div className="x-dialog-title for-action-icon"></div>
                <div className="x-btn-container">
                  <a
                    onClick={(e) => handleCloseModal(e)}
                    className="x-icon-btn for-simple is-close-modal w-button"
                  >
                    
                  </a>
                </div>
              </div>
              <div className="x-dialog-body">
                <div className="x-form-wrapper">
                  <div className="x-form-row">{generateModalContent()}</div>
                  <div className="x-form-action">
                    <div className="x-btn-container">
                      <a
                        onClick={(e) => handleCloseModal(e)}
                        className="x-btn for-secondary is-close-modal w-button"
                      >
                        Dismiss
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="x-dialog-overlay" />
        </div>
      </div>
    </MainContainer>
  );
}

export default SignIn;
