import MainContainer from '../../Components/MainContainer';

function SignUpSuccess() {
  return (
    <MainContainer title={'Sign Up'}>
<div className="x-master for-access">
  <div className="x-master-wrapper">
  <div className="x-access-wrapper">
  <img
    src="/images/dq-logo-text-color-hr.svg"
    // loading="lazy"
    alt=""
    className="x-brand-logo"
  />
  <img
    src="/images/icons8-success-240.png"
    // loading="lazy"
    alt=""
    className="x-message-thumb"
  />
      <h1>Account Ready!</h1>
      <p>
        Hooray! Your dobiQueen account is now ready. To ensure your account is
        fully activated, just top up at least RM10 to your account.
      </p>
      <div className="x-btn-container">
        <a href="/" className="x-btn w-button">
          Go to Account<span className="x-icon for-btn-inline"></span>
        </a>
      </div>
    </div>
  </div>
</div>
</MainContainer>

  );
}

export default SignUpSuccess;
