const OutletRow = (props) => {
  let gmapUrl = "https://www.google.com/maps/search/"+props.outlet.lat+",+"+props.outlet.lng+"?entry=tts"
  return (
    <li className="x-outlet-item">
    <div className="x-text-flex">
      <div className="x-icon for-noti"></div>
      <div className="x-emp-text">
        {props.outlet.name}
      </div>
    </div>
    <div className="x-btn-container">
      <a href={gmapUrl} target='_blank' className="x-sml-btn for-secondary w-button">
        Go Now<span className="x-icon for-btn-inline"></span>
      </a>
    </div>
    </li>
  )
}

export default OutletRow