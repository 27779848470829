import { ApiInstance, ApiRoutes } from "./ApiInstance";
import OrderObj from "./OrderObj";

export const filterLaundryOrderBags = (list) => {
  const filteredBag = list.filter((item) => item.category === "laundry");
  return filteredBag;
};

export const filterAddOnOrderBags = (list) => {
  const filteredBag = list.filter((item) => item.category === "addon");
  return filteredBag;
};

export const reorderBags = (bags) => {
  let updatedBags = JSON.parse(JSON.stringify(bags)); // deep copy

  updatedBags.map((item, index) => {
    item.id = index + 1;
    return item;
  });
  return updatedBags;
};

export const clearCurrentOrder = (currentOrder, clearAddress) => {
  let newOrderObj;

  if (clearAddress) {
    newOrderObj = new OrderObj();
  } else {
    const {
      pickup_building_type,
      pickup_address_1,
      pickup_address_2,
      pickup_city,
      pickup_postcode,
      pickup_state,
      delivery_building_type,
      delivery_address_1,
      delivery_address_2,
      delivery_city,
      delivery_postcode,
      delivery_state,
      pickup_delivery_same,
      is_default_pickup_address,
      is_default_delivery_address,
      pickup_opHour,
      delivery_opHour,
    } = currentOrder; // Destructuring to extract the address property

    newOrderObj = Object.assign(new OrderObj(), {
      pickup_building_type,
      pickup_address_1,
      pickup_address_2,
      pickup_city,
      pickup_postcode,
      pickup_state,
      delivery_building_type,
      delivery_address_1,
      delivery_address_2,
      delivery_city,
      delivery_postcode,
      delivery_state,
      pickup_delivery_same,
      is_default_pickup_address,
      is_default_delivery_address,
      pickup_opHour,
      delivery_opHour,
    }); // Creating a new object with only the address property
  }
  return newOrderObj;
};

export const getShippingMethod = async () => {
  try {
    const response = await ApiInstance.get(ApiRoutes.GET_SHIPPING_METHODS);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const postDeviceUUID = async () => {
  try {
    const response = await ApiInstance.post(ApiRoutes.POST_DEVICE_UUID, {
      device_brand: "",
      device_uuid: "web app",
      device_version: "",
      platform: "web app",
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const isExpressShipping = (id, shippingMethods) => {
  const item = shippingMethods.find((item) => item.id === id);
  return item.type == "express";
};

export const isDoorToDoor = (id, shippingMethods) => {
  const item = shippingMethods.find((item) => item.id === id);
  return item.is_door_to_door;
};

export const getShippingAmount = (id, shippingMethods) => {
  const item = shippingMethods.find((item) => item.id === id);
  return item.amount;
};

export const isHasValue = (val) => {
  if (val === null || val === "") {
    return false;
  }
  return true;
};

export const isHasPhoneValue = (phone) => {
  if (!isHasValue(phone)) {
    return false;
  }

  if (typeof phone === "object") {
    return isHasValue(phone?.country_code) && isHasValue(phone?.number);
  }

  return false;
};
