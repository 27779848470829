import moment from 'moment'

const HomeFooter = (props) => (
  <footer className="x-footer">
  <div className="x-footer-block">
    <img
      src="images/dq-logo-text-color-hr.svg"
      loading="eager"
      alt=""
      className="x-brand-logo"
    />
  </div>
  <div className="x-footer-block">
    <div className="x-footer-menu">
      <a href="https://www.dobiqueen.my/" target="_blank">dobiQueen Website</a>
      <a href="https://www.dobiqueen.my/app" target="_blank">Download App</a>
      <a href="https://www.dobiqueen.my/tnc/privacy-policy" target="_blank">Privacy</a>
      <a href="https://www.dobiqueen.my/tnc" target="_blank">Terms</a>
      <a href="https://www.dobiqueen.my/contact" target="_blank">Help</a>
    </div>
    <div className="x-footer-copy">
      © All rights reserved {moment().format('YYYY')}, dobiQueen
      <br />
    </div>
  </div>
</footer>
)

export default HomeFooter