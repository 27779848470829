import { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";

import MainContainer from "../../../Components/MainContainer";
import HomeFooter from "../../../Components/HomeFooter";
import Footer from "../../../Components/Footer";
import Header from "../../../Components/Header";
import ErrorModal from "../../../Components/Modals/ErrorModal";
// import CityList from '../../../Components/CityList';

import { ApiInstance, ApiRoutes } from "../../../Utils/ApiInstance";
import { profileAtom } from "../../../Utils/Atoms";
import { TextField } from "../../../Components/Forms/TextField";
import { RadioButtonOption } from "../../../Components/Forms/RadioButtonOption";

function Addresses() {
  const [profile, setProfile] = useRecoilState(profileAtom);

  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [selectedCity, setSelectedCity] = useState({});
  // const [saveAsDefaultPickupAddress, setSaveAsDefaultPickupAddress] = useState(false);
  // const [saveAsDefaultDeliveryAddress, setSaveAsDefaultDeliveryAddress] = useState(false);
  // const [pickUpDeliverSame, setPickUpDeliverSame] = useState(false);
  // const [showDeliveryLocation, setShowDeliveryLocation] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // setSelectedCity({
    //   value: profile?.city, label:  profile?.city
    // })
  }, []);

  const handleOpenModal = (errorMessage: string) => {
    setErrorMessage(errorMessage);
    setShowModal(true);
  };

  const handleCloseModal = (e: any) => {
    e.preventDefault();
    setShowModal(false);
  };

  const generateModalContent = () => {
    return <ErrorModal description={errorMessage} />;
  };

  const handleUpdate = async (e: any) => {
    e.preventDefault();

    // disable buttons etc.
    setIsSubmitting(true);

    let newProfile = {
      ...profile,
    };

    if (newProfile.pickup_delivery_same) {
      // Setting same delivery address as pickup
      newProfile.address_2_building_type = profile.address_building_type;
      newProfile.address_2_line_1 = profile.address;
      newProfile.address_2_line_2 = profile.address_line_2;
      newProfile.city_2 = profile.city;
      newProfile.postcode_2 = profile.postcode;
      newProfile.state_2 = profile.state;
      newProfile.opHour_2 = profile.opHour;
      newProfile.is_default_delivery_address =
        profile.is_default_pickup_address;
      // setSaveAsDefaultDeliveryAddress(profile.is_default_pickup_address)
    }
    setProfile(newProfile);

    // Validation
    if (
      newProfile.address_building_type == null ||
      newProfile.address_building_type.length === 0
    ) {
      handleOpenModal("Pickup building type required");
      return;
    }
    if (newProfile.address == null || newProfile.address.length === 0) {
      handleOpenModal("Pickup address line 1 required");
      return;
    }
    if (
      newProfile.address_line_2 == null ||
      newProfile.address_line_2.length === 0
    ) {
      handleOpenModal("Pickup address line 2 required");
      return;
    }
    if (newProfile.city == null || newProfile.city.length === 0) {
      handleOpenModal("Pickup city required");
      return;
    }
    if (newProfile.postcode == null || newProfile.postcode.length === 0) {
      handleOpenModal("Pickup postcode required");
      return;
    }
    if (newProfile.state == null || newProfile.state.length === 0) {
      handleOpenModal("Pickup state required");
      return;
    }
    if (
      (newProfile.address_building_type == "hotel" ||
        newProfile.address_building_type == "office") &&
      (newProfile.opHour == null || newProfile.opHour.length === 0)
    ) {
      handleOpenModal("Operational hours of office/hotel required");
      return;
    }

    // Validation delivery
    if (
      newProfile.address_2_building_type == null ||
      newProfile.address_2_building_type.length === 0
    ) {
      handleOpenModal("Delivery building type required");
      return;
    }
    if (
      newProfile.address_2_line_1 == null ||
      newProfile.address_2_line_1.length === 0
    ) {
      handleOpenModal("Delivery address line 1 required");
      return;
    }
    if (
      newProfile.address_2_line_2 == null ||
      newProfile.address_2_line_2.length === 0
    ) {
      handleOpenModal("Delivery address line 2 required");
      return;
    }
    if (newProfile.city_2 == null || newProfile.city_2.length === 0) {
      handleOpenModal("Delivery city required");
      return;
    }
    if (newProfile.postcode_2 == null || newProfile.postcode_2.length === 0) {
      handleOpenModal("Delivery postcode required");
      return;
    }
    if (newProfile.state_2 == null || newProfile.state_2.length === 0) {
      handleOpenModal("Delivery state required");
      return;
    }
    if (
      (newProfile.address_2_building_type == "hotel" ||
        newProfile.address_2_building_type == "office") &&
      (newProfile.opHour_2 == null || newProfile.opHour_2.length === 0)
    ) {
      handleOpenModal("Operational hours of office/hotel required");
      return;
    }

    const updatedProfile = {
      // address_building_type: profile.address_building_type,
      // address: profile.address,
      // address_line_2: profile.address_line_2,
      // city: profile.city,
      // postcode: profile.postcode,
      // state: profile.state,
      // address_2_building_type: profile.address_2_building_type,
      // address_2_line_1: profile.address_2_line_1,
      // address_2_line_2: profile.address_2_line_2,
      // city_2: profile.city_2,
      // postcode_2: profile.postcode_2,
      // state_2: profile.state_2,

      pickup_building_type: newProfile.address_building_type,
      pickup_address_1: newProfile.address,
      pickup_address_2: newProfile.address_line_2,
      pickup_city: newProfile.city,
      pickup_postcode: newProfile.postcode,
      pickup_state: newProfile.state,
      opHour: newProfile.opHour,
      is_default_pickup_address: newProfile.is_default_pickup_address,

      delivery_building_type: newProfile.address_2_building_type,
      delivery_address_1: newProfile.address_2_line_1,
      delivery_address_2: newProfile.address_2_line_2,
      delivery_city: newProfile.city_2,
      delivery_postcode: newProfile.postcode_2,
      delivery_state: newProfile.state_2,
      opHour_2: newProfile.opHour_2,
      is_default_delivery_address: newProfile.is_default_delivery_address,

      mobile_number: newProfile.phone.number,
      country_code: newProfile.phone.country_code,

      pickup_delivery_same: newProfile.pickup_delivery_same,
    };

    try {
      const response = await ApiInstance.put(
        ApiRoutes.UPDATE_PROFILE,
        updatedProfile
      );

      // console.log("Profile updated", response.data);
      navigate("/settings");
    } catch (error: any) {
      if (error.response?.data?.message != undefined) {
        console.log(error.response.data.message);
        setErrorMessage(error.response.data.message);
        handleOpenModal(error.response.data.message);
      } else {
        setErrorMessage(error.message);
        handleOpenModal(error.message);
      }
    } finally {
      // after all is said and done re-enable submit
      setIsSubmitting(false);
    }
  };

  const handleChange = (e: any): void => {
    let updated = {
      ...profile,
    };
    let target = e.target.id;
    let newValue = e.target.value;

    updated[target] = newValue;

    if (target === "address_building_type") {
      updated["opHour"] = "";
    }
    if (target === "address_2_building_type") {
      updated["opHour_2"] = "";
    }

    // if (target === 'state') {
    //   updated['city'] = "";
    //   setSelectedCity({})
    // }

    setProfile(updated);
  };

  // const handleChangeCity = (selected: any): void => {
  //   let updated = {
  //     ...profile
  //   }
  //   updated['city'] = selected.value;
  //   setSelectedCity(selected)
  //   setProfile(updated);
  // }

  const handleSaveAsDefaultPickupAddress = (e: any): void => {
    let updated = {
      ...profile,
    };
    let newValue = e.target.value === "false" ? true : false;
    updated.is_default_pickup_address = newValue;
    // setSaveAsDefaultPickupAddress(newValue)
    setProfile(updated);
  };

  const handleSaveAsDefaultDeliveryAddress = (e: any): void => {
    let updated = {
      ...profile,
    };
    let newValue = e.target.value === "false" ? true : false;
    updated.is_default_delivery_address = newValue;
    // setSaveAsDefaultDeliveryAddress(newValue)
    setProfile(updated);
  };

  const handlePickupDeliverySame = (e: any): void => {
    let updated = {
      ...profile,
    };
    let newValue = e.target.value === "false" ? true : false;
    updated.pickup_delivery_same = newValue;
    // setPickUpDeliverSame(newValue)
    // setShowDeliveryLocation(!newValue)
    if (!newValue) {
      // Clearing fields for delivery
      updated.address_2_building_type = "";
      updated.address_2_line_1 = "";
      updated.address_2_line_2 = "";
      updated.city_2 = "";
      updated.postcode_2 = "";
      updated.state_2 = "";
      updated.opHour_2 = "";
      updated.is_default_delivery_address = false;
      // setSaveAsDefaultDeliveryAddress(false)
    }

    setProfile(updated);
  };

  return (
    <MainContainer title={"Addresses"}>
      <div className="x-master">
        <div className="x-master-wrapper">
          <Header />
          <header className="x-header for-subpage">
            <h1>Addresses</h1>
          </header>
          <main className="x-main for-subpage">
            <div className="x-breadcrumbbar">
              <a href="/" className="x-breadcrumb-link">
                Home
              </a>
              <a href="/settings" className="x-breadcrumb-link">
                Settings
              </a>
              <a href="/addresses" className="x-breadcrumb-link">
                Addresses
              </a>
            </div>
            <div className="x-settings-wrapper">
              <div className="x-form-wrapper w-form">
                <form
                  id="wf-form-Settings-Form"
                  name="wf-form-Settings-Form"
                  data-name="Settings Form"
                  method="get"
                  data-wf-page-id="650064102890b124b06c8d96"
                  data-wf-element-id="eaec668c-9260-a96b-558d-3698e995ae2d"
                >
                  <div className="x-form-body">
                    <div className="x-form-container">
                      <h2>Pickup Location</h2>
                      <div className="x-form-row">
                        <div className="x-form-col">
                          <label htmlFor="Display-Name">Building Type</label>
                          <div className="x-input-toggle-block">
                            <RadioButtonOption
                              id="address_building_type"
                              value={"highrise"}
                              onChange={handleChange}
                              checked={
                                profile.address_building_type == "highrise"
                              }
                              name="PickUpBuildingType"
                              label="High-rise"
                            />
                            <RadioButtonOption
                              id="address_building_type"
                              value={"landed"}
                              onChange={handleChange}
                              checked={
                                profile.address_building_type == "landed"
                              }
                              name="PickUpBuildingType"
                              label="Landed"
                            />
                            <RadioButtonOption
                              id="address_building_type"
                              value={"hotel"}
                              onChange={handleChange}
                              checked={profile.address_building_type == "hotel"}
                              name="PickUpBuildingType"
                              label="Hotel"
                            />
                            <RadioButtonOption
                              id="address_building_type"
                              value={"office"}
                              onChange={handleChange}
                              checked={
                                profile.address_building_type == "office"
                              }
                              name="PickUpBuildingType"
                              label="Office"
                            />
                          </div>
                        </div>
                      </div>
                      {(profile.address_building_type == "hotel" ||
                        profile.address_building_type == "office") && (
                        <div className="x-form-row">
                          <TextField
                            id="opHour"
                            name="opHour"
                            label="Operational Hours of Office/Hotel"
                            value={profile.opHour}
                            onChange={handleChange}
                            placeholder="Let us know if there are operational hours"
                            required={true}
                          />
                        </div>
                      )}
                      <div className="x-form-row">
                        <TextField
                          id="address"
                          name="PickUpAddress1"
                          label="Address Line 1"
                          value={profile.address}
                          onChange={handleChange}
                          required={true}
                        />
                      </div>
                      <div className="x-form-row">
                        <TextField
                          id="address_line_2"
                          name="PickUpAddress2"
                          label="Address Line 2"
                          value={profile.address_line_2}
                          onChange={handleChange}
                          required={true}
                        />
                      </div>
                      <div className="x-form-row">
                        <div className="x-form-col for-med">
                          <label htmlFor="State">State</label>
                          <div className="x-input-toggle-block">
                            <RadioButtonOption
                              id="state"
                              value={"Kuala Lumpur"}
                              onChange={handleChange}
                              checked={profile.state == "Kuala Lumpur"}
                              name="PickupState"
                              label="Kuala Lumpur"
                            />
                            <RadioButtonOption
                              id="state"
                              value={"Selangor"}
                              onChange={handleChange}
                              checked={profile.state == "Selangor"}
                              name="PickupState"
                              label="Selangor"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="x-form-row">
                        <TextField
                          containerClassName="for-med"
                          id="city"
                          name="PickUpCity"
                          label="City"
                          value={profile.city}
                          onChange={handleChange}
                          required={true}
                        />
                        {/* <CityList
                          id="city"
                          name="PickUpCity"
                          data-name="PickUpCity"
                          value={selectedCity}
                          onChange={handleChangeCity}
                          selectedState={profile.state}
                        /> */}
                        <TextField
                          containerClassName="for-med"
                          id="postcode"
                          name="PickUpPostcode"
                          label="Postcode"
                          value={profile.postcode}
                          onChange={handleChange}
                          required={true}
                        />
                      </div>
                      <div className="x-form-row">
                        <div className="x-form-opt">
                          <label className="w-checkbox x-input-chkbox">
                            <div
                              className={`w-checkbox-input w-checkbox-input--inputType-custom x-input-chkbox-btn ${
                                profile.is_default_pickup_address
                                  ? "w--redirected-checked"
                                  : ""
                              }`}
                            />
                            <input
                              type="checkbox"
                              id="is_default_pickup_address"
                              name="is_default_pickup_address"
                              data-name="is_default_pickup_address"
                              style={{
                                opacity: 0,
                                position: "absolute",
                                zIndex: -1,
                              }}
                              value={profile.is_default_pickup_address?.toString()}
                              onChange={handleSaveAsDefaultPickupAddress}
                            />
                            <span className="x-input-chkbox-label w-form-label">
                              Save this as my default pickup address.
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="x-form-row">
                        <div className="x-form-opt">
                          <label className="w-checkbox x-input-chkbox">
                            <div
                              className={`w-checkbox-input w-checkbox-input--inputType-custom x-input-chkbox-btn ${
                                profile.pickup_delivery_same
                                  ? "w--redirected-checked"
                                  : ""
                              }`}
                            />
                            <input
                              type="checkbox"
                              id="pickup_delivery_same"
                              name="pickup_delivery_same"
                              data-name="pickup_delivery_same"
                              style={{
                                opacity: 0,
                                position: "absolute",
                                zIndex: -1,
                              }}
                              value={profile.pickup_delivery_same?.toString()}
                              onChange={handlePickupDeliverySame}
                            />
                            <span className="x-input-chkbox-label w-form-label">
                              Deliver back to the same address as above.
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    {!profile.pickup_delivery_same && (
                      <div className="x-form-container">
                        <h2>Delivery Location</h2>
                        <div className="x-form-row">
                          <div className="x-form-col">
                            <label htmlFor="Display-Name">Building Type</label>
                            <div className="x-input-toggle-block">
                              <RadioButtonOption
                                id="address_2_building_type"
                                value={"highrise"}
                                onChange={handleChange}
                                checked={
                                  profile.address_2_building_type == "highrise"
                                }
                                name="DeliveryBuildingType"
                                label="High-rise"
                              />
                              <RadioButtonOption
                                id="address_2_building_type"
                                value={"landed"}
                                onChange={handleChange}
                                checked={
                                  profile.address_2_building_type == "landed"
                                }
                                name="DeliveryBuildingType"
                                label="Landed"
                              />
                              <RadioButtonOption
                                id="address_2_building_type"
                                value={"hotel"}
                                onChange={handleChange}
                                checked={
                                  profile.address_2_building_type == "hotel"
                                }
                                name="DeliveryBuildingType"
                                label="Hotel"
                              />
                              <RadioButtonOption
                                id="address_2_building_type"
                                value={"office"}
                                onChange={handleChange}
                                checked={
                                  profile.address_2_building_type == "office"
                                }
                                name="DeliveryBuildingType"
                                label="Office"
                              />
                            </div>
                          </div>
                        </div>
                        {(profile.address_2_building_type == "hotel" ||
                          profile.address_2_building_type == "office") && (
                          <div className="x-form-row">
                            <TextField
                              id="opHour_2"
                              name="opHour_2"
                              label="Operational Hours of Office/Hotel"
                              value={profile.opHour_2}
                              onChange={handleChange}
                              placeholder="Let us know if there are operational hours"
                              required={true}
                            />
                          </div>
                        )}
                        <div className="x-form-row">
                          <TextField
                            id="address_2_line_1"
                            name="DeliveryAddress1"
                            label="Address Line 1"
                            value={profile.address_2_line_1}
                            onChange={handleChange}
                            required={true}
                          />
                        </div>
                        <div className="x-form-row">
                          <TextField
                            id="address_2_line_2"
                            name="DeliveryAddress2"
                            label="Address Line 2"
                            value={profile.address_2_line_2}
                            onChange={handleChange}
                            required={true}
                          />
                        </div>
                        <div className="x-form-row">
                          <div className="x-form-col for-med">
                            <label htmlFor="State">State</label>
                            <div className="x-input-toggle-block">
                              <RadioButtonOption
                                id="state_2"
                                value={"Kuala Lumpur"}
                                onChange={handleChange}
                                checked={profile.state_2 == "Kuala Lumpur"}
                                name="DeliveryState"
                                label="Kuala Lumpur"
                              />
                              <RadioButtonOption
                                id="state_2"
                                value={"Selangor"}
                                onChange={handleChange}
                                checked={profile.state_2 == "Selangor"}
                                name="DeliveryState"
                                label="Selangor"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="x-form-row">
                          <TextField
                            containerClassName="for-med"
                            id="city_2"
                            name="DeliveryCity"
                            label="City"
                            value={profile.city_2}
                            onChange={handleChange}
                            required={true}
                          />
                          <TextField
                            containerClassName="for-med"
                            id="postcode_2"
                            name="DeliveryPostcode"
                            label="Postcode"
                            value={profile.postcode_2}
                            onChange={handleChange}
                            required={true}
                          />
                        </div>
                        <div className="x-form-row">
                          <div className="x-form-opt">
                            <label className="w-checkbox x-input-chkbox">
                              <div
                                className={`w-checkbox-input w-checkbox-input--inputType-custom x-input-chkbox-btn ${
                                  profile.is_default_delivery_address
                                    ? "w--redirected-checked"
                                    : ""
                                }`}
                              />
                              <input
                                type="checkbox"
                                id="is_default_delivery_address"
                                name="is_default_delivery_address"
                                data-name="is_default_delivery_address"
                                style={{
                                  opacity: 0,
                                  position: "absolute",
                                  zIndex: -1,
                                }}
                                value={profile.is_default_delivery_address?.toString()}
                                onChange={handleSaveAsDefaultDeliveryAddress}
                              />
                              <span className="x-input-chkbox-label w-form-label">
                                Save this as my default delivery address.
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="x-form-action">
                      <div className="x-btn-container">
                        <a
                          onClick={(e) => handleUpdate(e)}
                          className="x-btn w-button"
                        >
                          Submit
                        </a>
                        <a
                          href="/settings"
                          className="x-btn for-simple w-button"
                        >
                          Cancel
                        </a>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </main>
          <HomeFooter />
        </div>
        <Footer />
      </div>
      {/* MODAL SECTION */}
      <div
        data-toggletarget="dialog-addons-guide"
        className={`x-dialog ${showModal ? "" : "for-hide"}`}
      >
        <div className="x-dialog-wrapper">
          <div
            className={`x-dialog-form w-form ${showModal ? "" : "for-hide"}`}
          >
            <form
              id="wf-form-Guide-Dialog-Form"
              name="wf-form-Guide-Dialog-Form"
              data-name="Guide Dialog Form"
              method="get"
              data-wf-page-id="64fc904b6e1ec367837abe0b"
              data-wf-element-id="cfb25b3e-6032-c0c4-861a-dc1b5db263d9"
            >
              <div className="x-dialog-header">
                <div className="x-dialog-title for-action-icon"></div>
                <div className="x-btn-container">
                  <a
                    onClick={(e) => handleCloseModal(e)}
                    className="x-icon-btn for-simple is-close-modal w-button"
                  >
                    
                  </a>
                </div>
              </div>
              <div className="x-dialog-body">
                <div className="x-form-wrapper">
                  <div className="x-form-row">{generateModalContent()}</div>
                  <div className="x-form-action">
                    <div className="x-btn-container">
                      <a
                        onClick={(e) => handleCloseModal(e)}
                        className="x-btn for-secondary is-close-modal w-button"
                      >
                        Dismiss
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="x-dialog-overlay" />
        </div>
      </div>
    </MainContainer>
  );
}

// const TextField = (props: any) => {
//   const {
//     containerClassName,
//     id,
//     name,
//     label,
//     value,
//     onChange,
//     type = 'text',
//     maxLength = 256,
//     placeholder,
//     required
//   } = props;

//   return (
//     <div className={`x-form-col ${containerClassName}`}>
//       <label htmlFor={name}>{label}</label>
//       <input
//         id={id}
//         name={name}
//         data-name={name}
//         value={value}
//         onChange={onChange}
//         type={type}
//         className="x-input-text w-input"
//         maxLength={maxLength}
//         placeholder={placeholder}
//         required={required}
//       />
//     </div>
//   )
// }

// const RadioButtonOption = (props: any) => {
//   const {
//     id,
//     value,
//     onChange,
//     checked,
//     name,
//     label
//   } = props;

//   return (
//     <label className="x-input-toggle w-radio">
//       <div className="w-form-formradioinput w-form-formradioinput--inputType-custom x-input-toggle-btn w-radio-input" />
//       <input
//         type="radio"
//         id={id}
//         value={value}
//         onChange={onChange}
//         checked={checked}
//         name={name}
//         data-name={name}
//         style={{
//           opacity: 0,
//           position: "absolute",
//           zIndex: -1
//         }}
//       />
//       <span
//         className="x-input-toggle-label w-form-label"
//       >
//         {label}
//       </span>
//     </label>
//   )
// };

export default Addresses;
