import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import moment from "moment";

import MainContainer from "../Components/MainContainer";
import HomeFooter from "../Components/HomeFooter";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import NewOrderStepper from "../Components/NewOrderStepper";
import NewOrderFooter from "../Components/NewOrderFooter";
// import PayModals from "../Components/Modals/PayModals";
import ErrorModal from "../Components/Modals/ErrorModal";

import {
  isGuestAtom,
  profileAtom,
  orderBagsAtom,
  currentOrderAtom,
  productsAtom,
  currentBagAtom,
  recentOrderAtom,
  recentOrderBagsAtom,
  showBagFormAtom,
  shippingMethodsAtom,
} from "../Utils/Atoms";
import OrderObj from "../Utils/OrderObj";
import OrderBagObj from "../Utils/OrderBagObj";
import { ApiInstance, ApiRoutes } from "../Utils/ApiInstance";
import { getProduct, getSubproduct } from "../Utils/Products";
import { AgreeCheckoutModal } from "../Components/Modals/AgreeCheckoutModal";
import { PhoneField } from "../Components/Forms/PhoneField";
import { isDoorToDoor, isHasPhoneValue, isHasValue } from "../Utils/Helpers";

function Payment() {
  const phoneRef = React.useRef<any>(null);
  const [recentOrder, setRecentOrder] = useRecoilState(recentOrderAtom);
  const [recentOrderBags, setRecentOrderBags] =
    useRecoilState(recentOrderBagsAtom);
  const [currentOrder, setCurrentOrder] = useRecoilState(currentOrderAtom);
  const [orderBags, setOrderBags] = useRecoilState(orderBagsAtom);
  const [currentBag, setCurrentBag] = useRecoilState(currentBagAtom);
  const [showBagForm, setShowBagForm] = useRecoilState(showBagFormAtom);
  const [products, setProducts] = useRecoilState(productsAtom);
  const [profile, setProfile] = useRecoilState(profileAtom);
  const [isGuest, setIsGuest] = useRecoilState(isGuestAtom);
  const [shippingMethods, setShippingMethods] =
    useRecoilState(shippingMethodsAtom);

  const [bagSubtotal, setBagSubtotal] = React.useState(0);
  const [addonsSubtotal, setAddonsSubtotal] = React.useState(0);
  const [deliverySubtotal, setDeliverySubtotal] = React.useState(0);
  const [isDeliveryDiscount, setIsDeliveryDiscount] = React.useState(false);
  const [deliveryDiscountAmount, setDeliveryDiscountAmount] = React.useState(0);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [haveError, setHaveError] = useState(false);

  // Guest Mode
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  // const [modalCode, setModalCode] = useState(0)

  const nav = useNavigate();

  // const handleOpenModal = (e: any, modalNumber: number) => {
  //   e.preventDefault();
  //   setShowModal(true)
  //   setModalCode(modalNumber)
  // }

  React.useEffect(() => {
    setIsSubmitting(false);
    const subtotal1 = calculateBagSubtotal();
    const subtotal2 = calculateAddonsSubtotal();
    const subtotal3 = calculateDeliveryCharge();
    const discountAmount = calculateDeliveryDiscount(
      subtotal1,
      subtotal2,
      subtotal3
    );
    setBagSubtotal(subtotal1);
    setAddonsSubtotal(subtotal2);
    setDeliverySubtotal(subtotal3);
    setDeliveryDiscountAmount(discountAmount);

    // Set default payment type
    let updated = Object.assign(new OrderObj(), currentOrder);
    updated["payment_type"] = "revmonster";
    setCurrentOrder(updated);
  }, []);

  const calculateBagSubtotal = () => {
    const list = [...orderBags].filter(
      (item: any) => item.category === "laundry"
    );
    return getProductsSubtotal(list);
  };

  const calculateAddonsSubtotal = () => {
    const list = [...orderBags].filter(
      (item: any) => item.category === "addon"
    );
    return getProductsSubtotal(list);
  };

  const getProductsSubtotal = (list: any) => {
    let subtotal = 0;
    if (list.length)
      list.map((item: any) => {
        let product = getProduct(item.product_id, products);
        if (product.variant_type == "quantity") {
          subtotal += Number(item.product_qty) * Number(product.unit_price);
        } else if (product.variant_type == "variant") {
          let subProduct =
            getSubproduct(item.subproduct_id, product.subproducts) || {};
          subtotal += Number(subProduct.unit_price);
        }
      });
    return subtotal;
  };

  const calculateDeliveryCharge = () => {
    return currentOrder.shipping_method_amount;
    // return `RM${subtotal.toFixed(2)}`;
  };

  const calculateDeliveryDiscount = (
    subtotal1: any,
    subtotal2: any,
    shippingAmount: any
  ) => {
    let amountToDeduct = 0;
    if (subtotal1 + subtotal2 >= 90) {
      setIsDeliveryDiscount(true);
      amountToDeduct = isDoorToDoor(
        currentOrder.shipping_method_id,
        shippingMethods
      )
        ? 12
        : 0;
    } else {
      setIsDeliveryDiscount(false);
      amountToDeduct = 0;
    }
    return shippingAmount - amountToDeduct;
  };

  const handleCloseModal = (e: any) => {
    e.preventDefault();
    setShowModal(false);
    // setModalCode(0)
    // Reset error modal
    setHaveError(false);
    setErrorMessage("");
  };

  const generateModalContent = () => {
    if (haveError) return <ErrorModal description={errorMessage} />;
    // return <PayModals modalCode={modalCode}/>
  };

  // const calculateLaundrySubtotal = () => {
  //   let subtotal = 0
  //   orderBags.map((bag: any) => {
  //     let product = getProduct(bag.product_id, products);

  //     if (product?.category == "laundry") {
  //       if (product.variant_type == "quantity") {
  //         subtotal += Number(bag.product_qty) * Number(product.unit_price);
  //       } else if (product.variant_type == "variant") {
  //         let subproduct = getSubproduct(bag.subproduct_id, product.subproducts);
  //         subtotal += Number(subproduct.unit_price);
  //       }
  //     }
  //   });
  //   return `RM${subtotal.toFixed(2)}`;
  // }

  const handleCreateOrder = async (e: any) => {
    e.preventDefault();
    if (isSubmitting) return;

    if (orderBags.length === 0) {
      setHaveError(true);
      setErrorMessage("Order bag is empty, please try again");
      setShowModal(true);
      return;
    }

    if (!currentOrder.payment_type) {
      setHaveError(true);
      setErrorMessage("Please select payment option");
      setShowModal(true);
      return;
    }

    if (isGuest) {
      let countryCode = phoneRef.current?.state?.country?.dialCode;
      let phoneNumber = mobile.replace(`+${countryCode}`, "");
      if (!isHasValue(fullName)) {
        setHaveError(true);
        setErrorMessage("Full name is required");
        setShowModal(true);
        return;
      }
      if (!isHasValue(email)) {
        setHaveError(true);
        setErrorMessage("Email is required");
        setShowModal(true);
        return;
      }
      if (!isHasValue(phoneNumber)) {
        setHaveError(true);
        setErrorMessage("Mobile number is required");
        setShowModal(true);
        return;
      }
    } else {
      if (!isHasValue(profile.email)) {
        setHaveError(true);
        setErrorMessage(
          "Full name is required. Please update your personal details"
        );
        setShowModal(true);
        return;
      }
      if (!isHasValue(profile.name)) {
        setHaveError(true);
        setErrorMessage(
          "Email is required. Please update your personal details"
        );
        setShowModal(true);
        return;
      }
      if (!isHasPhoneValue(profile.phone)) {
        setHaveError(true);
        setErrorMessage(
          "Mobile number is required. Please contact admin to update your mobile number."
        );
        setShowModal(true);
        return;
      }
    }

    // Prompt agree and pay
    setShowCheckoutModal(true);
  };

  const handleCloseCheckoutModal = (e: any) => {
    e.preventDefault();
    setShowCheckoutModal(false);
  };

  const handleContinueCheckout = async (e: any) => {
    handleCloseCheckoutModal(e);
    await handleSubmit();
  };

  const handleSubmit = async () => {
    let orderWithItems = Object.assign(new OrderObj(), currentOrder);
    orderWithItems.order_items = [];

    orderBags.map(function (bag: OrderBagObj, index: number) {
      orderWithItems.order_items.push(bag);
    });

    if (isGuest) {
      orderWithItems.customer_email = email;
      orderWithItems.customer_name = fullName;
      orderWithItems.customer_phone = mobile.replace("+", "");
      // orderWithItems.customer_id = profile.id
    } else {
      // Setting customer information into order:
      orderWithItems.customer_email = profile.email;
      orderWithItems.customer_name = profile.name;
      orderWithItems.customer_phone = `${profile.phone.country_code}${profile.phone.number}`;
      orderWithItems.customer_id = profile.id;
    }

    // Formatting for API
    orderWithItems.pickup_slot = Number(orderWithItems.pickup_slot);
    orderWithItems.pickup_slot_id = Number(orderWithItems.pickup_slot_id);
    orderWithItems.pickup_schedule_date = moment(
      orderWithItems.pickup_schedule_date
    ).format("YYMMDD");

    setIsSubmitting(true);

    try {
      const response = await ApiInstance.post(
        ApiRoutes.POST_ORDERS,
        orderWithItems
      );

      let updated = Object.assign(new OrderObj(), currentOrder);
      updated.id = response.data.id;

      // for post-payment success page use
      setRecentOrder(updated);
      setRecentOrderBags(orderBags);
      // clear current order / bags etc.
      setCurrentOrder(new OrderObj());
      setOrderBags([]);
      setCurrentBag(new OrderBagObj());
      setShowBagForm(true);

      // redirect to payment page
      window.location.replace(response.data.paymentUrl.url);
    } catch (error: any) {
      if (error.response?.data?.message != undefined) {
        setHaveError(true);
        setErrorMessage(error.response.data.message);
        setShowModal(true);
      } else {
        setHaveError(true);
        setErrorMessage(error.message);
        setShowModal(true);
      }
    } finally {
      // after all is said and done re-enable submit
      setIsSubmitting(false);
    }
  };

  const handleChange = (e: any): void => {
    let updated = Object.assign(new OrderObj(), currentOrder);
    let target = e.target.id;
    let newValue = e.target.value;

    updated[target] = newValue;
    setCurrentOrder(updated);
  };

  const generateOrderBagSummary = () => {
    const list = [...orderBags].filter(
      (item: any) => item.category === "laundry"
    );

    if (list.length) {
      return list.map((bag: any, i) => {
        let product = getProduct(bag.product_id, products);
        let subProduct =
          getSubproduct(bag.subproduct_id, product.subproducts) || {};
        let subtotal =
          product.variant_type == "quantity"
            ? Number(product.unit_price) * bag.product_qty
            : Number(subProduct.unit_price);

        if (product) {
          return (
            <li key={i} className="x-pay-summary-item">
              <div className="x-pay-summary-info">
                <div>Bag #{bag.id}</div>
                <div className="x-emp-text for-med">
                  {product.name}{" "}
                  {product.variant_type == "variant" && subProduct?.name}
                </div>
              </div>
              <div>RM{subtotal.toFixed(2)}</div>
            </li>
          );
        }
      });
    }
  };

  const generateAddOnSummary = () => {
    const list = [...orderBags].filter(
      (item: any) => item.category === "addon"
    );
    const items = getAddOnItemsQuantity(list);
    const emptyItems = Object.values(items).every((qty) => qty === 0);

    if (list.length && !emptyItems) {
      return (
        <li className="x-pay-summary-item">
          <div className="x-pay-summary-info">
            <div>Addons</div>
            <div className="x-emp-text for-med">
              {Object.entries(items)
                .filter(([_, quantity]: [string, any]) => quantity > 0)
                .map(([name, quantity], key) => (
                  <span key={key}>
                    {`${key ? ", " : ""}${quantity}x ${name}`}
                  </span>
                ))}
            </div>
          </div>
          <div>RM{addonsSubtotal.toFixed(2)}</div>
        </li>
      );
    }
  };

  const getAddOnItemsQuantity = (list: Array<any>) => {
    let items: any = {};

    list
      .sort((a, b) => a.product_id - b.product_id)
      .map((bag: any) => {
        let product = getProduct(bag.product_id, products);
        if (product) {
          items[product.name] = bag.product_qty;
        }
      });

    return items;
  };

  return (
    <MainContainer title={"Pay"}>
      <div className="x-master">
        <div className="x-master-wrapper">
          <Header />
          <header className="x-header for-subpage">
            <h1>Book Laundry Service</h1>
          </header>
          <main className="x-main for-subpage">
            <div className="x-order-header">
              <div className="x-text-flex">
                <a
                  href="/add-ons"
                  className="x-icon-btn for-secondary w-button"
                >
                  
                </a>
                <h2>Payment</h2>
                {/* <a
                  data-tippy-content="How this works?"
                  href="#"
                  className="x-helper-btn"
                >
                  
                </a> */}
              </div>
              <NewOrderStepper step={5} />
            </div>
            <div className="x-order-wrapper">
              <div className="x-form-wrapper w-form">
                <form
                  id="wf-form-Order-Payment-Form"
                  name="wf-form-Order-Payment-Form"
                  data-name="Order Payment Form"
                  method="get"
                  data-wf-page-id="64fd66bdcf6f93409fa6c3a8"
                  data-wf-element-id="6ca47c44-3a43-0c2f-1bbd-1aa46a68960f"
                >
                  <div className="x-pay-summary">
                    <div className="x-pay-summary-block">
                      <h3>Charge Summary</h3>
                      <ol
                        role="list"
                        className="x-pay-summary-list w-list-unstyled"
                      >
                        {generateOrderBagSummary()}
                        {generateAddOnSummary()}
                        <li className="x-pay-summary-item">
                          <div className="x-pay-summary-info">
                            <div>Delivery</div>
                            <div className="x-emp-text for-med">
                              {currentOrder.shipping_method_name}
                            </div>
                          </div>
                          <div>
                            {/* {calculateDeliveryCharge()} */}
                            RM{deliverySubtotal.toFixed(2)}
                          </div>
                        </li>
                        {isDeliveryDiscount && (
                          <li className="x-pay-summary-item">
                            <div className="x-pay-summary-info">
                              <div>Delivery Discount</div>
                              <div className="x-emp-text for-med">
                                Delivery fee discount for Service + Addons total
                                over RM90
                              </div>
                            </div>
                            <div style={{ whiteSpace: "nowrap" }}>
                              - RM{deliveryDiscountAmount.toFixed(2)}
                            </div>
                          </li>
                        )}

                        {/* <li className="x-pay-summary-item">
                          <div>
                            Laundry Service
                          </div>
                          <div>{calculateLaundrySubtotal()}</div>
                        </li>
                        <li className="x-pay-summary-item">
                          <div>Add-ons</div>
                          <div>{calculateAddonsSubtotal()}</div>
                        </li>
                        <li className="x-pay-summary-item">
                          <div>Delivery Charges</div>
                          <div>{calculateDeliveryCharge()}</div>
                        </li> */}
                      </ol>
                    </div>
                    <div className="x-form-container">
                      {isGuest
                        ? [
                            <div className="x-form-row">
                              <div className="x-form-col">
                                <label htmlFor="FullName">Full&nbsp;Name</label>
                                <input
                                  className="x-input-text w-input"
                                  maxLength={256}
                                  name="FullName"
                                  data-name="FullName"
                                  placeholder="Jonny Doe"
                                  type="text"
                                  id="FullName"
                                  onChange={(e) => setFullName(e.target.value)}
                                />
                              </div>
                            </div>,
                            <div className="x-form-row">
                              <div className="x-form-col">
                                <label htmlFor="Email">Email</label>
                                <input
                                  className="x-input-text w-input"
                                  maxLength={256}
                                  name="Email"
                                  data-name="Email"
                                  placeholder="johnny.doe@dobiqueen.my"
                                  type="email"
                                  id="Email"
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                              </div>
                            </div>,
                            <div className="x-form-row">
                              <PhoneField
                                inputRef={phoneRef}
                                mobile={mobile}
                                setMobile={setMobile}
                              />
                            </div>,
                          ]
                        : null}
                      <div className="x-form-row">
                        <div className="x-form-col">
                          <label htmlFor="Display-Name">
                            Available Payment Options
                          </label>
                          <div className="x-form-stack">
                            <label className="x-input-radio w-radio">
                              <div
                                className={`w-form-formradioinput w-form-formradioinput--inputType-custom x-input-radio-btn w-radio-input ${
                                  currentOrder.payment_type == "revmonster" &&
                                  "w--redirected-checked"
                                }`}
                              />
                              <input
                                type="radio"
                                id="payment_type"
                                value={"revmonster"}
                                onChange={handleChange}
                                checked={
                                  currentOrder.payment_type == "revmonster"
                                }
                                name="Payment"
                                data-name="Payment"
                                required
                                style={{
                                  opacity: 0,
                                  position: "absolute",
                                  zIndex: -1,
                                }}
                              />
                              <span className="x-input-radio-label w-form-label">
                                FPX, Visa, MasterCard, eWallets
                              </span>
                            </label>
                            {/* <label className="x-input-radio w-radio">
                              <div className={`w-form-formradioinput w-form-formradioinput--inputType-custom x-input-radio-btn w-radio-input ${currentOrder.payment_type == 'paypal' && 'w--redirected-checked'}`} />
                              <input
                                type="radio"
                                id="payment_type"
                                value="paypal"
                                onChange={handleChange}
                                checked={currentOrder.payment_type == 'paypal'}

                                name="Payment"
                                // defaultValue="paypal"
                                data-name="Payment"
                                required
                                style={{
                                  opacity: 0,
                                  position: "absolute",
                                  zIndex: -1
                                }}
                              />
                              <span
                                className="x-input-radio-label w-form-label"
                              >
                                PayPal (UnionPay, Amex, Foreign Payments)
                              </span>
                            </label> */}
                          </div>
                        </div>
                      </div>
                      {isGuest ? (
                        <div className="x-helper-block">
                          <div className="x-icon for-btn-inline is-amber">
                            
                          </div>
                          <div>
                            <div className="x-emp-text">
                              Your Name, Email and Mobile helps with your Order
                            </div>
                            <div>
                              We need these information to help process your
                              Order and also to send you the payment receipts or
                              Order updates.
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <NewOrderFooter
                    finalStep
                    goToNext={(e: any) => handleCreateOrder(e)}
                    isSubmitting={isSubmitting}
                    // disabled={isSubmitting}
                    // secondaryButton={() => (
                    //   <a href="/new-order" className="x-icon-btn for-secondary w-button">
                    //   
                    // </a>
                    // )}
                  />
                </form>
              </div>
            </div>
          </main>
          <HomeFooter />
        </div>
        <Footer />
      </div>

      <AgreeCheckoutModal
        showModal={showCheckoutModal}
        handleCloseModal={handleCloseCheckoutModal}
        handleContinueCheckout={handleContinueCheckout}
      />

      {/* MODAL SECTION */}
      <div
        data-toggletarget="dialog-addons-guide"
        className={`x-dialog ${showModal ? "" : "for-hide"}`}
      >
        <div className="x-dialog-wrapper">
          <div
            className={`x-dialog-form w-form ${showModal ? "" : "for-hide"}`}
          >
            <form
              id="wf-form-Guide-Dialog-Form"
              name="wf-form-Guide-Dialog-Form"
              data-name="Guide Dialog Form"
              method="get"
              data-wf-page-id="64fc904b6e1ec367837abe0b"
              data-wf-element-id="cfb25b3e-6032-c0c4-861a-dc1b5db263d9"
            >
              <div className="x-dialog-header">
                <div className="x-dialog-title for-action-icon"></div>
                <div className="x-btn-container">
                  <a
                    onClick={(e) => handleCloseModal(e)}
                    className="x-icon-btn for-simple is-close-modal w-button"
                  >
                    
                  </a>
                </div>
              </div>
              <div className="x-dialog-body">
                <div className="x-form-wrapper">
                  <div className="x-form-row">{generateModalContent()}</div>
                  <div className="x-form-action">
                    <div className="x-btn-container">
                      <a
                        onClick={(e) => handleCloseModal(e)}
                        className="x-btn for-secondary is-close-modal w-button"
                      >
                        Dismiss
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="x-dialog-overlay" />
        </div>
      </div>
    </MainContainer>
  );
}

export default Payment;
