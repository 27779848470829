const ErrorModal = (props:any) => {
  return (
    <div className="x-form-col">
    <div className="x-dialog-title for-action">
      {props.title ? props.title : 'Error'}
    </div>
    <p>
      {props.description ? props.description : 'Error'}
    </p>
    </div>
  )
}

export default ErrorModal