const CheckCoverageModals = (props:any) => {
  if (props.modalCode === 1) {
    return (
      <div className="x-form-col">
      <img
        alt=""
        src="images/checkbox-circle-fill.svg"
        className="x-message-thumb for-dialog"
      />
      <div className="x-dialog-title for-action">
        Within Coverage
      </div>
      <p>
        Congratulations! You're within our Pick Up &amp; Delivery
        coverage!
      </p>
    </div>
    )
  }
  if (props.modalCode === 2) {
    return (
      <div className="x-form-col">
      <img
        alt=""
        src="images/checkbox-circle-fill.svg"
        className="x-message-thumb for-dialog"
      />
      <div className="x-dialog-title for-action">
        Within Coverage
      </div>
      <p>
        Just about right! You're outside of our Pick Up &amp;
        Delivery coverage but still within the good-will limit.
        Extra charges may apply.
      </p>
    </div>
    )
  }
  if (props.modalCode === 3) {
    return (
      <div className="x-form-col">
      <img
        alt=""
        src="images/close-circle-fill.svg"
        className="x-message-thumb for-dialog"
      />
      <div className="x-dialog-title for-action">
        Location not Supported
      </div>
      <p>
        Woops, seems like you're out of our Pick Up &amp; Delivery
        coverage. We're expanding our operations to support more
        areas soon.
      </p>
    </div>
    )
  }
  if (props.modalCode === 4) {
    return (
      <div className="x-form-col">
      <img
        alt=""
        src="images/close-circle-fill.svg"
        className="x-message-thumb for-dialog"
      />
      <div className="x-dialog-title for-action">
        Please check Coverage
      </div>
      <p>
        Please use the map to check if you are within Pick Up & Delivery coverage.
      </p>
    </div>
    )
  }
  if (props.modalCode === 5) {
    return (
      <div className="x-form-col">
      <img
        alt=""
        src="images/close-circle-fill.svg"
        className="x-message-thumb for-dialog"
      />
      <div className="x-dialog-title for-action">
        Something went wrong
      </div>
      <p>
        Please try again.
      </p>
    </div>
    )
  }
  return null
}

export default CheckCoverageModals