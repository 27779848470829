const DQLogo = (props) => (
  <div className="x-nav-brand-group">
  <a href="/" className="x-nav-brand-link w-inline-block">
    <img
      src="images/dq-logo-text-color-hr.svg"
      // loading="lazy"
      alt=""
      className="x-brand-logo"
    />
  </a>
  </div>
)

export default DQLogo