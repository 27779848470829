import React from "react";

interface AgreeCheckoutModalProps {
  showModal: boolean;
  handleCloseModal: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleContinueCheckout: (
    event: React.MouseEvent<HTMLButtonElement>
  ) => Promise<void>;
}

export const AgreeCheckoutModal: React.FC<AgreeCheckoutModalProps> = ({
  showModal,
  handleCloseModal,
  handleContinueCheckout,
}) => {
  return (
    <div
      data-toggletarget="dialog-checkout-agreement"
      className={`x-dialog ${showModal ? "" : "for-hide"}`}
    >
      <div className="x-dialog-wrapper">
        <div className="x-dialog-form w-form">
          <form
            id="wf-form-Guide-Dialog-Form"
            name="wf-form-Guide-Dialog-Form"
            data-name="Guide Dialog Form"
            method="get"
            data-wf-page-id="64fd66bdcf6f93409fa6c3a8"
            data-wf-element-id="67a72fc4-06ef-556c-a032-59011035f52e"
            aria-label="Guide Dialog Form"
          >
            <div className="x-dialog-header">
              <div className="x-dialog-title for-action-icon"></div>
              <div className="x-btn-container">
                <a
                  onClick={(e) => handleCloseModal(e)}
                  className="x-icon-btn for-simple is-close-modal w-button"
                >
                  
                </a>
              </div>
            </div>
            <div className="x-dialog-body">
              <div className="x-form-wrapper">
                <div className="x-form-row">
                  <div className="x-form-col">
                    <img
                      alt=""
                      src="https://assets-global.website-files.com/64f4a348be566b5a01f37b12/653b8d8ae82234d2f822b6d4_spam-line.svg"
                      className="x-message-thumb for-dialog"
                    />
                    <div className="x-dialog-title for-action">
                      Before you Checkout
                    </div>
                    <p>
                      For{" "}
                      <span className="x-emp-text">
                        Express Delivery, laundry pickups before 12.30pm will be
                        delivered back same day
                      </span>
                      . Else, by next day.
                    </p>
                    <p>
                      <span className="x-emp-text">Please remove</span> laundry
                      that requires heavy treatment such as (but not limited
                      to):
                    </p>
                    <ul role="list">
                      <li>
                        Heavy stains (mud, ink, blood, cosmetics, chemical, etc)
                      </li>
                      <li>Urine / Faeces residues</li>
                      <li>Musty (Bau Hapak) laundry</li>
                      <li>
                        Shoes, Blackout Curtains, Carpets, Leather Material
                      </li>
                      <li>Laundry with animal fur</li>
                    </ul>
                    <p>
                      <span className="x-emp-text">
                        Don't fold, squeeze or overflow your laundry in the bag
                      </span>{" "}
                      to avoid surcharges on additional machines used.
                    </p>
                    <p>
                      For large orders (&gt; 4 bags), we might need an extra 1-2
                      days depending on the processing outlet's capacity.
                    </p>
                    <p>
                      Baby, toddler and children clothing will be stacked on top
                      of one another (not folded individually) and placed in
                      your bags when it is completed
                    </p>
                    <p className="x-emp-text">
                      By continuing, you agree to the above and dobiQueen's{" "}
                      <a href="/tnc" target="_blank">
                        Terms &amp;&nbsp;Conditions
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="x-dialog-action">
              <div className="x-btn-container">
                <a
                  onClick={(e) => handleContinueCheckout(e)}
                  className="x-btn w-button"
                >
                  I&nbsp;Agree, Continue to Checkout
                </a>
              </div>
            </div>
          </form>
          <div
            className="w-form-done"
            tabIndex="-1"
            role="region"
            aria-label="Guide Dialog Form success"
          >
            <div>Thank you! Your submission has been received!</div>
          </div>
          <div
            className="w-form-fail"
            tabIndex="-1"
            role="region"
            aria-label="Guide Dialog Form failure"
          >
            <div>Oops! Something went wrong while submitting the form.</div>
          </div>
        </div>
        <div className="x-dialog-overlay"></div>
      </div>
    </div>
  );
};
