class OrderObj {
  customer_name: string;
  customer_phone: string;
  customer_email: string;

  pickup_building_type: string;
  pickup_address_1: string;
  pickup_address_2: string;
  pickup_city: string;
  pickup_postcode: string;
  pickup_state: string;

  pickup_schedule_date: string;
  pickup_schedule_slot: string;
  pickup_slot: number;
  pickup_slot_id: number;

  delivery_building_type: string;
  delivery_address_1: string;
  delivery_address_2: string;
  delivery_city: string;
  delivery_postcode: string;
  delivery_state: string;

  delivery_schedule_date: string;
  delivery_schedule_slot: number;
  delivery_slot_id: number;
  delivery_slot_date: string;
  
  pickup_delivery_same: boolean;
  pickup_delivery_front_door: boolean;
  pickup_delivery_remarks: string;
  delivery_remarks: string;
  shipping_method_id: number;
  shipping_method_amount: number;
  shipping_method_name: string;

  is_default_pickup_address: boolean;
  is_default_delivery_address: boolean;
  pickup_opHour: string;
  delivery_opHour: string;

  constructor() {
    this.customer_name = "";
    this.customer_phone = "";
    this.customer_email = "";

    this.pickup_building_type = "";
    this.pickup_address_1 = "";
    this.pickup_address_2 = "";
    this.pickup_city = "";
    this.pickup_postcode = "";
    this.pickup_state = "";

    this.pickup_schedule_date = "";
    this.pickup_schedule_slot = "";
    this.pickup_slot = 0;
    this.pickup_slot_id = 0;

    this.delivery_building_type = "";
    this.delivery_address_1 = "";
    this.delivery_address_2 = "";
    this.delivery_city = "";
    this.delivery_postcode = "";
    this.delivery_state = "";

    this.delivery_schedule_date = "";
    this.delivery_schedule_slot = 0;
    this.delivery_slot_id = 0;
    this.delivery_slot_date = "";

    this.pickup_delivery_same = true;
    this.pickup_delivery_front_door = true;
    this.pickup_delivery_remarks = "";
    this.delivery_remarks = "";
    this.shipping_method_id = 0;
    this.shipping_method_amount = 0;
    this.shipping_method_name = "";

    this.is_default_pickup_address = false;
    this.is_default_delivery_address = false;
    this.pickup_opHour = "";
    this.delivery_opHour = "";
  }
}

export default OrderObj;