// routing
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
} from "react-router-dom";
import { RecoilRoot } from "recoil";
import RecoilNexus from "recoil-nexus";

/* ACCESS */
import SignIn from "./Pages/Access/SignIn";
import ForgotPassword from "./Pages/Access/ForgotPassword";
import ResetPassword from "./Pages/Access/ResetPassword";
import SignUp from "./Pages/Access/SignUp";
import SignUpTAC from "./Pages/Access/SignUpTAC";
import SignUpSuccess from "./Pages/Access/SignUpSuccess";

/* ORDERS */
import Home from "./Pages/Home";
import QRScan from "./Pages/QRScan";
import MyOrders from "./Pages/MyOrders";
import MyOrderDetails from "./Pages/MyOrderDetails";
import Cart from "./Pages/Cart";
import TopUp from "./Pages/TopUp";
import NewOrder from "./Pages/NewOrder";
import Schedule from "./Pages/Schedule";
import AddressSelect from "./Pages/AddressSelect";
import AddOns from "./Pages/AddOns";
import Payment from "./Pages/Payment";
import CheckCoverage from "./Pages/CheckCoverage";
import Success from "./Pages/OrderConfirmation";
import PaymentFailed from "./Pages/PaymentFailed";

/* ORDERS OLD */
// import NewOrderOld from './Pages/Old/NewOrderOld';
// import ScheduleOld from './Pages/Old/ScheduleOld';
// import AddressSelectOld from './Pages/Old/AddressSelectOld';
// import CheckCoverageOld from "./Pages/Old/CheckCoverage";
// import AddOnsOld from './Pages/Old/AddOnsOld';
// import PaymentOld from './Pages/Old/PaymentOld';
// import SuccessOld from './Pages/Old/Success';

/* SETTINGS */
import SettingsMain from "./Pages/Others/Settings/SettingsMain";
import Account from "./Pages/Others/Settings/Account";
import Security from "./Pages/Others/Settings/Security";
import Addresses from "./Pages/Others/Settings/Addresses";

/* OTHERS */
import Outlets from "./Pages/Others/Outlets";
import Notifications from "./Pages/Others/Notifications";

/* UTILITIES */
import AuthRequired from "./Layouts/AuthRequired";
import NoGuest from "./Layouts/NoGuest";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Home />} index />
      <Route path="check-coverage" element={<CheckCoverage />} />
      <Route path="order-details/:orderId" element={<MyOrderDetails />} />
      <Route path="/" element={<AuthRequired />}>
        {/* ORDERS */}
        <Route path="cart" element={<Cart />} />
        <Route path="top-up" element={<TopUp />} />
        <Route path="new-order" element={<NewOrder />} />
        <Route path="schedule" element={<Schedule />} />
        <Route path="address-select" element={<AddressSelect />} />
        <Route path="add-ons" element={<AddOns />} />
        <Route path="pay" element={<Payment />} />
        {/* <Route path="order-details/:orderId" element={<MyOrderDetails />} /> */}
        <Route path="order/:orderId/summary" element={<MyOrderDetails />} />
        <Route path="payment-failed" element={<PaymentFailed />} />
        {/* ORDERS OLD */}
        {/* <Route path="new-order-old" element={<NewOrderOld />} /> */}
        {/* <Route path="schedule-old" element={<ScheduleOld />} /> */}
        {/* <Route path="address-select-old" element={<AddressSelectOld />} /> */}
        {/* <Route path="check-coverage-old" element={<CheckCoverageOld />} /> */}
        {/* <Route path="add-ons-old" element={<AddOnsOld />} /> */}
        {/* <Route path="pay-old" element={<PaymentOld />} /> */}
        {/* <Route path="success-old" element={<SuccessOld />} /> */}
        {/* SCAN */}
        <Route path="qr-scan" element={<QRScan />} />
      </Route>
      <Route path="/" element={<NoGuest />}>
        {/* ORDERS */}
        <Route path="orders" element={<MyOrders />} />
        {/* SETTINGS */}
        <Route path="settings" element={<SettingsMain />} />
        <Route path="account" element={<Account />} />
        <Route path="security" element={<Security />} />
        <Route path="addresses" element={<Addresses />} />
        {/* OTHERS */}
        <Route path="outlets" element={<Outlets />} />
        <Route path="notifications" element={<Notifications />} />
      </Route>
      {/* SIGN IN */}
      <Route path="sign-in" element={<SignIn />} />
      {/* <Route path="password-recovery" element={<ForgotPassword />} /> */}
      {/* <Route path="reset-password-link-sent" element={<ResetPassword />} /> */}
      {/* SIGN UP */}
      <Route path="sign-up" element={<SignUp />} />
      <Route path="sign-up-tac" element={<SignUpTAC />} />
      <Route path="sign-up-success" element={<SignUpSuccess />} />
    </Route>
  )
);

function App() {
  return (
    <div className="App">
      <RecoilRoot>
        <RecoilNexus />{" "}
        {/* Recoil workaround to allow usage outside components */}
        <RouterProvider router={router} />
      </RecoilRoot>
    </div>
  );
}

export default App;
