import moment from 'moment'

const NotificationsRow = (props) => (
    <li className={`x-noti-item ${props.notification.read_at ? 'for-read' : ''}`} onClick={(e) => props.read(e)}>
    <div className="x-icon for-noti"></div>
    <div className="x-noti-wrapper">
      <div className="x-noti-header">
        <div className="x-small-text">
          {moment(props.notification.data.updated_at).format('MMMM DD YYYY - h:mma')}
        </div>
      </div>
      <div>
        {props.notification.data.message}
      </div>
    </div>
    </li>
  )

export default NotificationsRow