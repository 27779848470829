// get product by ID
const getProduct = (id: string, products: Array<any>) => {
  if(products.length) {
    return products.find((item: any) => item.id == id);
  }
  return {};
}

// get subproducts by product ID
const getSubproducts = (id: string, products: Array<any>) => {
  let variant = products.find((item: any) => item.id == id);
  if(variant !== undefined) {
    return variant.subproducts;
  }
  return [];
}

// get subproduct by ID
const getSubproduct = (id: string, subproducts: Array<any>) => {
  return subproducts.find((item: any) => item.id == id);
}

export {getProduct, getSubproducts, getSubproduct};