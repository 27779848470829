import { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import MainContainer from "../Components/MainContainer";
import HomeFooter from "../Components/HomeFooter";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
// import MyOrderEmptyState from '../Components/MyOrderEmptyState';
// import MyOrderRow from '../Components/MyOrderRow';

import { ApiInstance, ApiRoutes } from "../Utils/ApiInstance";
import SummaryList from "../Components/Order/SummaryList";
import ErrorModal from "../Components/Modals/ErrorModal";
import {
  currentBagAtom,
  currentOrderAtom,
  orderBagsAtom,
  productsAtom,
  shippingMethodsAtom,
  showBagFormAtom,
} from "../Utils/Atoms";
import OrderObj from "../Utils/OrderObj";
import OrderBagObj from "../Utils/OrderBagObj";
import { getProduct } from "../Utils/Products";
import { getShippingMethod } from "../Utils/Helpers";

const orderDetailsInit = {
  id: "",
  pickup_building_type: "",
  pickup_opHour: "",
  pickup_address_1: "",
  pickup_address_2: "",
  pickup_city: "",
  pickup_postcode: "",
  pickup_state: "",
  is_default_pickup_address: false,
  delivery_building_type: "",
  delivery_opHour: "",
  delivery_address_1: "",
  delivery_address_2: "",
  delivery_city: "",
  delivery_postcode: "",
  delivery_state: "",
  is_default_delivery_address: false,
  pickup_delivery_same: true,
  pickup_delivery_remarks: "",
  pickup_schedule_date: "",
  pickup_slot: "",
  pickup_slot_id: "",
  pickup_schedule_slot: "",
  delivery_schedule_slot: "",
  order_items: [],
  status: "",
  shipping_amount: 0,
  shipping_method_id: "",
  customer_name: "",
  overload: 0,
  cost_difference: 0,
};

function MyOrderDetails() {
  const [currentOrder, setCurrentOrder] = useRecoilState(currentOrderAtom);
  const [orderBags, setOrderBags] = useRecoilState(orderBagsAtom);
  const [currentBag, setCurrentBag] = useRecoilState(currentBagAtom);
  const [showBagForm, setShowBagForm] = useRecoilState(showBagFormAtom);
  const [products, setProducts] = useRecoilState(productsAtom);
  const [shippingMethods, setShippingMethods] =
    useRecoilState(shippingMethodsAtom);

  const [currentOrderDetails, setCurrentOrderDetails] =
    useState(orderDetailsInit);
  const [serviceTotal, setServiceTotal] = useState(0);
  const [addonsTotal, setAddonsTotal] = useState(0);
  const [deliveryTotal, setDeliveryTotal] = useState(0);
  const [totalPayable, setTotalPayable] = useState(0);
  const [isOrderConfirmation, setIsOrderConfirmation] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [haveError, setHaveError] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { orderId } = useParams();

  const checkHasOrderID = async () => {
    // Check if cancelled, then route to home
    if (!orderId) {
      navigate("/");
      return null;
    }

    if (location.search.toLowerCase().indexOf("cancelled") !== -1) {
      cancelOrder();
      return null;
    }

    // localhost:3000/order/556/summary?orderId=556_1712636553&status=CANCELLED
    if (location.pathname.toLowerCase().includes("summary")) {
      setIsOrderConfirmation(true);
    }
    handleGetShippingMethod();
    getProducts();
    getOrderDetails();

    // Checking has order ID
    // if (!location.state) {
    //   if (!orderId) {
    //     navigate('/')
    //     return null
    //   } else {
    //     getOrderDetails()
    //     setIsOrderConfirmation(true)
    //   }
    // } else {
    //   getOrderDetails()
    // }
  };

  const cancelOrder = async () => {
    try {
      const response = await ApiInstance.post(
        `${ApiRoutes.CANCEL_ORDER}/${orderId}`,
        null
      );
      navigate("/payment-failed");
    } catch (error) {
      navigate("/");
      throw error;
    }
  };

  const getProducts = async () => {
    try {
      const response = await ApiInstance.get(ApiRoutes.GET_PRODUCTS);
      setProducts(response.data);
    } catch (error) {
      throw error;
    }
  };

  const handleGetShippingMethod = async () => {
    const data = await getShippingMethod();
    setShippingMethods(data);
  };

  const getOrderDetails = async () => {
    try {
      const response = await ApiInstance.get(
        `${ApiRoutes.GET_ORDERS}/${orderId}`
      );
      setCurrentOrderDetails(response.data);
      calculatePaymentSummary(response.data);
    } catch (error) {
      console.log({ error });
      navigate("/");
      throw error;
    }
  };

  const calculatePaymentSummary = (data: any) => {
    let orders = data.order_items;
    let serviceTotal = 0;
    let addOnsTotal = 0;
    let deliveryTotal = 0;
    let totalPayable = 0;
    orders.map((orderItem: any) => {
      if (orderItem.product_category === "laundry") {
        let product = getProduct(orderItem.product_id, products);
        serviceTotal +=
          product && product?.variant_type == "quantity"
            ? Number(orderItem.product_unit_price) * orderItem.product_qty
            : Number(orderItem.subproduct_unit_price);
      }
      if (orderItem.product_category === "addon") {
        addOnsTotal +=
          Number(orderItem.product_qty) * Number(orderItem.product_unit_price);
      }
    });
    if (data.shipping_amount) {
      deliveryTotal = data.shipping_amount;
    }

    totalPayable = deliveryTotal + addOnsTotal + serviceTotal;

    setDeliveryTotal(deliveryTotal);
    setAddonsTotal(addOnsTotal);
    setServiceTotal(serviceTotal);
    setTotalPayable(totalPayable);
  };

  useEffect(() => {
    checkHasOrderID();
  }, []);

  const orderOverload = !!currentOrderDetails?.overload;

  const handleAcceptChange = async (e: any) => {
    e.preventDefault();
    if (isSubmitting) return;

    setIsSubmitting(true);

    try {
      const response = await ApiInstance.post(
        `${ApiRoutes.UPDATE_ORDER_STATUS}/${orderId}`,
        { choice: "top_up" }
      );
      window.location.replace(response.data.paymentUrl.url);
    } catch (error: any) {
      if (error.response?.data?.message != undefined) {
        setHaveError(true);
        setErrorMessage(error.response.data.message);
        setShowModal(true);
      } else {
        setHaveError(true);
        setErrorMessage(error.message);
        setShowModal(true);
      }
    } finally {
      // after all is said and done re-enable submit
      setIsSubmitting(false);
    }
  };

  const handleRejectChange = async (e: any) => {
    e.preventDefault();
    if (isSubmitting) return;

    setIsSubmitting(true);

    try {
      const response = await ApiInstance.post(
        `${ApiRoutes.UPDATE_ORDER_STATUS}/${orderId}`,
        { choice: "no" }
      );
      window.location.reload();
    } catch (error: any) {
      if (error.response?.data?.message != undefined) {
        setHaveError(true);
        setErrorMessage(error.response.data.message);
        setShowModal(true);
      } else {
        setHaveError(true);
        setErrorMessage(error.message);
        setShowModal(true);
      }
    } finally {
      // after all is said and done re-enable submit
      setIsSubmitting(false);
    }
  };

  const handleCloseModal = (e: any) => {
    e.preventDefault();
    setShowModal(false);
    // Reset error modal
    setHaveError(false);
    setErrorMessage("");
  };

  const generateModalContent = () => {
    if (haveError) return <ErrorModal description={errorMessage} />;
  };

  const displayAction = () => {
    if (orderOverload && currentOrderDetails.status !== "cancelled") {
      return (
        <div className="x-order-action">
          <h2>Your Order has Changes</h2>
          <div className="x-order-action-wrapper">
            <div className="x-order-action-container">
              <p>
                Hey, {currentOrderDetails?.customer_name}, unfortunately your
                order is Overload and additional Change Fee of{" "}
                <span className="x-brand-emp">
                  RM{currentOrderDetails?.cost_difference.toFixed(2)}
                </span>{" "}
                is required. You now have a choice to either:
              </p>
              <div className="x-order-action-grid">
                <div className="x-order-action-grid-block">
                  <div className="x-order-action-opt-title for-accept">
                    Pay &amp; Continue
                  </div>
                  <div>
                    To continue, just make RM
                    {currentOrderDetails?.cost_difference.toFixed(2)} Change Fee
                    payment and your order will be processed immediately.
                  </div>
                  <div className="x-btn-container">
                    <button
                      onClick={handleAcceptChange}
                      disabled={isSubmitting}
                      className="x-btn w-button"
                    >
                      Pay Change Fee of RM
                      {currentOrderDetails?.cost_difference.toFixed(2)}
                      <span className="x-icon for-btn-inline"></span>
                    </button>
                  </div>
                </div>
                <div className="x-order-action-sep">
                  <div className="x-order-action-sep-bubble">OR</div>
                </div>
                <div className="x-order-action-grid-block for-reject">
                  <div className="x-order-action-opt-title for-reject">
                    Reject &amp; Return
                  </div>
                  <div>
                    Reject this change, and we will return the laundry to you.
                    You will only be charged for the Delivery fees. All other
                    charges will be refunded.
                  </div>
                  <div className="x-btn-container">
                    <button
                      onClick={handleRejectChange}
                      disabled={isSubmitting}
                      className="x-btn for-secondary w-button"
                    >
                      Reject &amp; Return
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (orderOverload && currentOrderDetails.status === "cancelled") {
      return (
        <div className="x-order-action">
          <h2>Your Order has been Cancelled</h2>
          <div className="x-order-action-wrapper">
            <div className="x-order-action-container">
              <p>
                Hey, {currentOrderDetails?.customer_name}, your order has been
                cancelled. We will return the laundry to you. You will only be
                charged for the Delivery fees. All other charges will be
                refunded.
              </p>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="x-order-notice">
          <h2 className="x-emp-header">Important!</h2>
          <div className="x-order-notice-wrapper">
            <img
              src="/images/62876011.jpg"
              // loading="lazy"
              sizes="(max-width: 767px) 90vw, 1vw"
              srcSet="/images/62876011-p-500.jpg 500w, /images/62876011-p-800.jpg 800w, /images/62876011.jpg 1024w"
              alt=""
              className="x-order-notice-thumb"
            />
            <div className="x-order-notice-info">
              <p>
                Label your bag with the correct information as below to avoid
                issues.
              </p>
              <ul role="list">
                <li>
                  <strong>Your name</strong>
                </li>
                <li>
                  <strong>Mobile number</strong>
                </li>
                <li>
                  <strong>Bag Number + Service</strong> (E.g. "Bag #1, Wash
                  &amp;&nbsp;Dry")
                </li>
              </ul>
              <p>This will prevent issues when managing your order.</p>
              <div className="x-emp-text for-med">Disclaimer</div>
              <div className="x-small-text">
                dobiQueen will not be held responsible for issues that may occur
                in your order due to customer who do not label their bags as
                requested. We urge customers to always labels their bags
                correctly.
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const handleOrderAgain = () => {
    const currentOrderBags: any[] = [];
    if (currentOrderDetails.order_items.length) {
      currentOrderDetails.order_items
        .filter((item: any) => !!item.product_category)
        .map((item: any, key: any) => {
          currentOrderBags.push({
            id: key,
            product_id: item.product_id,
            subproduct_id: item.subproduct_id || "",
            product_qty: item.product_qty,
            subproduct_qty:
              item.product_category === "laundry"
                ? item.product_qty
                : item.subproduct_qty,
            category: item.product_category,
            hangers_option: item.hangers_option,
            hangers_qty: item.hangers_qty,
          });
        });
    }
    const details = {
      pickup_building_type: currentOrderDetails.pickup_building_type,
      pickup_opHour: currentOrderDetails.pickup_opHour,
      pickup_address_1: currentOrderDetails.pickup_address_1,
      pickup_address_2: currentOrderDetails.pickup_address_2,
      pickup_city: currentOrderDetails.pickup_city,
      pickup_postcode: currentOrderDetails.pickup_postcode,
      pickup_state: currentOrderDetails.pickup_state,
      is_default_pickup_address: currentOrderDetails.is_default_pickup_address,
      delivery_building_type: currentOrderDetails.delivery_building_type,
      delivery_opHour: currentOrderDetails.delivery_opHour,
      delivery_address_1: currentOrderDetails.delivery_address_1,
      delivery_address_2: currentOrderDetails.delivery_address_2,
      delivery_city: currentOrderDetails.delivery_city,
      delivery_postcode: currentOrderDetails.delivery_postcode,
      delivery_state: currentOrderDetails.delivery_state,
      is_default_delivery_address:
        currentOrderDetails.is_default_delivery_address,
      pickup_delivery_same: currentOrderDetails.pickup_delivery_same,
      pickup_delivery_remarks: currentOrderDetails.pickup_delivery_remarks,
      pickup_schedule_date: currentOrderDetails.pickup_schedule_date,
      pickup_slot: currentOrderDetails.pickup_slot_id,
      pickup_slot_id: currentOrderDetails.pickup_slot_id,
      shipping_method_amount: currentOrderDetails.shipping_amount,
      shipping_method_id: currentOrderDetails.shipping_method_id,
    };

    setCurrentOrder(Object.assign(new OrderObj(), details));
    setOrderBags(currentOrderBags);
    setCurrentBag(new OrderBagObj());
    setShowBagForm(false);
    navigate("/new-order");
  };

  return (
    <MainContainer title={"Order Details"}>
      <div className="x-master">
        <div className="x-master-wrapper">
          <Header />
          <header className="x-header for-subpage">
            {isOrderConfirmation ? (
              <h1>Order Confirmation</h1>
            ) : (
              <h1>Your Order</h1>
            )}
          </header>
          <main className="x-main for-subpage">
            {isOrderConfirmation ? null : (
              <div className="x-breadcrumbbar">
                <a href="/" className="x-breadcrumb-link">
                  Home
                </a>
                <a href="/orders" className="x-breadcrumb-link">
                  Orders
                </a>
                <a className="x-breadcrumb-link">
                  {`#${currentOrderDetails.id}`}
                </a>
              </div>
            )}
            <div className="x-order-header">
              <div className="x-text-flex">
                <h2>{`Order #${currentOrderDetails.id}`}</h2>
              </div>
              {isOrderConfirmation || orderOverload ? null : (
                <div className="x-btn-container">
                  <a onClick={handleOrderAgain} className="x-btn w-button">
                    Order again<span className="x-icon for-btn-inline"></span>
                  </a>
                </div>
              )}
            </div>
            <div className="x-order-status">
              {orderOverload && currentOrderDetails.status !== "cancelled" ? (
                <div className="x-order-status-chip for-current">
                  <div className="x-icon"></div>
                  <div>Attention Required for Order</div>
                </div>
              ) : (
                <>
                  <div
                    className={`x-order-status-chip ${
                      currentOrderDetails.status.toLowerCase() === "confirmed"
                        ? "for-current"
                        : ""
                    }`}
                  >
                    <div className="x-icon"></div>
                    <div>Confirmed</div>
                  </div>
                  <div
                    className={`x-order-status-chip ${
                      currentOrderDetails.status
                        .toLowerCase()
                        .indexOf("pickup") !== -1
                        ? "for-current"
                        : ""
                    }`}
                  >
                    <div className="x-icon"></div>
                    <div>Pickup</div>
                  </div>
                  <div
                    className={`x-order-status-chip ${
                      currentOrderDetails.status
                        .toLowerCase()
                        .indexOf("arrive") !== -1 ||
                      currentOrderDetails.status
                        .toLowerCase()
                        .indexOf("washing") !== -1
                        ? "for-current"
                        : ""
                    }`}
                  >
                    <div className="x-icon"></div>
                    <div>Washing</div>
                  </div>
                  <div
                    className={`x-order-status-chip ${
                      currentOrderDetails.status.toLowerCase() === "drying"
                        ? "for-current"
                        : ""
                    }`}
                  >
                    <div className="x-icon"></div>
                    <div>Drying</div>
                  </div>
                  <div
                    className={`x-order-status-chip ${
                      currentOrderDetails.status.toLowerCase() === "folding"
                        ? "for-current"
                        : ""
                    }`}
                  >
                    <div className="x-icon"></div>
                    <div>Folding</div>
                  </div>
                  <div
                    className={`x-order-status-chip ${
                      currentOrderDetails.status.toLowerCase() ===
                      "dropoff_scheduled"
                        ? "for-current"
                        : ""
                    }`}
                  >
                    <div className="x-icon"></div>
                    <div>To Return</div>
                  </div>
                  <div
                    className={`x-order-status-chip ${
                      currentOrderDetails.status.toLowerCase() ===
                      "dropoff_in_progress"
                        ? "for-current"
                        : ""
                    }`}
                  >
                    <div className="x-icon"></div>
                    <div>Returning</div>
                  </div>
                  <div
                    className={`x-order-status-chip ${
                      currentOrderDetails.status.toLowerCase() === "completed"
                        ? "for-current"
                        : ""
                    }`}
                  >
                    <div className="x-icon"></div>
                    <div>Completed</div>
                  </div>
                  {/* <div className={`x-order-status-chip ${currentOrderDetails.status.toLowerCase() === 'no_response' ? 'for-current' : ''}`}>
                    <div className="x-icon"></div>
                    <div>Attention</div>
                  </div> */}
                  <div
                    className={`x-order-status-chip ${
                      currentOrderDetails.status.toLowerCase() === "cancelled"
                        ? "for-current"
                        : ""
                    }`}
                  >
                    <div className="x-icon"></div>
                    <div>Cancelled</div>
                  </div>
                </>
              )}
            </div>
            {displayAction()}
            <div className="x-pay-summary">
              <div className="x-pay-summary-block">
                <h3>Payment Summary</h3>
                <ol role="list" className="x-pay-summary-list w-list-unstyled">
                  <li className="x-pay-summary-item">
                    <div>Laundry</div>
                    <div>RM{serviceTotal.toFixed(2)}</div>
                  </li>
                  <li className="x-pay-summary-item">
                    <div>Add-ons</div>
                    <div>RM{addonsTotal.toFixed(2)}</div>
                  </li>
                  <li className="x-pay-summary-item">
                    <div>Delivery Charges</div>
                    <div>RM{deliveryTotal.toFixed(2)}</div>
                  </li>
                </ol>
                <div className="x-hr-sep" />
                <div className="x-emp-text">
                  Total: RM{totalPayable.toFixed(2)}
                </div>
                {/* <div className="x-emp-text">Total Paid: RM0.00</div> */}
                {/* <div className="x-emp-text for-med">
                  Payment by {"{"}
                  {"{"}PaymentMethod{"}"}
                  {"}"}
                </div>
                <div className="x-emp-text for-med">
                  Paid on {"{"}
                  {"{"}Date/Time{"}"}
                  {"}"}
                </div> */}
                <div className="x-helper-block">
                  <div className="x-icon for-btn-inline is-amber"></div>
                  <div>
                    <div className="x-emp-text">Receipt Emailed to You</div>
                    <div>
                      Your Order Receipt has also been emailed to you. Please
                      check your inbox and spam folder in the next couple of
                      minutes.
                    </div>
                  </div>
                </div>
              </div>
              <SummaryList
                orderDetails={currentOrderDetails}
                deliveryTotal={deliveryTotal}
              />
            </div>
            {isOrderConfirmation ? (
              <div className="x-bag-footer">
                <div className="x-btn-container">
                  <a href="/" className="x-btn w-button">
                    Back to Home
                  </a>
                  <a href="/orders" className="x-btn for-secondary w-button">
                    Go to Orders
                  </a>
                </div>
              </div>
            ) : null}
          </main>
          <HomeFooter />
        </div>
        <Footer />
      </div>

      {/* MODAL SECTION */}
      <div
        data-toggletarget="dialog-addons-guide"
        className={`x-dialog ${showModal ? "" : "for-hide"}`}
      >
        <div className="x-dialog-wrapper">
          <div
            className={`x-dialog-form w-form ${showModal ? "" : "for-hide"}`}
          >
            <form
              id="wf-form-Guide-Dialog-Form"
              name="wf-form-Guide-Dialog-Form"
              data-name="Guide Dialog Form"
              method="get"
              data-wf-page-id="64fc904b6e1ec367837abe0b"
              data-wf-element-id="cfb25b3e-6032-c0c4-861a-dc1b5db263d9"
            >
              <div className="x-dialog-header">
                <div className="x-dialog-title for-action-icon"></div>
                <div className="x-btn-container">
                  <a
                    onClick={(e) => handleCloseModal(e)}
                    className="x-icon-btn for-simple is-close-modal w-button"
                  >
                    
                  </a>
                </div>
              </div>
              <div className="x-dialog-body">
                <div className="x-form-wrapper">
                  <div className="x-form-row">{generateModalContent()}</div>
                  <div className="x-form-action">
                    <div className="x-btn-container">
                      <a
                        onClick={(e) => handleCloseModal(e)}
                        className="x-btn for-secondary is-close-modal w-button"
                      >
                        Dismiss
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="x-dialog-overlay" />
        </div>
      </div>
    </MainContainer>
  );
}

export default MyOrderDetails;
